import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'
import addresses from './contracts'

export const getAddress = (address: Address): string => {
  const chainId = process.env.NEXT_PUBLIC_CHAIN_ID
  return address[chainId]
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getDagenTokenAddress = (chainId: number) => {
  return addresses.lifeDAO[chainId]
}
export const getMarketAddress = (chainId: number) => {
  return addresses.market[chainId]
}
export const getItemsAddress = (chainId: number) => {
  return addresses.items[chainId]
}
export const getBlindBoxAddress = (chainId: number) => {
  return addresses.blindBox[chainId]
}
export const getBadgeAddress = (chainId: number) => {
  return addresses.badge[chainId]
}
export const getBadgeBlindBoxAddress = (chainId: number) => {
  return addresses.badgeBlindBox[chainId]
}
