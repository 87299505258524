import { Flex, Text } from '@library/uikit'
import { useState } from 'react'
import CountUp from 'react-countup'
import { useProfile } from 'state/profile/hooks'

function GCentBalance() {
  const { profile } = useProfile()
  const [preGCent, setPreGCent] = useState(0)

  return (
    <>
      {profile && (
        <Flex color="white">
          <CountUp
            start={preGCent}
            end={profile.gold}
            duration={1}
            onEnd={() => setPreGCent(profile.gold)}
            separator=","
          />
          <Text ml={1}>G</Text>
        </Flex>
      )}
    </>
  )
}

export default GCentBalance
