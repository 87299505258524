import { useSystemInfo } from 'state/player/hooks'
import { useStore } from '../../state'

const useEventMsg1001SceneEvent = () => {
  const [_, setSystemInfo] = useSystemInfo()
  const { getState } = useStore()

  return (event) => {
    const eventData = JSON.parse(event.data)
    console.info('[useEventMsg1001SceneEvent===== eventData]', eventData)

    if (!eventData || eventData.length === 0) {
      return
    }

    const sceneEvents = [...eventData, ...getState().player.systemInfo.sceneEvents]

    setSystemInfo({
      ...getState().player.systemInfo,
      sceneEvents,
    })
  }
}

export default useEventMsg1001SceneEvent
