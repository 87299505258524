import { ContextApi } from '@library/localization'
import { FooterLinkType } from '@library/uikit'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: 'https://chainbow.co.jp/',
      },
      {
        label: t('Blog'),
        href: 'https://docs.chainbow.io/',
      },
      {
        label: t('Community'),
        href: 'https://discord.com/invite/j92h4qDcRX',
      },
    ],
  },
]
