import { useSystemInfo } from 'state/player/hooks'
import { useStore } from '../../state'
import { SceneEvent } from '../../../server/model'

const useEventMsg1003PlayerOffline = () => {
  const [_, setSystemInfo] = useSystemInfo()
  const { getState } = useStore()
  return (event) => {
    const eventData: { onlineCount: number; events: SceneEvent[] } = JSON.parse(event.data)
    setSystemInfo({
      ...getState().player.systemInfo,
      onlineCount: eventData.onlineCount,
      sceneEvents: [...eventData.events, ...getState().player.systemInfo.sceneEvents],
    })
  }
}

export default useEventMsg1003PlayerOffline
