import { useTranslation } from '@library/localization'
import { Button, Flex, InjectedModalProps, ModalLevelUpContainer, Text } from '@library/uikit'
import noop from 'lodash/noop'
import React from 'react'
import styled from 'styled-components'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import { ILevelUpParams } from '@common/types'
import { DAGEN_DOMAIN, genLightFunBgList } from '@common/constans'
import { shareTwitterUrl } from 'utils'
import { FlexGap } from '../../components/Layout/Flex'
import { NextLinkFromReactRouter } from '../../components/NextLink'
import useCommunities from '../../hooks/useCommunities'
import useWallet from '../../hooks/useWallet'

const Container = styled(Flex)<{ bgImage: string; coreImage: string; flashImage: string }>`
  height: 100%;
  width: 100%;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  position: relative;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .close-circle {
    position: absolute;
    width: 32px;
    height: 32px;
    border: 1px solid #d4b27e;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 10;

    top: 10%;
    right: 5%;

    ${({ theme }) => theme.mediaQueries.sm} {
      top: 20%;
      right: 5%;
    }
  }

  .close-mark {
    width: 14px;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #d4b27e;
  }

  /*黑色遮罩+外星图*/
  .main-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: url('https://s1.ax1x.com/2022/11/28/zU4igH.png');
    background-size: cover;
    animation: simple-fadein 0.5s;
  }

  /*一个简单的透明度动画，在描述文字上会复用*/
  @keyframes simple-fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /*文字背景的黑色半透明底色*/
  .block-bg {
    position: absolute;
    margin-top: 160px;
    width: 100%;
    height: 280px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.66),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.66),
      rgba(0, 0, 0, 0)
    );

    border-width: 1px;
    border-style: solid none;
    border-image: linear-gradient(to right, rgba(255, 204, 0, 0), #d4b27e, #ffeb80, #d4b27e, rgba(255, 204, 0, 0)) 1;
    opacity: 0;
    animation: simple-fadein 0.5s 1s forwards;

    display: flex;
    justify-content: center;
  }

  .text-bg {
    position: absolute;
    margin-top: 160px;
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
  }

  /*白色闪光*/
  .block-flash {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 1600px 280px;
    background-image: url('https://s1.ax1x.com/2022/11/28/zUvcX6.png');
    background-repeat: no-repeat;
    background-position: -1600px 0;
    animation: flash-fly 0.4s linear 1.1s forwards;
  }

  @keyframes flash-fly {
    from {
      background-position: -1600px 0;
    }
    to {
      background-position: 100vw 0;
    }
  }

  /*升级的标题文字，LEVEL UP!*/
  .main-title {
    position: absolute;
    opacity: 0;
    padding: 16px;
    /*background: linear-gradient(150deg, white, #FFF58A, #EBFF00, #00FFA3);*/
    background: linear-gradient(150deg, white, #fcf6ba, #b38728);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ebff00;
    font-size: 32px;
    font-weight: 500;
    margin-top: -425px;
    text-align: center;
    font-style: italic;
    animation: main-title-fly 2.4s cubic-bezier(0.02, 0.29, 0, 0.87) 2.1s forwards;
  }

  @keyframes main-title-fly {
    from {
      margin-top: -265px;
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /*升级的描述*/
  .gen-code {
    position: absolute;
    color: white;
    opacity: 0;
    margin-top: 56px;
    font-size: 20px;
    text-align: center;
    animation: simple-fadein 0.4s ease 1.3s forwards;
  }

  /*描述中绿色加重的高亮文字*/
  .desc-bold {
    font-weight: 500;
    color: #ebff00;
  }

  /*奖励内容的描述*/
  .desc {
    position: absolute;
    width: 70vw;
    max-width: 960px;
    margin-top: 92px;
    text-align: center;
  }

  .desc-details {
    width: 100%;
    height: fit-content;
    max-height: 74px;
    min-height: 48px;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    overflow: auto;
    color: wheat;
    opacity: 0;
    animation: simple-fadein 0.9s ease 1.5s forwards;

    scrollbar-width: 4px;
    scrollbar-color: rgba(255, 220, 190, 0.4) rgba(0, 0, 0, 0);
  }

  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(255, 220, 190, 0.4);
    border-radius: 20px;
  }

  .desc-properties {
    width: 100%;
    height: fit-content;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    max-height: 74px;
    font-size: 14px;
    color: #ccc;
    overflow: auto;
    opacity: 0;
    animation: simple-fadein 0.9s ease 1.7s forwards;
  }

  /*按钮*/
  .share-button {
    width: 200px;
    height: 32px;
    background: #4553ec;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    color: white;
    opacity: 0;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
    border-radius: 100px;
    cursor: pointer;
    animation: simple-fadein 0.7s ease 2s forwards;
  }

  .share-button:hover {
    background: white;
    color: #366cf9;
  }

  .card-flare {
    width: 100%;
    height: 640px;
    margin-bottom: 220px;
    background-image: url('https://s1.ax1x.com/2022/12/24/zv1sOA.png');
    background-size: 640px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20%;
    mix-blend-mode: color-dodge;
    /*mix-blend-mode: screen;*/
    opacity: 0;
    position: absolute;
    animation: flare-fx 1.8s 0.7s ease-in-out forwards;
  }

  @keyframes flare-fx {
    0% {
      opacity: 0;
      background-size: 20%;
    }
    30% {
      opacity: 0.9;
      background-size: 90%;
    }
    60% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
      transform: rotate(400deg);
      background-size: 20%;
    }
  }

  .card-container {
    width: 360px;
    height: 640px;
    margin-top: 48px;
    position: absolute;
    overflow: visible;
  }

  .card-frame {
    position: absolute;
    height: 160px;
    width: 160px;
    border-radius: 20%;
    border: 1px solid tan;
    overflow: hidden;
    filter: drop-shadow(0 -4px 8px rgba(255, 215, 167, 0.4)) drop-shadow(0 0 1px black)
      drop-shadow(0 4px 6px rgba(0, 0, 0, 0.8));
    offset-path: path('M180,400C391.5,279.5,346.19,17.91,180,28,56.5,35.5,55.5,200,180,200');
    animation: card-fly 1.2s forwards;
  }

  @keyframes card-fly {
    0% {
      offset-distance: 0;
      offset-rotate: 0deg;
      width: 16px;
      height: 16px;
    }
    100% {
      offset-distance: 100%;
      offset-rotate: 0deg;
    }
  }

  .card-bg {
    height: 100%;
    width: 100%;
    display: flex;
    background-image: linear-gradient(45deg, #fff7bc, #b38728, #fbf5b7, #996302);
    background-size: 160px 160px;
    justify-content: center;
    align-items: center;
    animation: card-bg-move 5s cubic-bezier(0.14, 0.32, 0.19, 1) infinite;
  }

  @keyframes card-bg-move {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 0 160px;
    }
  }

  .card-bg > img {
    height: 90%;
    width: 90%;
    border-radius: 17.5%;
    border: 1px solid wheat;
    filter: drop-shadow(0 0 2px black) drop-shadow(0 0 8px black);
  }

  @media only screen and (max-width: 600px) {
    .main-title {
      font-size: 24px;
      margin-top: -366px;
    }

    .card-container {
      width: 360px;
      height: 640px;
      margin-top: 64px;
    }

    .gen-code {
      font-size: 16px;
      margin-top: 48px;
    }

    .card-frame {
      height: 128px;
      width: 128px;
    }

    .desc {
      width: 95vw;
      left: calc(2.5% - 4px);
      margin-top: 84px;
    }

    .desc-details {
      padding-left: 4px;
      padding-right: 4px;
    }

    .desc-properties {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
`

const ShareButton = styled(ClickFlex)`
  &:hover {
    background: green;
  }

  align-items: center;
  justify-content: center;
  position: absolute;
  width: 200px;
  height: 32px;
  background: #89bd00;
  color: white;
  opacity: 0;
  margin-top: 88px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 32px;
  border-radius: 100px;
  cursor: pointer;
  animation: simple-fadein 0.5s ease 1.4s forwards;
`

export const AttributeChangeItem: React.FC<ILevelUpParams> = ({ itemId, preAndCurrent }) => {
  const { t } = useTranslation()
  return (
    <FlexGap gap="2px" alignItems="center" justifyContent="center">
      <Text fontSize="16px" textAlign="center">
        {t(itemId)} :
      </Text>

      <Text fontSize="16px" textAlign="center">
        {preAndCurrent.preValue} {`->`}
      </Text>

      <Text fontSize="16px" color="#B6FF1B" textAlign="center" className="lvlup-bold">
        {preAndCurrent.currentValue}
      </Text>

      <Text fontSize="16px" color="#B6FF1B" textAlign="center" className="lvlup-bold">
        (+{preAndCurrent.currentValue - preAndCurrent.preValue})
      </Text>
    </FlexGap>
  )
}

export interface GenReward extends InjectedModalProps {
  gen
}

export const GenRewardModal: React.FC<GenReward> = ({ gen, onDismiss }) => {
  const { t } = useTranslation()
  const bgImage = '/images/game/levelup/bgImage.png'
  const coreImage = '/images/game/levelup/coreImage.png'
  const flashImage = '/images/game/levelup/flashImage.png'
  const { walletAddress } = useWallet()
  const { communities } = useCommunities(walletAddress)
  const shareContent = `${DAGEN_DOMAIN}/profile/${walletAddress}`

  return (
    <ModalLevelUpContainer>
      <Container bgImage={bgImage} coreImage={coreImage} flashImage={flashImage}>
        <ClickFlex className="close-circle" onClick={() => onDismiss()}>
          <div className="close-mark">✕</div>
        </ClickFlex>

        <div className="main-bg" />
        <div className="card-flare" />
        <div className="block-bg">
          <div className="block-flash" />
        </div>
        <div className="card-container">
          <div className="card-frame">
            <div className="card-bg">
              <img src={`/images/gens/${gen.name}.png`} alt="Gen" />
            </div>
          </div>
        </div>
        <div className="text-bg">
          <div className="gen-code">
            {gen.name} <span className="desc-bold"> {gen.nickname}</span>
          </div>
          <div className="desc">
            <div className="desc-details">{t(gen.description)}</div>
            <div className="desc-properties">
              {gen.functions &&
                Object.keys(gen.functions).map((funKey) => {
                  return (
                    <Text key={funKey} fontSize="12px" color={gen.functions[funKey] < 0 ? '#FF7777' : '#EBFF00'}>
                      {t(funKey)} {gen.functions[funKey] < 0 ? '' : '+'}
                      {gen.functions[funKey]}
                      {genLightFunBgList[funKey] ? '%' : ''}
                    </Text>
                  )
                })}
            </div>

            <Button
              as={NextLinkFromReactRouter}
              className="share-button"
              target="_blank"
              to={shareTwitterUrl(t('I gained ') + gen.name, communities, shareContent)}
              onClick={() => {
                onDismiss()
              }}
            >
              <Text fontSize="14px" bold>
                {t('Share on Twitter')}
              </Text>
            </Button>
          </div>

          <div className="main-title">Gained new Gen!</div>
        </div>
      </Container>
    </ModalLevelUpContainer>
  )
}
