import { ChangeItem, EnergySun, IMiniPlayer, ISystemInfo, Scenes } from '@common/types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../../index'
import {
  updateAbsorbingChangeItems,
  updateEnergySuns,
  updateMiniPlayer,
  updateOthersChangeItems,
  updatePreGameConnect,
  updateScenes,
  updateSunSpeed,
  updateSystemInfo,
} from '../actions'

export function useMiniPlayer(): [IMiniPlayer, (miniPlayer: IMiniPlayer) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const miniPlayer = useSelector<AppState, AppState['player']['miniPlayer']>((state) => {
    return state.player.miniPlayer
  })

  const setMiniPlayer = useCallback(
    (miniPlayer: IMiniPlayer) => {
      dispatch(updateMiniPlayer(miniPlayer))
    },
    [dispatch],
  )

  return [miniPlayer, setMiniPlayer]
}

export function useEnergySuns(): [EnergySun[], (energySuns: EnergySun[]) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const energySuns = useSelector<AppState, AppState['player']['energySuns']>((state) => {
    return state.player.energySuns
  })

  const setEnergySuns = useCallback(
    (energySuns: any[]) => {
      dispatch(updateEnergySuns(energySuns))
    },
    [dispatch],
  )

  return [energySuns, setEnergySuns]
}

export function useSunSpeed(): [number, (sunSpeed: number) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const sunSpeed = useSelector<AppState, AppState['player']['sunSpeed']>((state) => {
    return state.player.sunSpeed
  })

  const setSunSpeed = useCallback(
    (sunSpeed: number) => {
      dispatch(updateSunSpeed(sunSpeed))
    },
    [dispatch],
  )

  return [sunSpeed, setSunSpeed]
}

export function useAbsorbingChangeItems(): [ChangeItem[], (changeItems: ChangeItem[]) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const changeItems = useSelector<AppState, AppState['player']['absorbingChangeItems']>((state) => {
    return state.player.absorbingChangeItems
  })

  const setChangeItems = useCallback(
    (changeItems: any[]) => {
      dispatch(updateAbsorbingChangeItems(changeItems))
    },
    [dispatch],
  )

  return [changeItems, setChangeItems]
}

export function useOthersChangeItems(): [ChangeItem[], (changeItems: ChangeItem[]) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const changeItems = useSelector<AppState, AppState['player']['othersChangeItems']>((state) => {
    return state.player.othersChangeItems
  })

  const setChangeItems = useCallback(
    (changeItems: any[]) => {
      dispatch(updateOthersChangeItems(changeItems))
    },
    [dispatch],
  )

  return [changeItems, setChangeItems]
}

export function useSystemInfo(): [ISystemInfo, (systemInfo: ISystemInfo) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const systemInfo = useSelector<AppState, AppState['player']['systemInfo']>((state) => {
    return state.player.systemInfo
  })

  const setSystemInfo = useCallback(
    (systemInfo: ISystemInfo) => {
      dispatch(updateSystemInfo(systemInfo))
    },
    [dispatch],
  )

  return [systemInfo, setSystemInfo]
}

export function usePreGameConnect(): [boolean, (preGameConnect: boolean) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const preGameConnect = useSelector<AppState, AppState['player']['preGameConnect']>((state) => {
    return state.player.preGameConnect
  })

  const setPreGameConnect = useCallback(
    (collectable: boolean) => {
      dispatch(updatePreGameConnect(collectable))
    },
    [dispatch],
  )

  return [preGameConnect, setPreGameConnect]
}

export function useScenes(): [Scenes[], (scenes: Scenes[]) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const scenes = useSelector<AppState, AppState['player']['scenes']>((state) => {
    return state.player.scenes
  })

  const setScenes = useCallback(
    (scenes: Scenes[]) => {
      dispatch(updateScenes(scenes))
    },
    [dispatch],
  )

  return [scenes, setScenes]
}
