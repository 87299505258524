import { Handler } from '@library/uikit/src/widgets/Modal/types'
import { ILifeFormHS } from '../server/game/Types'
import { FusionOrder, Gen, ILifeForm, PositionItem, Profile, RewardItem, SceneEvent } from '../server/model'

export interface IMiniPlayer {
  isNight?: boolean
  time?: string
  profile?: Profile
  lifeForm?: ILifeFormHS
  friends?: Friend[]
}

export interface Friend {
  address: string
  energy?: number
  gensCount?: number
  lifeFormType?: number
  avatar?: string
  index?: number
}

export interface BattlePlayer {
  address: string
  ep: number
  hp: number
  attack: number
  defense: number
  fiber?: number
  chitin?: number
  maxEP?: number
  maxFiber?: number
  maxChitin?: number
}

export interface BattleRound {
  attackerDamage: number
  affecterDamage: number
  battleRound: number
}

export interface BattleRecord {
  isAttacker: boolean
  icon: string
  nickName: string
  address: string
  createdAt: number
  isWin: number
}

export interface BattleOfflineRecordItem {
  icon: string
  attackNickname: string
  attacker: string
  attackNum: number
  attackSuccess: number
  onDismiss?: Handler
}

export interface BattleOfflineRecord {
  stealNum: number
  reward: RewardItem[]
  loss: RewardItem[]
  recordItems: BattleOfflineRecordItem[]
}

export interface BattleTarget {
  hp: number
  icon: string // 玩家头像
  bg?: string // 玩家战场背景
}

export enum NftTokenInWalletStatus {
  SHOW,
  CANCEL_FUSION,
  CANCEL_MARKET,
  DISMISS,
  ONLY_FUSION,
  ONLY_OFFERING,
  CAN_NOT_FUSION, // 头像nft不能融合
  SHOW_OFF,
}

export interface NftTokenInWallet {
  tokenId: string
  name: string
  description: string
  image: any
  preview: string
  collectionAddress: string
  status: NftTokenInWalletStatus // SHOW: 无状态  CANCEL_FUSION: 融合（可以撤销） CANCEL_MARKET: 市场单(可以取消市场) DISMISS: 在上阵 是PGen （不显示）
  marketId?: string
  fusionId?: string
}

export interface MaterialFull {
  ep: boolean
  chitin: boolean
  fiber: boolean
}

export interface FriendMsg {
  msgId: number
  seAddress: string
  reAddress: string
  type: number
  msg: string
  time: number
}

export const allAsset = {
  friends: '/images/game/online.svg',
  epRank: '/images/game/rank.svg',
  energy: '/images/game/icon/energy.svg',
  gCent: '/images/game/icon/g-cent.svg',
  gensCount: '/images/game/icon/gens.svg',
}

export interface EnergySun {
  stamp?: number
  ep?: number
}

export interface ChangeItem {
  energySuns?: EnergySun[]
  lifeForm?: Partial<ILifeForm>
  profile?: Partial<Profile>
}

export interface ChangeItemEvent extends ChangeItem {
  eventName: string
}

export interface BattleInfo {
  attacker: string
  affecter: string
}

export interface ISystemInfo {
  onlineCount: number
  sceneEvents: SceneEvent[]
  bullet?: BattleInfo
  battleRecord?: BattleOfflineRecord
}

export interface FusionItem extends FusionOrder {
  _id: string
  gen: Gen
  profile: Profile
  takeDown?: boolean
}

export interface FusionProductCond {
  condition: string
  gene: string
  id: string
  name: string
  note: string
  rank: number
}

export interface FusionProduct {
  cond?: FusionProductCond
  genId?: string
  gene?: string
  genome?: string[]
}

export interface Condition {
  walletAddress?: string
  params?: any
}

export interface EventHandler {
  eventCode: string
  handler: (condition: Condition) => any
}

export enum Scenes {
  Home = 1,
  LifeFormDesign,
  GenList,
}

export interface ILevelUpParams {
  itemId: string
  preAndCurrent: IPreAndCurrent
}

export interface IPreAndCurrent {
  currentValue: number
  preValue: number
}

export const initPreAndCurrent: IPreAndCurrent = { currentValue: 0, preValue: 0 }

export interface ItemNotEnough {
  itemId: string
  currentValue: number
  needValue: number
}

export interface Item {
  itemId: string
  itemNum: number
}

export interface TaskTypeMap {
  icon?: string
  desc: string
  action: string
  color: string
}

export interface IMaterial {
  field: string
  maxFiled: string
  name: string
}

export interface PlayerExpDict {
  level: number
  lvlExp: number
  rewards?: PositionItem[]
}

export interface AttributeLevelDict {
  level: number
  maxHP: number
}

export interface CommunityRank {
  name?: string
  collectionAddress?: string
  icon?: string
  level: number
}
