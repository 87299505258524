import {
  ADD_FRIEND,
  APPLY_FRIEND,
  BATTLE_FINISH,
  CHANGE_FINALLY_ITEM,
  CHANGE_ITEM,
  CROSS_DAYS,
  DELETE_FRIEND,
  FUSION_ORDER_FINISH,
  LOGIN,
  LOGOUT,
  PLAYER_LEVEL_UP,
  PLAYER_OFFLINE,
  PLAYER_ONLINE,
  SCENE_EVENT,
  SYNC_BARRAGE,
  SYNC_GUIDE,
  SYNC_PLAYER,
  SYNC_TIME,
  TASK,
  WEATHER_CHANGE,
} from '@common/constans'
import { useEffect, useRef, useState } from 'react'
import { useDagenToken } from 'state/user/hooks'
import { useMiniPlayer } from '../state/player/hooks'
import useEventMsg1000Login from './event/useEventMsg1000Login'
import useEventMsg1001SceneEvent from './event/useEventMsg1001SceneEvent'
import useEventMsg1002PlayerOnline from './event/useEventMsg1002PlayerOnline'
import useEventMsg1003PlayerOffline from './event/useEventMsg1003PlayerOffline'
import useEventMsg1004CrossDay from './event/useEventMsg1004CrossDay'
import useEventMsg1005Task from './event/useEventMsg1005Task'
import useEventMsg1006ChangeItem from './event/useEventMsg1006ChangeItem'
import useEventMsg1007WeatherChange from './event/useEventMsg1007WeatherChange'
import useEventMsg1008SyncPlayer from './event/useEventMsg1008SyncPlayer'
import useEventMsg1010SyncGuide from './event/useEventMsg1010SyncGuide'
import useEventMsg1011SyncTime from './event/useEventMsg1011SyncTime'
import useEventMsg1012SyncBarrage from './event/useEventMsg1012SyncBarrage'
import useEventMsg1013SyncBattleFinish from './event/useEventMsg1013SyncBattleFinish'
import useEventMsg1014SyncFinallyItem from './event/useEventMsg1014SyncFinallyItem'
import useEventMsgLogout from './event/useEventMsgLogout'
import { useDagenApi } from './useDagenApi'
import useWallet from './useWallet'
import useEventMsg1015PayerLevelUp from './event/useEventMsg1015PayerLevelUp'
import useEventMsg1016FusionOrderFinish from './event/useEventMsg1016FusionOrderFinish'
import useEventMsg1018ApplyFriend from './event/useEventMsg1018ApplyFriend'
import useEventMsg1019DeleteFriend from './event/useEventMsg1019DeleteFriend'
import useEventMsg1020AddFriend from './event/useEventMsg1020AddFriend'

const useEventSource = () => {
  const evtSource = useRef<EventSource | null>(null)
  const dagenApi = useDagenApi()
  const { walletAddress } = useWallet()
  const [isConnect, setConnect] = useState(false)
  const [_, setMiniPlayer] = useMiniPlayer()
  const [dagenToken] = useDagenToken()

  const msgEventCodes = [
    { key: LOGIN, handler: useEventMsg1000Login() },
    { key: LOGOUT, handler: useEventMsgLogout() },
    { key: SCENE_EVENT, handler: useEventMsg1001SceneEvent() },
    { key: PLAYER_ONLINE, handler: useEventMsg1002PlayerOnline() },
    { key: PLAYER_OFFLINE, handler: useEventMsg1003PlayerOffline() },
    { key: CROSS_DAYS, handler: useEventMsg1004CrossDay() },
    { key: TASK, handler: useEventMsg1005Task() },
    { key: CHANGE_ITEM, handler: useEventMsg1006ChangeItem() },
    { key: WEATHER_CHANGE, handler: useEventMsg1007WeatherChange() },
    { key: SYNC_PLAYER, handler: useEventMsg1008SyncPlayer() },
    { key: SYNC_GUIDE, handler: useEventMsg1010SyncGuide() },
    { key: SYNC_TIME, handler: useEventMsg1011SyncTime() },
    { key: SYNC_BARRAGE, handler: useEventMsg1012SyncBarrage() },
    { key: BATTLE_FINISH, handler: useEventMsg1013SyncBattleFinish() },
    { key: CHANGE_FINALLY_ITEM, handler: useEventMsg1014SyncFinallyItem() },
    { key: PLAYER_LEVEL_UP, handler: useEventMsg1015PayerLevelUp() },
    { key: FUSION_ORDER_FINISH, handler: useEventMsg1016FusionOrderFinish() },
    { key: APPLY_FRIEND, handler: useEventMsg1018ApplyFriend() },
    { key: DELETE_FRIEND, handler: useEventMsg1019DeleteFriend() },
    { key: ADD_FRIEND, handler: useEventMsg1020AddFriend() },
  ]

  useEffect(() => {
    const preConnectServer = async () => {
      if (isConnect) return
      const res = await dagenApi.post('preConnect', { walletAddress })
      if (!res.data.success) {
        return
      }
      setConnect(true)
    }

    if (walletAddress) preConnectServer()
  }, [dagenApi, walletAddress, isConnect])

  useEffect(() => {
    if (walletAddress && isConnect && dagenToken) {
      // 清空miniPlayer
      setMiniPlayer({})
      evtSource.current = dagenApi.eventSource(`gameConnect?address=${walletAddress}&token=${dagenToken}`)
      for (const msgEventCode of msgEventCodes) {
        evtSource.current.addEventListener(msgEventCode.key, msgEventCode.handler)
      }
    }

    return () => {
      if (evtSource.current) {
        for (const msgEventCode of msgEventCodes) {
          evtSource.current.removeEventListener(msgEventCode.key, () => console.info(`[remove${msgEventCode.key}]`))
        }
        evtSource.current.close()
        evtSource.current = null
        setConnect(false)
      }
    }
  }, [dagenApi, walletAddress, setMiniPlayer, isConnect, dagenToken])

  return { evtSource, isConnect }
}

export default useEventSource
