import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import noop from 'lodash/noop'
import { useContext } from 'react'
import { LEVEL_PRIORITY } from '@common/constans'
import { LevelUpModals } from '../../views/Game/LevelUpModals'

export default function useLevelUpManager() {
  const { isOpen, onPresent, onDismiss } = useContext(MContext)
  const showLevelUp = (level, params, callback = noop) => {
    return onPresent(
      <LevelUpModals
        level={level}
        params={params}
        callback={() => {
          callback()
          onDismiss()
        }}
      />,
      `LevelUpModals_`,
      LEVEL_PRIORITY,
    )
  }

  return { showLevelUp, onDismiss, isOpen }
}
