import { createReducer } from '@reduxjs/toolkit'
import { DEFAULT_DEADLINE_FROM_NOW, INITIAL_ALLOWED_SLIPPAGE } from '../../config/constants'
import { resetUserState, updateVersion } from '../global/actions'
import {
  hideAnnouncementBanner,
  muteAudio,
  unmuteAudio,
  updateDagenToken,
  updateEnergyCollectable,
  updateFusionConfirm,
  updateGasPrice,
  updateGenCollectable,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserSingleHopOnly,
  updateUserSlippageTolerance,
} from './actions'
import { GAS_PRICE_GWEI } from './hooks/helpers'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number

  userExpertMode: boolean

  // only allow swaps on direct pairs
  userSingleHopOnly: boolean

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number

  // deadline set by user in minutes, used in all txns
  userDeadline: number
  dagenToken: string
  timestamp: number
  audioPlay: boolean
  gasPrice: string
  hideTimestampAnnouncementBanner: number

  energyCollectable: boolean
  genCollectable: boolean

  fusionConfirm: boolean
}

export const initialState: UserState = {
  energyCollectable: false,
  genCollectable: false,
  userExpertMode: false,
  userSingleHopOnly: false,
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  dagenToken: '',
  timestamp: currentTimestamp(),
  audioPlay: true,
  gasPrice: GAS_PRICE_GWEI.default,
  hideTimestampAnnouncementBanner: null,
  fusionConfirm: true,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userSlippageTolerance !== 'number') {
        state.userSlippageTolerance = INITIAL_ALLOWED_SLIPPAGE
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userDeadline !== 'number') {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW
      }

      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(resetUserState, (state) => {
      state.dagenToken = ''
    })
    .addCase(updateDagenToken, (state, action) => {
      state.dagenToken = action.payload.dagenToken
    })
    .addCase(updateUserExpertMode, (state, action) => {
      state.userExpertMode = action.payload.userExpertMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserSingleHopOnly, (state, action) => {
      state.userSingleHopOnly = action.payload.userSingleHopOnly
    })
    .addCase(muteAudio, (state) => {
      state.audioPlay = false
    })
    .addCase(unmuteAudio, (state) => {
      state.audioPlay = true
    })
    .addCase(updateGasPrice, (state, action) => {
      state.gasPrice = action.payload.gasPrice
    })
    .addCase(hideAnnouncementBanner, (state) => {
      state.hideTimestampAnnouncementBanner = currentTimestamp()
    })
    .addCase(updateEnergyCollectable, (state, action) => {
      state.energyCollectable = action.payload
    })
    .addCase(updateFusionConfirm, (state, action) => {
      state.fusionConfirm = action.payload
    })
    .addCase(updateGenCollectable, (state, action) => {
      state.genCollectable = action.payload
    }),
)
