import React from 'react'
import { Preview } from 'views/Gens/Market/Fusion/hooks/useFusion'
import useFusionManager from '../game/useFusionManager'

const useEventMsg1016FusionOrderFinish = () => {
  const { showFusionFinish } = useFusionManager()

  return async (event) => {
    const chargeAndGen: { preview: Preview; nftId: string } = JSON.parse(event.data)
    await showFusionFinish(chargeAndGen.preview)
    console.info(`[融合完成获得新Gen]`, chargeAndGen)
  }
}

export default useEventMsg1016FusionOrderFinish
