import React from 'react'
import useCustomToastManager from '../game/useCustomToastManager'
import FriendDeleteModal from '../../views/Game/FriendDeleteModal'

const useEventMsg1019DeleteFriend = () => {
  const { showToastCustom } = useCustomToastManager()

  return async (event) => {
    const { nickName } = JSON.parse(event.data)
    showToastCustom(<FriendDeleteModal nickName={nickName} />)
  }
}

export default useEventMsg1019DeleteFriend
