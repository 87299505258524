import { FetchStatus } from 'config/constants/types'
import { useDagenApi } from 'hooks/useDagenApi'
import useWallet from 'hooks/useWallet'
import useSWR, { KeyedMutator } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { Profile } from '../types'
import { getProfile, GetProfileResponse } from './helpers'

export const useProfileForAddress = (
  address: string,
  fetchConfiguration = {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  },
): {
  profile?: Profile
  isFetching: boolean
  isValidating: boolean
  refresh: KeyedMutator<GetProfileResponse>
} => {
  const dagenApi = useDagenApi()
  const { data, status, mutate, isValidating } = useSWR(
    address ? [address, 'profile'] : null,
    () => getProfile(dagenApi, address),
    fetchConfiguration,
  )

  const { profile } = data ?? { profile: null }

  return {
    profile,
    isFetching: status === FetchStatus.Fetching,
    isValidating,
    refresh: mutate,
  }
}

export const useProfile = (): {
  profile?: Profile
  hasProfile: boolean
  hasActiveProfile: boolean
  isInitialized: boolean
  isLoading: boolean
  refresh: KeyedMutator<GetProfileResponse>
} => {
  const { walletAddress } = useWallet()
  const dagenApi = useDagenApi()
  const { data, status, mutate } = useSWRImmutable(walletAddress ? [walletAddress] : null, (walletAddress) =>
    getProfile(dagenApi, walletAddress),
  )

  const { profile } = data ?? ({ profile: null } as GetProfileResponse)

  const isLoading = status === FetchStatus.Fetching
  const isInitialized = status === FetchStatus.Fetched || status === FetchStatus.Failed
  const hasProfile = isInitialized && !!profile
  const hasActiveProfile = hasProfile && !!profile

  return { profile, hasProfile, hasActiveProfile, isInitialized, isLoading, refresh: mutate }
}
