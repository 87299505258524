import { PlayerExpDict } from '@common/types'
import axios from 'axios'
import { useState } from 'react'
import useSWRImmutable from 'swr/immutable'

const playerExps = new Map<number, PlayerExpDict>()

const useCharaterManager = () => {
  const [maxLv, setMaxLv] = useState()

  useSWRImmutable('/config/level.csv', async (url) => {
    const csv = await axios.get(url)

    const { parse } = await import('csv-parse/browser/esm/sync')
    const records: [] = parse(csv.data, { skip_empty_lines: true, from: 4 })

    for (let i = 0; i < records.length; i++) {
      const record = records[i]
      const level = Number(record[0])
      if (Number.isNaN(Number(level))) {
        throw new Error(`经验表 ${i}行 level 转化错误`)
      }

      const lvlExp = Number(record[1])
      if (Number.isNaN(lvlExp)) {
        throw new Error(`经验表 ${i}行 lvlExp 转化错误`)
      }
      playerExps.set(level, { level, lvlExp })
    }

    const lastRecord = records[records.length - 1]
    setMaxLv(lastRecord[0])
  })

  return { maxLv, playerExps }
}

export default useCharaterManager
