import { parseUnits } from '@ethersproject/units'
import {
  Box,
  Button,
  CloseIcon,
  Flex,
  Grid,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  Text,
} from '@library/uikit'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useTranslation } from '@library/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useCatchTxError from 'hooks/useCatchTxError'
import { useERC20 } from 'hooks/useContract'
import { useDagenApi } from 'hooks/useDagenApi'
import useToast from 'hooks/useToast'
import { useSession } from 'next-auth/react'
import NextImage from 'next/image'
import { useCallback, useState } from 'react'
import { useProfile } from 'state/profile/hooks'
import styled from 'styled-components'

type ReferProps = InjectedModalProps

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`

const P = styled(Box)`
  :hover span {
    animation: flicker 1s linear forwards;
  }

  :hover #a {
    animation-delay: 0.2s;
  }

  :hover #b {
    animation-delay: 0.5s;
  }

  :hover #c {
    animation-delay: 0.6s;
  }

  :hover #d {
    animation-delay: 0.8s;
  }

  @keyframes flicker {
    0% {
      color: #fff;
    }
    5%,
    15%,
    25%,
    30%,
    100% {
      color: #fff;
      text-shadow: 0px 0px 5px #42fff6, 0px 0px 10px #42fff6, 0px 0px 20px #42fff6, 0px 0px 50px #42fff6;
    }
    10%,
    20% {
      color: #fff;
      text-shadow: none;
    }
  }
`

const chargePatterns = [
  { image: '/images/diamonds/D1.jpg', amount: 1000, usd: 10 },
  { image: '/images/diamonds/D2.jpg', amount: 2000, usd: 20 },
  { image: '/images/diamonds/D3.jpg', amount: 5000, usd: 50 },
  { image: '/images/diamonds/D4.jpg', amount: 10000, usd: 100 },
  { image: '/images/diamonds/D5.jpg', amount: 20000, usd: 200 },
  { image: '/images/diamonds/D6.jpg', amount: 50000, usd: 500 },
]

const GoldChargeModal: React.FC<ReferProps> = ({ onDismiss }) => {
  const { toastSuccess, toastError } = useToast()
  const { t } = useTranslation()
  const { fetchWithCatchTxError } = useCatchTxError()
  const dagenApi = useDagenApi()
  const { refresh } = useProfile()

  const { chainId } = useActiveWeb3React()
  const { data: session } = useSession()
  const usdc = useERC20(
    chainId === 1 ? '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48' : '0x466595626333c55fa7d7Ad6265D46bA5fDbBDd99',
  )
  const [fiat, setFiat] = useState(!!session)

  const charge = useCallback(
    async (amount: string) => {
      // session mean fiat else mean call erc20 transfer
      if (fiat) {
        const res = await dagenApi.get('chargeGoldPaymentLink', {
          params: { amount, redirectUrl: window.location.href },
        })
        if (res.data.success) {
          window.location = res.data.result.url
        } else {
          toastError(res.data.error)
        }
      } else {
        const receipt = await fetchWithCatchTxError(async () => {
          return usdc.transfer('0xFf305dBbF500875f9b981092E8A827835CaCFff1', parseUnits(amount, await usdc.decimals()))
        })
        if (receipt) {
          toastSuccess(
            t('Charge success, might wait for a send for sync G Cent'),
            <ToastDescriptionWithTx txHash={receipt.transactionHash} />,
          )
          setTimeout(() => {
            refresh()
          }, 3000)
        }
      }
    },
    [dagenApi, fetchWithCatchTxError, fiat, refresh, t, toastError, toastSuccess, usdc],
  )

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>
            {t('Charge')}

            <Button disabled={fiat} ml={2} scale="xs" onClick={() => setFiat(!fiat)}>
              {t('Credit card')}
            </Button>

            <Button disabled={!fiat} ml={2} scale="xs" onClick={() => setFiat(!fiat)}>
              {t('USDC')}
            </Button>
          </Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="24px" width="100%">
        <Grid gridGap="32px" gridTemplateColumns={['repeat(3, 1fr)']} alignItems="start">
          {chargePatterns.map((p) => {
            return (
              <P key={p.amount} style={{ cursor: 'pointer' }} onClick={() => charge(String(p.usd))}>
                <Flex flexDirection="column" style={{ borderRadius: 12, overflow: 'hidden' }}>
                  <NextImage width={240} height={240} draggable={false} src={p.image} />
                </Flex>
                <Text>
                  <span>{fiat ? p.amount * 0.96 : p.amount}</span>
                  <span id="a"> G</span>
                  <span id="b">=</span>
                  <br />
                  <span id="c">{p.usd}</span>
                  <span id="d"> {fiat ? 'USD' : 'USDC'}</span>
                </Text>
              </P>
            )
          })}
        </Grid>
      </ModalBody>
    </ModalContainer>
  )
}

export default GoldChargeModal
