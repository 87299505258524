import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1422 1024" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1245.029994 0.03622H177.059423A177.041313 177.041313 0 0 0 0 177.077533v669.881154a177.041313 177.041313 0 0 0 177.059423 177.041313h1067.970571a177.041313 177.041313 0 0 0 177.041313-177.041313V177.041313A177.041313 177.041313 0 0 0 1245.029994 0.03622z m98.752689 59.363893l-657.874364 449.122807a16.588568 16.588568 0 0 1-6.501415 2.64403 17.113752 17.113752 0 0 1-22.836446 3.477079L45.83588 97.647991a17.186191 17.186191 0 1 1 19.377476-28.378042l601.552915 410.675722L1324.405207 30.967742a17.188002 17.188002 0 0 1 19.377476 28.396152z"
        fill="#F29600"
        p-id="2571"
      />
    </Svg>
  );
};

export default Icon;
