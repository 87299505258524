// Set of helper functions to facilitate wallet setup

import { ExternalProvider } from '@ethersproject/providers'
import { BASE_URL } from 'config'
import providers from '@common/providers.json'
import { networkConfigs } from './networks'

/**
 * Prompt the user to add as a network on Metamask, or switch to if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainId: number, externalProvider?: ExternalProvider) => {
  const provider = externalProvider || window.ethereum
  if (provider) {
    try {
      const res = await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      // { error: { code: 4902, message: "the chain has not been added to Wallet 3" } }
      if (res && res.error) throw res.error
      return true
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: networkConfigs[chainId].chainName,
                nativeCurrency: {
                  name: networkConfigs[chainId].currencyName,
                  symbol: networkConfigs[chainId].currencySymbol,
                  decimals: 18,
                },
                rpcUrls: providers[chainId],
                blockExplorerUrls: [`${networkConfigs[chainId].blockExplorerUrl}/`],
              },
            ],
          })
          return true
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error)
          return false
        }
      }
      return false
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined")
    return false
  }
}

export const addNetwork = async (chainId: number) => {
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${chainId.toString(16)}`,
          chainName: networkConfigs[chainId].chainName,
          nativeCurrency: {
            name: networkConfigs[chainId].currencyName,
            symbol: networkConfigs[chainId].currencySymbol,
            decimals: 18,
          },
          rpcUrls: providers[chainId],
          blockExplorerUrls: [`${networkConfigs[chainId].blockExplorerUrl}/`],
        },
      ],
    })
    return true
  } catch (error) {
    // console.error('Failed to setup the network in Metamask:', error)
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${BASE_URL}/images/tokens/${tokenAddress}.png`,
      },
    },
  })

  return tokenAdded
}
