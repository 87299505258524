import { DAGEN_DOMAIN, genLightFunBgList, taskTypeMap } from '@common/constans'
import { useTranslation } from '@library/localization'
import {
  Box,
  Button,
  CloseIcon,
  Flex,
  InjectedModalProps,
  ModalTaskBody,
  ModalTaskContainer,
  Text,
} from '@library/uikit'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import noop from 'lodash/noop'
import NextImage from 'next/image'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { allAsset } from '@common/types'
import { Preview } from 'views/Gens/Market/Fusion/hooks/useFusion'
import { SlotIcon } from 'views/Gens/Market/Fusion/shared'
import useWallet from 'hooks/useWallet'
import useCommunities from 'hooks/useCommunities'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { shareTwitterUrl } from 'utils'
import { FlexGapCenter } from '../../components/Layout/Flex'

export interface TaskContent {
  image: string
  descs: string[]
  reward?: string
}

export interface Task {
  dictId?: number
  type: 'welcome' | 'accept' | 'finish' | 'reward' | 'fail'
  title: string
  itemIcon?: string
  content: TaskContent | ReactNode
  finishContent?: TaskContent | ReactNode
  showButton?: boolean
  buttonDesc?: string
}

export interface TaskProps extends InjectedModalProps, Task {
  closeCallback?: () => void
  callback?: () => void
  onDismiss?
}

export interface TaskFinishProps extends InjectedModalProps, Task {
  taskFinish?: () => void
}

export const TaskTitle = styled(Flex)<{ color: string }>`
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color ?? '#4553EC'};
  border-radius: 12px 12px 0 0;
  height: 52px;
  gap: 20px;
`

const TaskContentDesc = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 18px;
  min-height: 250px;
  padding: 8px;
  overflow: hidden;
`

export const TaskButton = styled(ClickFlex)<{ color?: string }>`
  width: 140px;
  height: 40px;
  margin-bottom: 20px;
  background-color: ${({ color }) => color ?? '#4553EC'};
  border-radius: 26px;
  align-items: center;
  justify-content: center;
`

const StyledTaskContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  align-self: center;
  text-align: center;
  gap: 10px;
  padding: 10px 10px;
`

interface FusionContentProps {
  onShareCallback: () => void
  preview: Preview
}

export const FusionContent: React.FC<FusionContentProps> = ({ preview, onShareCallback }) => {
  const { t } = useTranslation()
  const { walletAddress } = useWallet()
  const { communities } = useCommunities(walletAddress)
  const shareContent = `${DAGEN_DOMAIN}/profile/${walletAddress}`

  return (
    <Flex width="100%" flexDirection="column">
      <Flex mt={10} justifyContent="center">
        <SlotIcon width={90} height={90}>
          <NextImage src={`/images/gens/${preview.gen.name}.png`} width={90} height={90} />
        </SlotIcon>
      </Flex>

      <Text my={10} textAlign="center" color="#EBFF00" fontSize="16px">
        {preview.gen.name}
      </Text>

      <Text textAlign="center" fontSize="12px" maxWidth={200}>
        {t(preview.gen.description)}
      </Text>

      {preview.gen.functions &&
        Object.keys(preview.gen.functions).map((funKey) => {
          return (
            <Text key={funKey} fontSize="12px" color={preview.gen.functions[funKey] < 0 ? '#FF7777' : '#EBFF00'}>
              {t(funKey)} {preview.gen.functions[funKey] < 0 ? '' : '+'}
              {preview.gen.functions[funKey]}
              {genLightFunBgList[funKey] ? '%' : ''}
            </Text>
          )
        })}

      {preview.cost && (
        <FlexGapCenter my={10} gap="10px">
          <Text>{t('Cost')}</Text>

          {preview.cost?.energy && (
            <>
              <NextImage src={allAsset.energy} width={12} height={12} />
              <Text ml={1}>{preview.cost.energy}</Text>
            </>
          )}

          {preview.cost?.gCent && (
            <>
              <NextImage src={allAsset.gCent} width={12} height={12} />
              <Text ml={1}>{preview.cost.gCent}</Text>
            </>
          )}
        </FlexGapCenter>
      )}

      {preview.charge && (
        <FlexGapCenter my={10} gap="10px">
          <Text>{t('Get')}</Text>

          {preview.charge?.energy && (
            <>
              <NextImage src={allAsset.energy} width={12} height={12} />
              <Text ml={1}>{preview.charge.energy}</Text>
            </>
          )}

          {preview.charge?.gCent && (
            <>
              <NextImage src={allAsset.gCent} width={12} height={12} />
              <Text ml={1}>{preview.charge.gCent}</Text>
            </>
          )}
        </FlexGapCenter>
      )}

      <TaskButton
        mt={20}
        mx="auto"
        as={NextLinkFromReactRouter}
        target="_blank"
        to={shareTwitterUrl(t('I fused a new Gen'), communities, shareContent)}
        color={taskTypeMap.welcome.color}
        onClick={onShareCallback}
      >
        <Text fontSize="14px" bold>
          {t('Share')}
        </Text>
      </TaskButton>
    </Flex>
  )
}

export const TaskModal: React.FC<TaskProps> = ({
  type,
  itemIcon,
  title,
  content,
  showButton = true,
  buttonDesc,
  callback = noop,
  closeCallback = noop,
  onDismiss,
}) => {
  const { t } = useTranslation()
  console.info('[TaskModal显示对话框]')

  const onCloseClick = () => {
    closeCallback()
    onDismiss()
  }

  return (
    <ModalTaskContainer color={taskTypeMap[type].color}>
      <TaskTitle color={taskTypeMap[type].color}>
        {itemIcon ? (
          <NextImage src={`/images/game/task/${itemIcon}.svg`} width={22} height={22} />
        ) : (
          <NextImage src={`/images/game/task/${taskTypeMap[type].icon}`} width={22} height={22} />
        )}

        <Text fontSize="20px" fontWeight="400" textAlign="center">
          {t(taskTypeMap[type].desc)}
        </Text>

        <Button style={{ position: 'absolute', right: 0 }} onClick={() => onCloseClick()} variant="text">
          <CloseIcon />
        </Button>
      </TaskTitle>
      <ModalTaskBody flexDirection="column">
        {title ? (
          <Flex alignItems="center" justifyContent="center" px="5px">
            <Text fontSize="14px" fontWeight="400" mt={12} mb={9} textAlign="center">
              {t(title)}
            </Text>
          </Flex>
        ) : null}

        <TaskContentDesc>
          {type === 'reward' ? (
            content
          ) : (
            <Box>
              <NextImage src={(content as TaskContent).image} width={560} height={220} />

              <StyledTaskContent>
                {(content as TaskContent).descs.map((desc) => (
                  <Text key={desc}>{t(desc)}</Text>
                ))}

                {(content as TaskContent).reward && (
                  <Text color="#FFD600" mt={10}>
                    {t((content as TaskContent).reward)}
                  </Text>
                )}
              </StyledTaskContent>
            </Box>
          )}
        </TaskContentDesc>

        {showButton ? (
          <TaskButton
            color={taskTypeMap[type].color}
            onClick={() => {
              onDismiss()
              callback()
            }}
          >
            <Text fontSize="14px" bold>
              {buttonDesc || t(taskTypeMap[type].action)}
            </Text>
          </TaskButton>
        ) : null}
      </ModalTaskBody>
    </ModalTaskContainer>
  )
}
