import { FetchStatus } from 'config/constants/types'
import useSWR from 'swr'
import { useDagenApi } from './useDagenApi'

const useCommunities = (walletAddress: string) => {
  const dagenApi = useDagenApi()

  const { data, status, mutate } = useSWR(
    walletAddress ? ['communities', walletAddress] : null,
    async (url, walletAddress) => {
      const res = await dagenApi.get(url, { params: { walletAddress } })
      return res.data.result
    },
  )

  return { communities: data ?? [], isLoading: status !== FetchStatus.Fetched, refresh: mutate }
}

export default useCommunities
