import React from 'react'
import useCustomToastManager from '../game/useCustomToastManager'
import AddFriendNoticeModal from '../../views/Game/AddFriendNoticeModal'

const useEventMsg1020AddFriend = () => {
  const { showToastCustom } = useCustomToastManager()

  return async (event) => {
    const { nickName } = JSON.parse(event.data)
    showToastCustom(<AddFriendNoticeModal nickName={nickName} />)
  }
}

export default useEventMsg1020AddFriend
