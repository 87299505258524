import type { Signer } from '@ethersproject/abstract-signer'
import { Contract } from '@ethersproject/contracts'
import { Provider, StaticJsonRpcProvider } from '@ethersproject/providers'
import blindBox from 'config/abi/blindBox.json'
import dagenItemsAbi from 'config/abi/dagenItems.json'
import dagenMarketAbi from 'config/abi/dagenMarket.json'
import dagenToken from 'config/abi/dagenToken.json'
import erc1155Abi from 'config/abi/erc1155.json'
import erc721Abi from 'config/abi/erc721.json'
import erc20Abi from 'config/abi/erc20.json'
import MultiCallAbi from 'config/abi/Multicall.json'
// Types
import type { BlindBox, DagenItems, DagenMarket, DagenToken, Erc1155, Erc20, Erc721, Multicall } from 'config/abi/types'
import { CSS_ADDRESS } from 'config/constants/info'
import sample from 'lodash/sample'
import providers from '@common/providers.json'
import {
  getBadgeAddress,
  getBadgeBlindBoxAddress,
  getBlindBoxAddress,
  getDagenTokenAddress,
  getItemsAddress,
  getMarketAddress,
  getMulticallAddress,
} from '../../common/addressHelpers'
// Addresses

const getContract = (chainId: number, abi: any, address: string, signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? new StaticJsonRpcProvider(sample(providers[chainId]))
  return new Contract(address, abi, signerOrProvider)
}

export const getErc20Contract = (chainId: number, address: string, signer?: Signer | Provider) => {
  return getContract(chainId, erc20Abi, address, signer) as Erc20
}
export const getErc721Contract = (chainId: number, address: string, signer?: Signer | Provider) => {
  return getContract(chainId, erc721Abi, address, signer) as Erc721
}
export const getErc1155Contract = (chainId: number, address: string, signer?: Signer | Provider) => {
  return getContract(chainId, erc1155Abi, address, signer) as Erc1155
}
export const getDagenTokenContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract(chainId, dagenToken, getDagenTokenAddress(chainId), signer) as DagenToken
}
export const getBlindBoxContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract(chainId, blindBox, getBlindBoxAddress(chainId), signer) as BlindBox
}
export const getBadgeBlindBoxContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract(chainId, blindBox, getBadgeBlindBoxAddress(chainId), signer) as BlindBox
}
export const getMulticallContract = (chainId = 1) => {
  return getContract(chainId, MultiCallAbi, getMulticallAddress()) as Multicall
}
export const getMarketContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract(chainId, dagenMarketAbi, getMarketAddress(chainId), signer) as DagenMarket
}
export const getItemsContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract(chainId, dagenItemsAbi, getItemsAddress(chainId), signer) as DagenItems
}
export const getBadgeContract = (chainId: number, signer?: Signer | Provider) => {
  return getContract(chainId, dagenItemsAbi, getBadgeAddress(chainId), signer) as DagenItems
}

export const getCSSContract = (signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? new StaticJsonRpcProvider(sample(providers[1]))
  return new Contract(CSS_ADDRESS, erc20Abi, signerOrProvider) as Erc20
}
