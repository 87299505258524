import { connectorLocalStorageKey, ConnectorNames, useWalletModal } from '@library/uikit'
import { useTranslation } from '@library/localization'
import useAuth from '../useAuth'

export default function useLogin() {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  const startLogin = async () => {
    if (window.navigator?.userAgent?.indexOf('Wallet3') !== -1) {
      await login(ConnectorNames.Injected)
      localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected)
    } else {
      onPresentConnectModal()
    }
  }

  return { startLogin }
}
