import React from "react";
import { infoUrl, termsUrl } from "../../widgets/Menu/config";
import { Flex } from "../Box";
import { IconButton } from "../Button";
import Email from "../Svg/Icons/Email";
import Github from "../Svg/Icons/Github";
import { Text } from "../Text";
import ClickText from "../Text/ClickText";
import { StyledFooter } from "./styles";
import { FooterProps } from "./types";
import { openNewTabByUrl } from "../../../../../common/utils";

const Footer: React.FC<FooterProps> = ({ items, isDark, currentLang, langs, setLang, cakePriceUsd, ...props }) => {
  return (
    <StyledFooter p={["20px 16px", null, "26px 40px 32px 40px"]} {...props} justifyContent="center">
      <Flex
        zIndex={10}
        flexDirection={["column-reverse", "column-reverse", "row", "row"]}
        width="100%"
        alignItems="center"
        justifyContent={["center", "center", "space-around", "space-around"]}
      >
        <Text fontSize="14px">©2022-2023 dagen lifeDAO. All rights reserved.</Text>
        <ClickText style={{ zIndex: 30 }} fontSize="14px" onClick={() => openNewTabByUrl(termsUrl)}>
          Terms of Service & Privacy Policy
        </ClickText>
        {currentLang === "ja" && (
          <ClickText style={{ zIndex: 30 }} fontSize="14px" onClick={() => openNewTabByUrl(infoUrl)}>
            特定商取引法に基づく表記
          </ClickText>
        )}
        <Flex py={1}>
          <IconButton as="a" href="https://github.com/dagen-life" external target="_blank" scale="md">
            <Github width="32px" />
          </IconButton>

          <IconButton as="a" href="mailto:team@dagen.life" external target="_blank" scale="md">
            <Email width="32px" />
          </IconButton>
        </Flex>
      </Flex>
    </StyledFooter>
  );
};

export default Footer;
