import { Alert, alertVariants, Flex, useMatchBreakpoints } from '@library/uikit'
import React, { useCallback, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { DRAWER_WIDTH } from 'views/Scenes/constants'
import { ToastProps, types } from './types'

const alertTypeMap = {
  [types.INFO]: alertVariants.INFO,
  [types.SUCCESS]: alertVariants.SUCCESS,
  [types.DANGER]: alertVariants.DANGER,
  [types.WARNING]: alertVariants.WARNING,
}

const StyledToast = styled.div<{ direction: string }>`
  right: ${({ direction }) => (direction === 'center' ? `50%` : '16px')};
  transform: ${({ direction }) => direction === 'center' && 'translate(50%, 0)'};
  position: fixed;
  max-width: calc(100% - 32px);
  transition: all 250ms ease-in;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 400px;
  }
`

const StyledCustomToast = styled(Flex)<{ isDesktop: boolean }>`
  margin-top: ${({ isDesktop }) => (isDesktop ? 'calc(-70vh + 55px)' : '-60vh')};
  left: ${({ isDesktop }) => (isDesktop ? `calc(${DRAWER_WIDTH}px + (100% - ${DRAWER_WIDTH}px) / 2)` : '50%')};
  position: fixed;
  transform: ${({ isDesktop }) => (isDesktop ? ` translate(-70%, 0)` : 'translate(-50%, 0)')};
  transition: all 250ms ease-in;
`

const Toast: React.FC<ToastProps> = ({ toast, onRemove, style, ttl, ...props }) => {
  const timer = useRef<number>()
  const ref = useRef(null)
  const removeHandler = useRef(onRemove)
  const { id, title, description, icon, type, customContent, isCustom } = toast
  const currentTTL = isCustom ? 2000 : ttl
  const { isDesktop } = useMatchBreakpoints()

  const handleRemove = useCallback(() => removeHandler.current(id), [id, removeHandler])

  const handleMouseEnter = () => {
    clearTimeout(timer.current)
  }

  const handleMouseLeave = () => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    handleRemove()
    timer.current = window.setTimeout(() => {
      handleRemove()
    }, currentTTL)
  }

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = window.setTimeout(() => {
      handleRemove()
    }, currentTTL)

    return () => {
      clearTimeout(timer.current)
    }
  }, [timer, currentTTL, handleRemove, isCustom])

  const onDismiss = () => {
    handleRemove()
  }

  return (
    <CSSTransition nodeRef={ref} timeout={50} style={style} {...props}>
      {isCustom ? (
        <StyledCustomToast
          ref={ref}
          isDesktop={isDesktop}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {customContent}
        </StyledCustomToast>
      ) : (
        <StyledToast
          direction={toast.direction}
          ref={ref}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Alert title={title} icon={icon} variant={alertTypeMap[type]} onClick={handleRemove}>
            {description}
          </Alert>
        </StyledToast>
      )}
    </CSSTransition>
  )
}

export default Toast
