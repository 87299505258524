import {
  Button,
  Flex,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  Text,
} from '@library/uikit'
import { useTranslation } from '@library/localization'
import styled from 'styled-components'
import NextImage from 'next/image'
import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import { enUS, ja, zhCN } from 'date-fns/locale'
import { AssetsInfo } from '@common/constans'
import { MaterialFull } from '@common/types'
import { FlexGap } from '../../components/Layout/Flex'

interface RewardProps extends InjectedModalProps {
  reward: { [key: string]: number }
  materialFull?: MaterialFull
  offlineTime: number
  taskRun?: () => void
}

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`

const RewardModal: React.FC<RewardProps> = ({ reward, materialFull, offlineTime, onDismiss }) => {
  const LANGUAGE2CODE = { 'zh-cn': zhCN, en: enUS, ja }
  const { t, currentLanguage } = useTranslation()
  const { code } = currentLanguage

  return (
    <ModalContainer minWidth="320px">
      <ModalBody p="24px" maxWidth="400px" width="100%" alignItems="center" style={{ gap: '12px' }}>
        <Flex style={{ flexShrink: 0 }}>
          <NextImage src="/logo.png" width={64} height={64} />
        </Flex>
        <Text>{t('Welcome back!')}</Text>
        <Text>
          {t('Left')} {formatDistanceToNow(offlineTime, { locale: LANGUAGE2CODE[code] })}
        </Text>
        <Text fontSize="14px" color="#4d6afa">
          ({t('Offline progress is capped at 20 hours')})
        </Text>

        {reward && (
          <>
            <Text mt={20}>{t('When you left')}:</Text>
            {Object.keys(reward)
              .filter((key) => reward[key] > 0)
              .map((itemIdKey, index) => {
                return (
                  <FlexGap gap="5px" key={`${itemIdKey}_${index}`}>
                    <Text>
                      {t('You gained %number% %symbol%', {
                        number: Math.floor(reward[itemIdKey]),
                        symbol: t(itemIdKey),
                      })}
                    </Text>
                    <NextImage src={AssetsInfo[itemIdKey].image} width={20} height={20} />
                  </FlexGap>
                )
              })}
          </>
        )}

        {materialFull.ep && <Text>{t('epFull')}</Text>}
        {materialFull.fiber && <Text>{t('fiberFull')}</Text>}
        {materialFull.chitin && <Text>{t('chitinFull')}</Text>}

        <Flex my={10} justifyContent="center" alignItems="center" width="100%">
          <Button onClick={() => onDismiss()} width="50%" scale="sm">
            <Text>{t('OK')}</Text>
          </Button>
        </Flex>
      </ModalBody>
    </ModalContainer>
  )
}

export default RewardModal
