import { Button, CloseIcon, Flex, InjectedModalProps, ModalTaskBody, ModalTaskContainer, Text } from '@library/uikit'
import NextImage from 'next/image'
import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from '@library/localization'
import { BattleOfflineRecord, BattleOfflineRecordItem } from '@common/types'
import NFTMedia from '../Gens/Market/components/NFTMedia'
import { FlexGap } from '../../components/Layout/Flex'
import { TaskTitle } from './TaskModals'
import { BattleRewardItem } from './BattleRewardModals'

export interface UIBattleProps extends InjectedModalProps {
  callback?: () => void
}

const BattleContent = styled(Flex)`
  width: 100%;
  max-height: 460px;
  min-height: 250px;
  margin-bottom: 10px;

  overflow-y: scroll;
  flex-direction: column;

  .icon {
    border: 1px solid #ffffff;
    border-radius: 50%;
  }
`

const BattleButton = styled(Button)<{ color: string }>`
  padding: unset;
  width: 64px;
  height: 32px;
  font-size: 14px;
  background: #b12020;
  border-radius: 100px;
  align-items: center;
  text-align: center;
`

export const BattleRecordItem: React.FC<BattleOfflineRecordItem> = (record) => {
  const router = useRouter()
  const { t } = useTranslation()

  const onStartBattle = async () => {
    await router.push(`/profile/${record.attacker}`)
    record.onDismiss()
  }

  return (
    <FlexGap gap="16px" justifyContent="space-evenly" alignItems="center">
      <NFTMedia
        className="icon"
        nft={{ name: record.attacker, image: { original: record.icon ?? '/logo.png' } }}
        width={32}
        height={32}
      />
      <Flex flexDirection="column">
        <Text fontSize="14px">{record.attackNickname ?? t('noName')}</Text>
        <Text fontSize="11px" color="#AAAAAA">
          {t('raid')} <span style={{ color: '#EBFF00' }}>{record.attackNum}</span> {t('times')}, {t('succeed')}{' '}
          <span style={{ color: '#FF9F9F' }}>{record.attackSuccess}</span> {t('times')}
        </Text>
      </Flex>

      <BattleButton onClick={() => onStartBattle()}>{t('revenge')}</BattleButton>
    </FlexGap>
  )
}

export const BattleRecordModals: React.FC<InjectedModalProps & BattleOfflineRecord> = ({
  stealNum,
  reward,
  loss,
  recordItems,
  onDismiss,
}) => {
  const { t } = useTranslation()

  return (
    <ModalTaskContainer color="#4553EC">
      <TaskTitle color="#4553EC">
        <NextImage src="/images/game/battle/offline.svg" width={20} height={24} />
        <Text fontSize="20px" fontWeight="400" textAlign="center">
          {t('offline battle record')}
        </Text>

        <Button style={{ position: 'absolute', right: 0 }} onClick={onDismiss} variant="text">
          <CloseIcon />
        </Button>
      </TaskTitle>
      <ModalTaskBody flexDirection="column">
        <BattleContent>
          <Text fontSize="14px" fontWeight="400" py={10} px={38} textAlign="center">
            {t('offline gap')}
          </Text>

          <FlexGap mx={10} gap="16px" flexDirection="column">
            {reward.length > 0 && (
              <BattleRewardItem title={t('reward record')} itemList={reward} titleColor="#FFD600" />
            )}

            {loss.length > 0 && (
              <BattleRewardItem
                title={`${t('loss %num% record', { num: Math.floor(stealNum) })}`}
                itemList={loss}
                titleColor="#FF6464"
              />
            )}
          </FlexGap>

          <FlexGap gap="14px" flexDirection="column" width="100%" px="8px" mt={20} maxHeight={200}>
            {recordItems.map((item, index) => {
              return <BattleRecordItem {...item} onDismiss={onDismiss} key={`battle_offline_item_${index}`} />
            })}
          </FlexGap>
        </BattleContent>
      </ModalTaskBody>
    </ModalTaskContainer>
  )
}
