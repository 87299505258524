import styled, { DefaultTheme } from "styled-components";
import { layout, space, typography } from "styled-system";
import getThemeValue from "../../util/getThemeValue";
import { TextProps } from "./types";

interface ThemedProps extends TextProps {
  theme: DefaultTheme;
}

const getColor = ({ color, theme }: ThemedProps) => {
  return getThemeValue(`colors.${color}`, color)(theme);
};

const getFontSize = ({ fontSize, small }: TextProps) => {
  return small ? "14px" : fontSize || "16px";
};

const Text = styled.div<TextProps>`
  //font-family: Poppins, sans-serif;
  color: ${getColor};
  font-size: ${getFontSize};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  line-height: 1.5;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}

  ${space}
  ${typography}
  ${layout}
`;

Text.defaultProps = {
  color: "text",
  small: false,
  ellipsis: false,
};

export const TextItem = styled(Text)<{ fontSize?: string; isMobile?: boolean; fontWeight?: number }>`
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight || "bold"};
  max-width: ${({ isMobile }) => (isMobile ? "80%" : "680px")};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  color: white;
`;

export const NotFoundItem = styled(Text)<{ fontSize?: string; isMobile?: boolean; fontWeight?: number }>`
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: ${({ fontSize }) => fontSize || "24px"};
  opacity: 0.6;
  margin-top: 36px;
  max-width: ${({ isMobile }) => (isMobile ? "80%" : "680px")};
  color: white;
`;

export const TextTitle = styled(Text)<{ fontSize?: string; isMobile?: boolean; textAlign?: string }>`
  text-align: ${({ textAlign }) => textAlign || "center"};
  font-weight: 600;
  max-width: ${({ isMobile }) => (isMobile ? "80%" : "680px")};
  font-size: ${({ fontSize }) => fontSize || "48px"};
  color: white;
`;

export const TitleNameText = styled(Text)<{ marginLeft?: string }>`
  font-size: 16px;
  margin-left: ${({ marginLeft }) => marginLeft || "16px"};
  font-weight: 400;
  max-width: 680px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 18px;
  }
`;

export default Text;
