/* eslint-disable no-param-reassign */
import { ChangeItem, EnergySun, IMiniPlayer, ISystemInfo, Scenes } from '@common/types'
import { createReducer } from '@reduxjs/toolkit'
import {
  updateAbsorbingChangeItems,
  updateEnergySuns,
  updateMiniPlayer,
  updateOthersChangeItems,
  updatePreGameConnect,
  updateScenes,
  updateSystemInfo,
  updateSunSpeed
} from './actions'

export interface PlayerState {
  miniPlayer: IMiniPlayer
  systemInfo: ISystemInfo
  preGameConnect: boolean
  energySuns: EnergySun[]
  sunSpeed: number
  absorbingChangeItems: ChangeItem[]
  othersChangeItems: ChangeItem[]
  scenes: Scenes[]
}

export const initialState: PlayerState = {
  miniPlayer: null,
  systemInfo: { onlineCount: 0, sceneEvents: [] },
  preGameConnect: false,
  energySuns: [],
  sunSpeed: 0.5,
  absorbingChangeItems: [],
  othersChangeItems: [],
  scenes: [Scenes.Home],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateMiniPlayer, (state, action) => {
      state.miniPlayer = action.payload
    })
    .addCase(updateSystemInfo, (state, action) => {
      state.systemInfo = action.payload
    })
    .addCase(updatePreGameConnect, (state, action) => {
      state.preGameConnect = action.payload
    })
    .addCase(updateEnergySuns, (state, action) => {
      state.energySuns = action.payload
    })
    .addCase(updateSunSpeed, (state, action) => {
      state.sunSpeed = action.payload
    })
    .addCase(updateAbsorbingChangeItems, (state, action) => {
      state.absorbingChangeItems = action.payload
    })
    .addCase(updateOthersChangeItems, (state, action) => {
      state.othersChangeItems = action.payload
    })
    .addCase(updateScenes, (state, action) => {
      state.scenes = action.payload
    }),
)
