import { ChangeItem } from '@common/types'
import { useTranslation } from '@library/localization'
import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { AttackedModal } from 'views/Scenes/components/AttackedModal'

export default function useAttackedManager() {
  const { onPresent, onDismiss } = useContext(MContext)
  const { t } = useTranslation()
  const router = useRouter()

  // 玩家接受任务，引导任务自动完成
  const showAttacked = (title: string, content: ChangeItem) => {
    if (router.pathname === '/scenes') {
      onPresent(
        <AttackedModal
          type="fail"
          title={title}
          changeItem={content}
          buttonDesc={t('OK')}
          callback={async () => {
            onDismiss()
          }}
        />,
        title,
      )
    }
  }

  return { showAttacked }
}
