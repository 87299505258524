import { ChangeItem, EnergySun, IMiniPlayer, ISystemInfo, Scenes } from '@common/types'
import { createAction } from '@reduxjs/toolkit'

export const updateMiniPlayer = createAction<IMiniPlayer>('user/updateMiniPlayer')

export const updateAbsorbingChangeItems = createAction<ChangeItem[]>('user/updateAbsorbingChangeItems')

export const updateEnergySuns = createAction<EnergySun[]>('user/updateEnergySuns')

export const updateSunSpeed = createAction<number>('user/updateSunSpeed')

export const updateCollectingChangeItems = createAction<ChangeItem[]>('user/updateCollectingChangeItems')

export const updateOthersChangeItems = createAction<ChangeItem[]>('user/updateOthersChangeItems')

export const updateSystemInfo = createAction<ISystemInfo>('user/updateSystemInfo')

export const updatePreGameConnect = createAction<boolean>('user/updatePreGameConnect')

export const updateScenes = createAction<Scenes[]>('user/updateScenes')
