// eslint-disable-next-line lodash/import-scope
import { cloneDeep } from 'lodash'
import { useMiniPlayer } from '../../state/player/hooks'
import { useStore } from '../../state'
import { PositionItem } from '../../../server/model'

const useEventMsg1014SyncFinallyItem = () => {
  const [_, setMiniPlayer] = useMiniPlayer()
  const { getState } = useStore()
  return async (event) => {
    const positionItems: PositionItem[] = JSON.parse(event.data)

    console.info(`[positionItems]`, positionItems)
    const currentMiniPlayer = cloneDeep(getState().player.miniPlayer)
    for (const positionItem of positionItems) {
      const currentMiniPlayerElement = currentMiniPlayer[positionItem.position]
      if (currentMiniPlayerElement) {
        currentMiniPlayerElement[positionItem.item.itemId] = positionItem.item.itemNum
      }
    }
    setMiniPlayer({ ...getState().player.miniPlayer, ...currentMiniPlayer })
  }
}

export default useEventMsg1014SyncFinallyItem
