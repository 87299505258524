import { Profile } from 'state/types'

export interface GetProfileResponse {
  profile?: Profile
}

export const getProfile = async (dagenApi, walletAddress: string): Promise<GetProfileResponse> => {
  try {
    const res = await dagenApi.get(`profile`, { params: { walletAddress } })
    if (res.data.success) {
      const { profile } = res.data.result
      profile.userId = walletAddress
      return { profile }
    }
    return null
  } catch (e) {
    console.error(e)
    return null
  }
}
