import { FetchStatus } from 'config/constants/types'
import { useSelector } from 'react-redux'
import { isAddress } from 'utils'
import { State } from '../types'
import { NftActivityFilter, NftAttribute, NftFilter, NftToken } from './types'

const DEFAULT_NFT_ORDERING = { field: 'current_ask_price', direction: 'asc' as 'asc' | 'desc' }
const DEFAULT_NFT_ACTIVITY_FILTER = { typeFilters: [], collectionFilters: [] }
const EMPTY_OBJECT = {}

export const useLoadingState = () => {
  return useSelector((state: State) => state.nftMarket.data.loadingState)
}

export const useNftsFromCollection = (collectionAddress: string) => {
  const checksummedCollectionAddress = isAddress(collectionAddress) || ''
  const nfts: NftToken[] = useSelector((state: State) => state.nftMarket.data.nfts[checksummedCollectionAddress])
  return nfts
}

export const useGetNftFilters = (collectionAddress: string): Readonly<Record<string, NftAttribute>> => {
  const collectionFilter: NftFilter = useSelector((state: State) => state.nftMarket.data.filters[collectionAddress])
  return collectionFilter ? collectionFilter.activeFilters : EMPTY_OBJECT
}

export const useGetNftFilterLoadingState = (collectionAddress: string) => {
  const collectionFilter: NftFilter = useSelector((state: State) => state.nftMarket.data.filters[collectionAddress])
  return collectionFilter ? collectionFilter.loadingState : FetchStatus.Idle
}

export const useGetNftOrdering = (collectionAddress: string) => {
  const collectionFilter: NftFilter = useSelector((state: State) => state.nftMarket.data.filters[collectionAddress])
  return collectionFilter ? collectionFilter.ordering : DEFAULT_NFT_ORDERING
}

export const useGetNftShowOnlyOnSale = (collectionAddress: string) => {
  const collectionFilter: NftFilter = useSelector((state: State) => state.nftMarket.data.filters[collectionAddress])
  return collectionFilter ? collectionFilter.showOnlyOnSale : false
}

export const useTryVideoNftMedia = () => {
  const tryVideoNftMedia = useSelector((state: State) => state.nftMarket.data.tryVideoNftMedia)
  return tryVideoNftMedia ?? true
}

export const useGetNftActivityFilters = (collectionAddress: string) => {
  const collectionFilter: NftActivityFilter = useSelector(
    (state: State) => state.nftMarket.data.activityFilters[collectionAddress],
  )
  return collectionFilter || DEFAULT_NFT_ACTIVITY_FILTER
}
