import React from "react";
import styled from "styled-components";
import { Image } from "../../../../components/Image";
import { RefreshIcon, WarningIcon } from "../../../../components/Svg";
import Wallet from "../../../../components/Svg/Icons/Wallet";
import { useMatchBreakpoints } from "../../../../contexts";
import { Colors } from "../../../../theme/types";
import { Variant, variants } from "./types";

const MenuIconWrapper = styled.div<{ borderColor: keyof Colors }>`
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  border-color: ${({ theme }) => theme.colors.normal};
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  z-index: 102;

  @media screen and (max-width: 576px) {
    .wallet {
      align-items: center;
      width: 28px;
    }
  }
`;

const ProfileIcon = styled(Image)<{ height: number; width: number }>`
  align-items: center;
  background-color: #0c141e;
  border-color: ${({ theme }) => theme.colors.normal};
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  z-index: 102;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};

  & > img {
    border-radius: 50%;
  }
`;

export const NoProfileMenuIcon: React.FC = () => (
  <MenuIconWrapper borderColor="normal">
    <Wallet color="normal" width="32px" className="wallet" />
  </MenuIconWrapper>
);

export const PendingMenuIcon: React.FC = () => (
  <MenuIconWrapper borderColor="secondary">
    <RefreshIcon color="secondary" width="32px" spin />
  </MenuIconWrapper>
);

export const WarningMenuIcon: React.FC = () => (
  <MenuIconWrapper borderColor="warning">
    <WarningIcon color="warning" width="32px" />
  </MenuIconWrapper>
);

export const DangerMenuIcon: React.FC = () => (
  <MenuIconWrapper borderColor="failure">
    <WarningIcon color="failure" width="32px" />
  </MenuIconWrapper>
);

const MenuIcon: React.FC<{ avatarSrc?: string; variant: Variant }> = ({ avatarSrc, variant }) => {
  const { isMobile } = useMatchBreakpoints();
  if (variant === variants.DANGER) {
    return <DangerMenuIcon />;
  }

  if (variant === variants.WARNING) {
    return <WarningMenuIcon />;
  }

  if (variant === variants.PENDING) {
    return <PendingMenuIcon />;
  }

  if (!avatarSrc) {
    return <NoProfileMenuIcon />;
  }

  return <ProfileIcon src={avatarSrc} height={isMobile ? 32 : 40} width={isMobile ? 32 : 40} />;
};

export default MenuIcon;
