import { StaticJsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { CHAIN_ID } from 'config/constants/networks'
import sample from 'lodash/sample'
import providers from '@common/providers.json'
import { useEffect, useRef, useState } from 'react'

const simpleRpcProvider = new StaticJsonRpcProvider(sample(providers[parseInt(CHAIN_ID, 10)]))
/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const { library, chainId, ...web3React } = useWeb3React()
  const refEth = useRef(library)
  const [provider, setProvider] = useState(library || simpleRpcProvider)

  useEffect(() => {
    if (library !== refEth.current) {
      setProvider(library || new StaticJsonRpcProvider(sample(providers[chainId ?? parseInt(CHAIN_ID, 10)])))
      refEth.current = library
    }
  }, [chainId, library])

  return { library: provider, chainId: chainId ?? parseInt(CHAIN_ID, 10), ...web3React }
}

export default useActiveWeb3React
