import { useMiniPlayer } from 'state/player/hooks'
import { useStore } from '../../state'

const useEventMsg1005Task = () => {
  const [_, setMiniPlayer] = useMiniPlayer()
  const { getState } = useStore()

  return (event) => {
    const eventData: { guideIndex: number } = JSON.parse(event.data)
    setMiniPlayer({
      ...getState().player.miniPlayer,
      ...{ profile: { ...getState().player.miniPlayer.profile, ...eventData } },
    })
  }
}

export default useEventMsg1005Task
