import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import { useContext } from 'react'
import LogoutModal from '../../views/Game/LogoutModal'

export default function useLogoutManager() {
  const {
    isOpen,
    nodeIds: nodeId,
    modalNodes: modalNode,
    onPresent,
    onDismiss,
    setCloseOnOverlayClick,
  } = useContext(MContext)

  const showLogout = () => {
    setCloseOnOverlayClick(false)
    onPresent(<LogoutModal />, 'LogoutModal')
  }

  return { showLogout }
}
