import { IPreAndCurrent } from '@common/types'
// eslint-disable-next-line lodash/import-scope
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useMiniPlayer } from '../../state/player/hooks'

const useAssertPreAndCurrent = () => {
  const [miniPlayer, _] = useMiniPlayer()
  const initPreAndCurrent: IPreAndCurrent = { currentValue: 0, preValue: 0 }
  const preAssertPreAndCurrent = {
    friends: initPreAndCurrent,
    epRank: initPreAndCurrent,
    energy: initPreAndCurrent,
    gCent: initPreAndCurrent,
    gensCount: initPreAndCurrent,
  }

  const [assertPreAndCurrent, setAssertPreAndCurrent] = useState<{ [key: string]: IPreAndCurrent }>(
    preAssertPreAndCurrent,
  )

  function checkHasUpdate(profile): boolean {
    for (const assertKey of Object.keys(assertPreAndCurrent)) {
      const preValue = profile[assertKey]
      if (assertPreAndCurrent[assertKey] !== preValue) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    const profile = miniPlayer?.profile
    const friends = miniPlayer?.friends?.length ?? 0
    if (!profile) return
    // 检查一边是是否有更新
    if (!checkHasUpdate(profile)) return
    const currentAssertList = cloneDeep(preAssertPreAndCurrent)
    for (const assertKey of Object.keys(preAssertPreAndCurrent)) {
      let preValue = profile[assertKey] ?? 0
      let currentValue = profile[assertKey] ?? 0

      if (assertKey === 'friends') {
        preValue = friends
        currentValue = friends
      }

      if (assertPreAndCurrent) preValue = assertPreAndCurrent[assertKey]?.currentValue ?? preValue
      currentAssertList[assertKey] = { preValue, currentValue }
    }
    setAssertPreAndCurrent(currentAssertList)
  }, [miniPlayer?.profile, miniPlayer?.friends])

  return assertPreAndCurrent
}

export default useAssertPreAndCurrent
