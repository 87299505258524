import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@library/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`

  html[local-lang="en"] {
    --font: "Poppins", sans-serif;
  }

  html[local-lang="zh-cn"] {
    --font: "Noto Sans SC", sans-serif;
  }


  html[local-lang="ja"] {
    --font: "M PLUS 2", sans-serif;
  }


  body {

    * {
      font-family: var(--font);
    }

    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
