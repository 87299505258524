import React from "react";
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import { Box } from "../../components/Box";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { IconButton } from "../../components/Button";
import { ModalProps } from "./types";

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  display: flex;
  width: 100%;
  padding: 5px 10% 0 10%;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 10px 0px 10px 20px;
  }
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)<{ isBodyBackground?: boolean; bodyMargin?: string }>`
  max-height: 90vh;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  border-radius: 0;
  background-color: unset;
  margin: 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-radius: 23px;
    background-color: ${({ isBodyBackground }) => (isBodyBackground ? "#3b3659" : "unset")};
    margin: ${({ bodyMargin }) => bodyMargin || null};
  }

  div li {
    color: white;
  }
`;

const BackIconButton = styled(IconButton)`
  padding: 0;
  width: 10px;
  margin-right: 14px;
  margin-left: -8%;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: ${({ scale }) => (scale === "sm" ? "32px" : "48px")};
    margin-right: 4px;
    margin-left: -5%;
  }

  svg {
    opacity: 0.6;
  }
`;

export const ModalCloseButton: React.FC<{ onDismiss?: ModalProps["onDismiss"] }> = ({ onDismiss }) => {
  return (
    <IconButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon color="white" />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps["onBack"] }> = ({ onBack }) => {
  return (
    <BackIconButton variant="text" onClick={onBack} area-label="go back">
      <ArrowBackIcon color="primary" />
    </BackIconButton>
  );
};

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  overflow: hidden;
  background: #282c39;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  z-index: ${({ theme }) => theme.zIndices.modal};
  min-width: 95%;
  max-width: 477px;

  width: 95%;
  border-radius: 20px;
  border: 3px solid #535f89;
  position: relative;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    min-width: 400px;
    border-radius: 32px;
    border: 5px solid #535f89;
  }
`;

export const ModalTaskBody = styled(Flex)<{ isBodyBackground?: boolean; bodyMargin?: string }>`
  flex-direction: column;
  align-items: center;
`;

export const ModalTaskContainer = styled(Box)<{ color?: string }>`
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: relative;
  width: 280px;
  min-height: 400px;
  background: #272727;
  border: 1px solid ${({ color }) => color ?? "#4553EC"};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 14px;

  img {
    -webkit-user-drag: none;
  }
`;

export const ModalLevelUpContainer = styled(Flex)<{ color?: string }>`
  z-index: ${({ theme }) => theme.zIndices.modal};
  width: 100%;
  min-height: 100%;
  align-items: center;
  img {
    -webkit-user-drag: none;
  }
`;
