import { useMiniPlayer } from 'state/player/hooks'
import { useStore } from '../../state'

const useEventMsg1010SyncTime = () => {
  const { getState } = useStore()
  const [_, setMiniPlayer] = useMiniPlayer()

  return (event) => {
    const eventData: { time: string } = JSON.parse(event.data)
    setMiniPlayer({ ...getState().player.miniPlayer, ...eventData })
  }
}

export default useEventMsg1010SyncTime
