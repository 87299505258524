import { connectorLocalStorageKey, ConnectorNames } from '@library/uikit'
import useAuth from 'hooks/useAuth'
import { getSession } from 'next-auth/react'
import { useEffect } from 'react'
import { injected } from 'utils/web3React'

const _binanceChainListener = async () =>
  new Promise<void>((resolve) =>
    Object.defineProperty(window, 'BinanceChain', {
      get() {
        return this.bsc
      },
      set(bsc) {
        this.bsc = bsc

        resolve()
      },
    }),
  )

const safeGetLocalStorageItem = () => {
  try {
    return (
      typeof window?.localStorage?.getItem === 'function' &&
      (window?.localStorage?.getItem(connectorLocalStorageKey) as ConnectorNames)
    )
  } catch (err: any) {
    // Ignore Local Storage Browser error
    // - NS_ERROR_FILE_CORRUPTED
    // - QuotaExceededError
    console.error(`Local Storage error: ${err?.message}`)

    return null
  }
}

const useEagerConnect = () => {
  const { login } = useAuth()

  useEffect(() => {
    const tryLogin = (c: ConnectorNames) => {
      setTimeout(() => login(c, true), 500)
    }

    const eagerConnect = async () => {
      const session = await getSession()
      if (session) return

      const connectorId = safeGetLocalStorageItem()

      if (connectorId) {
        if (connectorId === ConnectorNames.Injected) {
          // somehow injected login not working well on development mode
          injected.isAuthorized().then(() => tryLogin(connectorId))
        } else {
          tryLogin(connectorId)
        }
      }
    }

    eagerConnect()
  }, [login])
}

export default useEagerConnect
