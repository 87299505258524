import { FC } from "react";
import { SvgProps } from "../../components/Svg/types";

export enum ConnectorNames {
  Injected = "injected",
  EMAIL = "EMAIL",
  Github = "Github",
  Twitter = "Twitter",
  Google = "Google",
  Wallet3 = "Wallet3",
  WalletConnect = "walletconnect",
  Blocto = "blocto",
  WalletLink = "walletlink",
}

export type Login = (connectorId: ConnectorNames, eager?: boolean, email?: string) => Promise<any>;

export interface Config {
  title: string;
  icon: FC<SvgProps>;
  connectorId: ConnectorNames;
  priority: number;
}
