import { Button, Flex, Text } from '@library/uikit'
import styled from 'styled-components'

export const SlotIcon = styled(Flex)<{ selected?: boolean }>`
  margin: auto;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #141e29;

  border: ${({ selected }) => (selected ? '3px solid #ebff00' : '2px solid #cccccc')};
  box-shadow: ${({ selected }) => (selected ? ' 0px 0px 16px 2px #ebff00' : 'unset')};
  border-radius: 12px;
`

export const FusionButton = styled(Button)`
  height: 28px;
  min-width: 116px;
  font-weight: 400;
  padding: 4px;

  .txt {
    font-size: 12px;
    color: white;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    .txt {
      font-size: 14px;
      color: white;
    }

    min-width: 100px;
    height: 36px;
  }
`

export const Desc = styled(Text)`
  color: white;
  align-self: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
`
