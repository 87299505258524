import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../theme";
import Button from "../Button/Button";
import { Scale } from "../Button/types";
import Dropdown from "../Dropdown/Dropdown";
import { Position } from "../Dropdown/types";
import LanguageIcon from "../Svg/Icons/Language";
import Text from "../Text/Text";
import MenuButton from "./MenuButton";
import { Language } from "./types";

interface Props {
  currentLang: string;
  langs: Language[];
  setLang: (lang: Language) => void;
  color: keyof Colors;
  dropdownPosition?: Position;
  buttonScale?: Scale;
  hideLanguage?: boolean;
  width?: string;
}

const LangContainer = styled.div<{ isMobile?: boolean }>`
  .icon {
    width: 24px;
  }
`;

const LangSelector: React.FC<Props> = ({
  currentLang,
  langs,
  color,
  setLang,
  dropdownPosition = "bottom",
  buttonScale = "sm",
  hideLanguage = false,
}) => {
  const [selected, setSelected] = useState(false);

  return (
    <LangContainer onMouseLeave={() => setSelected(false)}>
      <Dropdown
        selected={selected}
        position={dropdownPosition}
        target={
          <Button
            onClick={() => setSelected(!selected)}
            scale={buttonScale}
            variant="text"
            startIcon={<LanguageIcon color={color} className="icon" />}
          >
            {!hideLanguage && <Text color={color}>{currentLang?.toUpperCase()}</Text>}
          </Button>
        }
      >
        {selected &&
          langs.map((lang) => (
            <MenuButton
              key={lang.locale}
              fullWidth
              onClick={() => {
                setSelected(true);
                setTimeout(() => {
                  setSelected(false);
                }, 100);
                setLang(lang);
              }}
              // Safari fix
              style={{ minHeight: "32px", height: "auto" }}
            >
              {lang.language}
            </MenuButton>
          ))}
      </Dropdown>
    </LangContainer>
  );
};

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);
