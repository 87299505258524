export interface NetworkConfigs {
  [key: string]: {
    chainId: number
    chainName?: string
    currencyName?: string
    currencySymbol?: string
    rpcUrl?: string
    blockExplorerUrl?: string
    marketItemSoldTable?: string
    itemImagesTable?: string
    wrapped?: string
  }
}

export const networkConfigs: NetworkConfigs =
  process.env.NODE_ENV === 'production'
    ? {
        1: {
          chainId: 1,
          chainName: 'Etheruem',
          currencyName: 'ETH',
          currencySymbol: 'ETH',
          blockExplorerUrl: 'https://etherscan.io',
          rpcUrl: 'https://mainnet.infura.io/v3',
          wrapped: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        },
      }
    : {
        5: {
          chainId: 5,
          chainName: 'goerli',
          currencyName: 'ETH',
          currencySymbol: 'ETH',
          rpcUrl: 'https://goerli.infura.io/v3/',
          blockExplorerUrl: 'https://goerli.etherscan.io',
        },
        // 43114: {
        //   chainId: 43114,
        //   chainName: 'Avalanche Mainnet',
        //   currencyName: 'AVAX',
        //   currencySymbol: 'AVAX',
        //   rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
        //   blockExplorerUrl: 'https://cchain.explorer.avax.network',
        // },
        // 56: {
        //   chainId: 56,
        //   chainName: 'Smart Chain',
        //   currencyName: 'BNB',
        //   currencySymbol: 'BNB',
        //   rpcUrl: 'https://bsc-dataseed.binance.org',
        //   blockExplorerUrl: 'https://bscscan.com',
        //   wrapped: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        // },
        // 97: {
        //   chainId: 97,
        //   chainName: 'Smart Chain - Testnet',
        //   currencyName: 'BNB',
        //   currencySymbol: 'BNB',
        //   rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
        //   blockExplorerUrl: 'https://testnet.bscscan.com',
        // },
        // 137: {
        //   chainId: 137,
        //   chainName: 'Polygon Mainnet',
        //   currencyName: 'MATIC',
        //   currencySymbol: 'MATIC',
        //   rpcUrl: 'https://rpc-mainnet.maticvigil.com',
        //   blockExplorerUrl: 'https://explorer-mainnet.maticvigil.com',
        //   wrapped: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        // },
        // 80001: {
        //   chainId: 80001,
        //   chainName: 'mumbai',
        //   currencyName: 'MATIC',
        //   currencySymbol: 'MATIC',
        //   rpcUrl: 'https://rpc-mumbai.matic.today',
        //   blockExplorerUrl: 'https://mumbai.polygonscan.com',
        //   marketItemSoldTable: 'CreatedMarketItemsMumbai',
        //   itemImagesTable: 'ItemImagesMumbai',
        // },
        // 42161: {
        //   chainId: 42161,
        //   chainName: 'arbitrum',
        //   currencyName: 'ETH',
        //   currencySymbol: 'ETH',
        //   rpcUrl: 'https://speedy-nodes-nyc.moralis.io/1583a47e62f1cddfe687736f/arbitrum/mainnet',
        //   blockExplorerUrl: 'https://arbiscan.io',
        //   marketItemSoldTable: 'CreatedMarketItemsAT',
        //   itemImagesTable: 'ItemImagesAT',
        // },
        // 421611: {
        //   chainId: 421611,
        //   chainName: 'arbitrumTestnet',
        //   currencyName: 'ETH',
        //   currencySymbol: 'ETH',
        //   rpcUrl: 'https://speedy-nodes-nyc.moralis.io/1583a47e62f1cddfe687736f/arbitrum/testnet',
        //   blockExplorerUrl: 'https://testnet.arbiscan.io',
        //   marketItemSoldTable: 'CreatedMarketItemsAT',
        //   itemImagesTable: 'ItemImagesAT',
        // },
      }

export const getNativeByChain = (chain) => networkConfigs[chain]?.currencySymbol || ''

export const getChainById = (chainId) => networkConfigs[chainId]

export const getExplorer = (chain) => networkConfigs[chain]?.blockExplorerUrl

export const getWrappedNative = (chain) => networkConfigs[chain]?.wrapped || null
