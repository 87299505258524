import { DAGEN_DOMAIN, taskTypeMap } from '@common/constans'
import { useTranslation } from '@library/localization'
import { Button, CloseIcon, Flex, InjectedModalProps, ModalTaskBody, ModalTaskContainer, Text } from '@library/uikit'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import noop from 'lodash/noop'
import NextImage from 'next/image'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import useWallet from 'hooks/useWallet'
import { shareTwitterUrl } from 'utils'
import useCommunities from 'hooks/useCommunities'
import { FlexGap } from '../../components/Layout/Flex'
import { RewardItem } from '../../../server/model'
import { NextLinkFromReactRouter } from '../../components/NextLink'

export interface UIBattle {
  type: 'victory' | 'loss' | 'victoryed' | 'lossed'
  title: { key: string; params: { name: string } }
  content: ReactNode
  buttonDesc?: string
  contentBottom?: boolean
}

export interface UIBattleProps extends InjectedModalProps, UIBattle {
  callback?: () => void
}

const TaskTitle = styled(Flex)<{ color: string }>`
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color ?? '#4553EC'};
  border-radius: 12px 12px 0 0;
  height: 52px;
  gap: 20px;
`

const TaskContentDesc = styled(Flex)<{ contentBottom?: boolean }>`
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: ${({ contentBottom }) => (contentBottom ? '150px' : '250px')};
  overflow: hidden;
  position: relative;
  justify-content: ${({ contentBottom }) => (contentBottom ? 'end' : 'start')};
`

const TaskButton = styled(ClickFlex)<{ color: string }>`
  min-width: 140px;
  max-width: 90%;
  padding: 5px;
  height: 40px;
  margin-bottom: 20px;
  background-color: ${({ color }) => color ?? '#4553EC'};
  border-radius: 26px;
  align-items: center;
  justify-content: center;
`

interface BattleRewardItem {
  titleColor: string
  title: string
  itemList: RewardItem[]
}

export const BattleRewardItem: React.FC<BattleRewardItem> = ({ title, titleColor, itemList }) => {
  const { t } = useTranslation()
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Text color={titleColor} fontSize="14px" textAlign="center" px="10px">
        {title}
      </Text>
      <FlexGap gap="5px" flexWrap="wrap" alignItems="center" justifyContent="center">
        {itemList.map((item, index) => {
          return (
            <FlexGap
              gap="5px"
              minWidth="60px"
              key={`battle_reward_item_${item.itemId}_${index}`}
              justifyContent="center"
            >
              <Text color="white" fontSize="14px">
                {Math.floor(item.itemNum)}x
              </Text>

              {item.itemId && (
                <Text color="white" fontSize="14px">
                  {t(item.itemId)}
                </Text>
              )}
            </FlexGap>
          )
        })}
      </FlexGap>
    </Flex>
  )
}

interface AttackerDialogContentProps {
  stealItemList: RewardItem[]
  rewardItemList: RewardItem[]
  lossItemList: RewardItem[]
}

export const AttackerDialogContent: React.FC<AttackerDialogContentProps> = ({
  stealItemList,
  rewardItemList,
  lossItemList,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {stealItemList.length > 0 && (
        <BattleRewardItem title={t('steal_res')} itemList={stealItemList} titleColor="#FFD600" />
      )}
      {rewardItemList.length > 0 && (
        <BattleRewardItem title={t('reward_exp')} itemList={rewardItemList} titleColor="#FFD600" />
      )}
      {lossItemList.length > 0 && (
        <BattleRewardItem title={t('steal_cost')} itemList={lossItemList} titleColor="#FF6464" />
      )}
    </>
  )
}

interface AffecterDialogContentProps {
  stealItemList: RewardItem[]
  hpNum?: number
}

export const AffecterLossedContent: React.FC<AffecterDialogContentProps> = ({ stealItemList, hpNum = 0 }) => {
  const { t } = useTranslation()

  return (
    <>
      {stealItemList.length > 0 && (
        <BattleRewardItem title={t('steal_res')} itemList={stealItemList} titleColor="#FF6464" />
      )}
      {hpNum > 0 && (
        <BattleRewardItem title={t('loss_hp')} itemList={[{ itemId: '', itemNum: hpNum }]} titleColor="#FF6464" />
      )}
      <Text textAlign="center" fontSize="14px" fontWeight="400">
        {t('limit_hp_warning')}
      </Text>
    </>
  )
}

interface AffecterWinedContentProps {
  desc: string
  callback: () => void
}

export const AffecterWinedContent: React.FC<AffecterWinedContentProps> = ({ desc, callback }) => {
  const { t } = useTranslation()
  return (
    <TaskButton color="#B12020" onClick={() => callback()}>
      <Text fontSize="14px" bold textAlign="center">
        {t(desc)}
      </Text>
    </TaskButton>
  )
}

export const BattleRewardModals: React.FC<UIBattleProps> = ({
  type,
  title,
  content,
  buttonDesc,
  contentBottom,
  callback = noop,
  onDismiss,
}) => {
  const { t } = useTranslation()
  const { walletAddress } = useWallet()
  const shareContent = `${DAGEN_DOMAIN}/profile/${walletAddress}`
  const { communities } = useCommunities(walletAddress)
  const shareTextMap = {
    victory: t('dagen game is so much fun, I just 【successfully】 sneak attacked my mate ftw'),
    loss: t('Sneak attack missed T^T... Help me beat back!'),
    victoryed: t('dagen game is so much fun, I just 【successfully】 sneak attacked my mate ftw'),
    lossed: t('limit_hp_warning'),
  }

  const productTwitterUrl = () => {
    const shareText = shareTextMap[type]
    console.info(`[战斗结算下方按钮显示内容]`, type, shareText)

    return shareTwitterUrl(shareText, communities, shareContent)
  }

  return (
    <ModalTaskContainer color={taskTypeMap[type].color}>
      <TaskTitle color={taskTypeMap[type].color}>
        <NextImage src={`/images/game/battle/${taskTypeMap[type].icon}`} width={22} height={22} />
        <Text fontSize="20px" fontWeight="400" textAlign="center">
          {t(taskTypeMap[type].desc)}
        </Text>

        <Button style={{ position: 'absolute', right: 0 }} onClick={onDismiss} variant="text">
          <CloseIcon />
        </Button>
      </TaskTitle>
      <ModalTaskBody flexDirection="column">
        <Flex alignItems="center" justifyContent="center" px="5px">
          <Text fontSize="14px" fontWeight="400" py={32} px={38} textAlign="center">
            {t(title.key, title.params)}
          </Text>
        </Flex>

        <TaskContentDesc contentBottom={contentBottom}>{content}</TaskContentDesc>

        <TaskButton
          as={NextLinkFromReactRouter}
          target="_blank"
          to={productTwitterUrl()}
          color={taskTypeMap[type].color}
          onClick={() => {
            callback()
          }}
        >
          <Text fontSize="14px" bold>
            {buttonDesc || t(taskTypeMap[type].action)}
          </Text>
        </TaskButton>
      </ModalTaskBody>
    </ModalTaskContainer>
  )
}
