import {
  Button,
  Flex,
  Heading,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  Text,
} from '@library/uikit'
import { useTranslation } from '@library/localization'
import styled from 'styled-components'
import NextImage from 'next/image'
import React from 'react'
import { enUS, ja, zhCN } from 'date-fns/locale'

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`

const LogoutModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const LANGUAGE2CODE = { 'zh-cn': zhCN, en: enUS, ja }
  const { t, currentLanguage } = useTranslation()
  const { code } = currentLanguage

  const imageMap = { ep: '/images/sun.svg', hp: '/images/sun.svg' }

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>离开</Heading>
        </ModalTitle>
      </ModalHeader>
      <ModalBody
        p="24px"
        maxWidth="400px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        style={{ gap: '12px' }}
      >
        <NextImage src="/images/sun.svg" width={64} height={64} />
        <Text>你已下线,在其他设备登陆</Text>
      </ModalBody>

      <Flex justifyContent="center" alignItems="center" mb={10} width="100%" pb={60}>
        <Button onClick={() => onDismiss()} width="50%" scale="sm">
          <Text>确认</Text>
        </Button>
      </Flex>
    </ModalContainer>
  )
}

export default LogoutModal
