import { UserMenuItem } from '@library/uikit'
import { useTranslation } from '@library/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

interface ProfileUserMenuItemProps {
  disabled: boolean
}

const ProfileUserMenuItem: React.FC<ProfileUserMenuItemProps> = ({ disabled }) => {
  const { account } = useActiveWeb3React()
  const { data: session } = useSession()
  const router = useRouter()
  const { t } = useTranslation()

  const handleClick = () => {
    router.push(session ? `/profile/${session.userId}` : `/profile/${account}`)
  }

  return (
    <UserMenuItem as="button" disabled={disabled} onClick={handleClick}>
      {t('Your Profile')}
    </UserMenuItem>
  )
}

export default ProfileUserMenuItem
