import { useSession } from 'next-auth/react'
import { useMemo } from 'react'
import useActiveWeb3React from './useActiveWeb3React'

const useWallet = () => {
  const { data: session } = useSession()
  const { account, ...web3React } = useActiveWeb3React()
  const walletAddress = useMemo<string>(() => (session ? (session.userId as string) : account), [session, account])

  return { walletAddress, session, ...web3React }
}

export default useWallet
