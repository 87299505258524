import { standardEthAddress } from '@common/utils'
import {
  Box,
  Flex,
  LogoutArrow,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
} from '@library/uikit'
import axios from 'axios'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useTranslation } from '@library/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useDagenApi } from 'hooks/useDagenApi'
import useWallet from 'hooks/useWallet'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useProfile } from 'state/profile/hooks'
import { useDagenToken } from 'state/user/hooks'
import { getChainById } from 'utils/networks'
import { nftsBaseUrl } from 'views/Gens/Market/constants'
import ProfileUserMenuItem from './ProfileUserMenutItem'
import useLogoutManager from '../../../hooks/game/useLogin'

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { logout } = useAuth()
  const { profile } = useProfile()
  const avatarSrc = profile?.avatar
  const [userMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork = !getChainById(chainId)
  const dagenApi = useDagenApi()
  const [dagenToken, setDagenToken] = useDagenToken()
  const { data: session, status } = useSession()
  const { walletAddress } = useWallet()
  const { startLogin } = useLogoutManager()

  useEffect(() => {
    if (account) {
      dagenApi
        .post(`loginRecord`, {
          address: account,
          userAgent: navigator.userAgent,
        })
        .catch(console.error)
    }
  }, [account])

  const UserMenuItems = () => {
    return (
      <>
        <UserMenuItem
          as="button"
          disabled={isWrongNetwork}
          onClick={() => router.push(`${process.env.NEXT_PUBLIC_DAGEN_BACKEND}/#/address/${walletAddress}`)}
        >
          {t('Recent Transactions')}
        </UserMenuItem>
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={() => router.push(`${nftsBaseUrl}/yours`)}>
          {t('Your Gens')}
        </UserMenuItem>
        {/* <UserMenuItem as="button" disabled={isWrongNetwork} onClick={() => router.push(`/friends`)}>
          {t('Friends')}
        </UserMenuItem> */}
        <ProfileUserMenuItem disabled={isWrongNetwork} />
        <UserMenuDivider />
        {walletAddress ? (
          <UserMenuItem as="button" onClick={logout}>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              {t('Disconnect')}
              <LogoutArrow />
            </Flex>
          </UserMenuItem>
        ) : (
          <UserMenuItem as="button" onClick={startLogin}>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              {t('Connect')}
            </Flex>
          </UserMenuItem>
        )}
      </>
    )
  }

  if (session) {
    if (status === 'authenticated' && !dagenToken) {
      axios.post(`/api/socialLogin`, { userId: session.userId }).then((res) => setDagenToken(res.data.token))
    }
    return (
      <UIKitUserMenu
        nickName={profile?.nickName}
        account={standardEthAddress(session.userId as string)}
        avatarSrc={avatarSrc}
        variant={userMenuVariable}
      >
        <UserMenuItems />
      </UIKitUserMenu>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu nickName={profile?.nickName} account={account} avatarSrc={avatarSrc} variant={userMenuVariable}>
        <UserMenuItems />
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        <UserMenuItems />
      </UIKitUserMenu>
    )
  }

  return (
    <Flex justifyContent="center" alignItems="center">
      <ConnectWalletButton>
        <Box>
          <Trans>Connect</Trans>
        </Box>
      </ConnectWalletButton>
    </Flex>
  )
}

export default UserMenu
