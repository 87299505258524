// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId } from '@pancakeswap/sdk'
import {
  DagenItems,
  DagenMarket,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc20Bytes32,
  Multicall,
} from 'config/abi/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import {
  getBadgeBlindBoxContract,
  getBadgeContract,
  getBlindBoxContract,
  getCSSContract,
  getDagenTokenContract,
  getErc1155Contract,
  getErc20Contract,
  getErc721Contract,
  getItemsContract,
  getMarketContract,
} from 'utils/contractHelpers'
import { getMulticallAddress } from '../../common/addressHelpers'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import multiCallAbi from '../config/abi/Multicall.json'
import { getContract, getProviderOrSigner } from '../utils'

export const useCSSToken = (withSignerIfPossible = true) => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getCSSContract(withSignerIfPossible && chainId === 1 ? getProviderOrSigner(library, account) : null),
    [account, chainId, library, withSignerIfPossible],
  )
}

export const useDagenToken = (withSignerIfPossible = true) => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getDagenTokenContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [account, chainId, library, withSignerIfPossible],
  )
}

export const useBlindBox = (withSignerIfPossible = true) => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getBlindBoxContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [account, chainId, library, withSignerIfPossible],
  )
}

export const useBadgeBlindBox = (withSignerIfPossible = true) => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getBadgeBlindBoxContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [account, chainId, library, withSignerIfPossible],
  )
}
/**
 * Helper hooks to get specific contracts (by ABI)
 */
export const useERC20 = (address: string, withSignerIfPossible = true) => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getErc20Contract(chainId, address, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [account, address, chainId, library, withSignerIfPossible],
  )
}

export const useERC1155 = (address: string, withSignerIfPossible = true) => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getErc1155Contract(chainId, address, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [account, address, chainId, library, withSignerIfPossible],
  )
}

export const useERC721 = (address: string, withSignerIfPossible = true) => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getErc721Contract(chainId, address, withSignerIfPossible ? getProviderOrSigner(library, account) : null),
    [account, address, chainId, library, withSignerIfPossible],
  )
}

export const useMarketContract = (): { reader: DagenMarket; signer: DagenMarket } => {
  const { account, library, chainId } = useActiveWeb3React()
  return useMemo(
    () => ({
      reader: getMarketContract(chainId),
      signer: getMarketContract(chainId, getProviderOrSigner(library, account)),
    }),
    [account, library, chainId],
  )
}

export const useItemsContract = (): { reader: DagenItems; signer: DagenItems } => {
  const { account, library, chainId } = useActiveWeb3React()
  return useMemo(
    () => ({
      reader: getItemsContract(chainId),
      signer: getItemsContract(chainId, getProviderOrSigner(library, account)),
    }),
    [account, library, chainId],
  )
}

export const useBadgeContract = (): { reader: DagenItems; signer: DagenItems } => {
  const { account, library, chainId } = useActiveWeb3React()
  return useMemo(
    () => ({
      reader: getBadgeContract(chainId),
      signer: getBadgeContract(chainId, getProviderOrSigner(library, account)),
    }),
    [account, library, chainId],
  )
}

// returns null on errors
function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, withSignerIfPossible ? getProviderOrSigner(library, account) : null)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account]) as T
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract<EnsRegistrar>(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract<Erc20Bytes32>(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract() {
  return useContract<Multicall>(getMulticallAddress(), multiCallAbi, false)
}
