import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "./Button";
import { BaseButtonProps } from "./types";

const IconButton: PolymorphicComponent<BaseButtonProps, "button"> = styled(Button)<BaseButtonProps>`
  padding: 0;
  width: ${({ scale }) => (scale === "sm" ? "10px" : "48px")};

  ${({ theme }) => theme.mediaQueries.sm} {
    width: ${({ scale }) => (scale === "sm" ? "32px" : "48px")};
  }
  svg {
    opacity: 0.6;
  }
`;

export default IconButton;
