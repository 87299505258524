import { Button, ButtonProps, connectorLocalStorageKey, ConnectorNames, useWalletModal } from '@library/uikit'
import { useTranslation } from '@library/localization'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import useLogin from 'hooks/game/useLogin'
import Trans from './Trans'

const ButtonContainer = styled(Button)<{ fontWeight?: number }>`
  font-size: 10px;
  color: white;
  background-color: transparent;
  border: 2px solid #f9dd48;
  border-radius: 40px;
  font-weight: ${({ fontWeight }) => fontWeight || 400};

  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    background-color: #f9dd4877;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
  }
`

export const SCALES = ['sm', 'sm', 'md', 'md']
export const HEIGHTS = ['24px', '24px', '28px', '28px']

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { startLogin } = useLogin()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <ButtonContainer defaultHoverDisable scale={SCALES} height={HEIGHTS} onClick={() => startLogin()} {...props}>
      {children || <Trans>Connect</Trans>}
    </ButtonContainer>
  )
}

export default ConnectWalletButton
