import { useTranslation } from '@library/localization'
import { Flex, InjectedModalProps, ModalLevelUpContainer, Text } from '@library/uikit'
import noop from 'lodash/noop'
import React from 'react'
import styled from 'styled-components'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import { ILevelUpParams } from '@common/types'
import { DAGEN_DOMAIN } from '@common/constans'
import { shareTwitterUrl } from 'utils'
import { FlexGap } from '../../components/Layout/Flex'
import { NextLinkFromReactRouter } from '../../components/NextLink'
import useCommunities from '../../hooks/useCommunities'
import useWallet from '../../hooks/useWallet'

const Container = styled(Flex)<{ bgImage: string; coreImage: string; flashImage: string }>`
  height: 100%;
  width: 100%;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  position: relative;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .close-circle {
    position: absolute;
    width: 32px;
    height: 32px;
    border: 1px solid #d4b27e;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 10;

    top: 10%;
    right: 5%;

    ${({ theme }) => theme.mediaQueries.sm} {
      top: 20%;
      right: 5%;
    }
  }

  .close-mark {
    width: 14px;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #d4b27e;
  }

  /*黑色遮罩+外星图*/

  .lvlup-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: ${({ coreImage }) => `url(${coreImage})`};

    background-size: cover;
    animation: simple-fadein 0.5s;
  }

  /*一个简单的透明度动画，在描述文字上会复用*/
  @keyframes simple-fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /*后面的大号等级文字*/

  .lvl-large {
    position: absolute;
    color: white;
    overflow: hidden;
    mix-blend-mode: overlay;
    opacity: 0;
    text-align: center;
    font-style: italic;
    animation: lvl-move 6s 1s infinite;
    font-size: 80vh;
    line-height: 85vh;
    font-weight: 500;
    height: 100%;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 100vh;
      line-height: 100vh;
      font-weight: 300;
    }
  }

  @keyframes lvl-move {
    0% {
      left: -160px;
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    90% {
      left: -10px;
      opacity: 0;
    }
  }

  /*文字背景的黑色半透明底色*/

  .block-bg {
    position: absolute;
    width: 1080px;
    height: 160px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.66),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.66),
      rgba(0, 0, 0, 0)
    );

    border-width: 1px;
    border-style: solid none;
    border-image: linear-gradient(to right, rgba(255, 204, 0, 0), #d4b27e, #ffeb80, #d4b27e, rgba(255, 204, 0, 0)) 1;
    opacity: 0;
    animation: simple-fadein 0.5s 0.5s forwards;
  }

  /*白色闪光*/

  .block-flash {
    position: absolute;
    width: 100%;
    height: 160px;
    background-image: ${({ flashImage }) => `url(${flashImage})`};

    background-repeat: no-repeat;
    background-position: -1600px 0;
    animation: flash-fly 0.4s linear 0.7s forwards;
  }

  @keyframes flash-fly {
    from {
      background-position: -1600px 0;
    }
    to {
      background-position: 100vw 0;
    }
  }

  /*升级的标题文字，LEVEL UP!*/

  .lvlup-title {
    position: absolute;
    opacity: 0;
    background: linear-gradient(150deg, white, #fff58a, #ebff00, #00ffa3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ebff00;
    font-weight: 600;
    text-align: center;
    font-style: italic;
    animation: lvlup-title-fly 0.3s ease 0.7s forwards;
    font-size: 48px;
    padding: 16px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 80px;
      padding: 32px;
    }
  }

  @keyframes lvlup-title-fly {
    from {
      margin-top: 0;
      opacity: 0;
    }
    to {
      margin-top: -180px;
      opacity: 1;
    }
  }

  /*升级的描述*/

  .lvlup-desc {
    position: absolute;
    color: white;
    opacity: 0;
    margin-top: -56px;
    font-size: 20px;
    text-align: center;
    animation: simple-fadein 0.4s ease 0.9s forwards;
  }

  /*描述中绿色加重的高亮文字*/

  .lvlup-bold {
    font-weight: 500;
    color: #ebff00;
  }

  /*奖励内容的描述*/

  .lvlup-reward {
    position: absolute;
    color: #ccc;
    opacity: 0;
    font-size: 14px;
    text-align: center;
    animation: simple-fadein 0.9s ease 1s forwards;
  }
`

const ShareButton = styled(ClickFlex)`
  &:hover {
    background: green;
  }

  align-items: center;
  justify-content: center;
  position: absolute;
  width: 200px;
  height: 32px;
  background: #89bd00;
  color: white;
  opacity: 0;
  margin-top: 88px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 32px;
  border-radius: 100px;
  cursor: pointer;
  animation: simple-fadein 0.5s ease 1.4s forwards;
`

export const AttributeChangeItem: React.FC<ILevelUpParams> = ({ itemId, preAndCurrent }) => {
  const { t } = useTranslation()
  return (
    <FlexGap gap="2px" alignItems="center" justifyContent="center">
      <Text fontSize="16px" textAlign="center">
        {t(itemId)} :
      </Text>

      <Text fontSize="16px" textAlign="center">
        {preAndCurrent.preValue} {`->`}
      </Text>

      <Text fontSize="16px" color="#B6FF1B" textAlign="center" className="lvlup-bold">
        {preAndCurrent.currentValue}
      </Text>

      <Text fontSize="16px" color="#B6FF1B" textAlign="center" className="lvlup-bold">
        (+{preAndCurrent.currentValue - preAndCurrent.preValue})
      </Text>
    </FlexGap>
  )
}

export interface ILevelUp extends InjectedModalProps {
  callback?: () => void
  level: number
  params: ILevelUpParams[]
}

export const LevelUpModals: React.FC<ILevelUp> = ({ level, params, callback = noop, onDismiss }) => {
  const { t } = useTranslation()
  const bgImage = '/images/game/levelup/bgImage.png'
  const coreImage = '/images/game/levelup/coreImage.png'
  const flashImage = '/images/game/levelup/flashImage.png'
  const { walletAddress } = useWallet()
  const { communities } = useCommunities(walletAddress)
  const shareContent = `${DAGEN_DOMAIN}/profile/${walletAddress}`

  return (
    <ModalLevelUpContainer>
      <Container bgImage={bgImage} coreImage={coreImage} flashImage={flashImage}>
        <ClickFlex className="close-circle" onClick={() => onDismiss()}>
          <div className="close-mark">✕</div>
        </ClickFlex>

        <div className="lvlup-bg" />
        <div className="block-bg" />
        <div className="lvl-large">{level}</div>
        <div className="lvlup-title">{t('LEVEL UP')}</div>
        <div className="block-flash" />
        <div className="lvlup-desc">
          {t('You have reached Level')} <span className="lvlup-bold">{level}</span> !
        </div>

        <FlexGap gap="5px" className="lvlup-reward">
          {params &&
            params.map((item, index) => {
              return (
                <AttributeChangeItem
                  key={`level_up_params_${index}`}
                  itemId={item.itemId}
                  preAndCurrent={item.preAndCurrent}
                />
              )
            })}
        </FlexGap>

        <ShareButton
          as={NextLinkFromReactRouter}
          target="_blank"
          to={shareTwitterUrl(t('Share level up'), communities, shareContent)}
          onClick={() => {
            onDismiss()
            callback()
          }}
        >
          <Text fontSize="14px" bold>
            {t('Share on Twitter')}
          </Text>
        </ShareButton>
      </Container>
    </ModalLevelUpContainer>
  )
}
