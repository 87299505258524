import { getSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useWallet from './useWallet'

const useUserAgent = () => {
  const { walletAddress } = useWallet()
  const [loginCheck, setLoginCheck] = useState(false)
  const router = useRouter()
  useEffect(() => {
    document.documentElement.setAttribute('data-useragent', navigator.userAgent)
  }, [])

  useEffect(() => {
    const check = async () => {
      if (walletAddress) setLoginCheck(true)
      else {
        const session = await getSession()
        if (session) setLoginCheck(true)
      }
    }

    check()

    setTimeout(() => {
      setLoginCheck(true)
    }, 3000)
  }, [router, walletAddress])

  useEffect(() => {
    // 未登陆去首页
    if ((router.pathname === '/scenes' || router.pathname === '/game') && loginCheck && !walletAddress) {
      router.replace('/')
    }
  }, [loginCheck, router, walletAddress])
}

export default useUserAgent
