import type { Signer } from '@ethersproject/abstract-signer'
import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import type { Provider } from '@ethersproject/providers'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { CurrencyAmount, JSBI, Percent } from '@pancakeswap/sdk'
import { getExplorer } from './networks'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId?: number,
): string {
  switch (type) {
    case 'transaction': {
      return `${getExplorer(chainId)}/tx/${data}`
    }
    case 'token': {
      return `${getExplorer(chainId)}/token/${data}`
    }
    case 'block': {
      return `${getExplorer(chainId)}/block/${data}`
    }
    case 'countdown': {
      return `${getExplorer(chainId)}/block/countdown/${data}`
    }
    default: {
      return `${getExplorer(chainId)}/address/${data}`
    }
  }
}

export function getScanLinkForNft(chainId: number, collectionAddress: string, tokenId: string): string {
  return `${getExplorer(chainId)}/token/${collectionAddress}?a=${tokenId}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, signer?: Signer | Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const ERC1155 = 'ERC1155'
export const ERC721 = 'ERC721'

export const ITEMS_PER_PAGE = 36

export const getImageUrlFromTokenAddress = (tokenAddress: string) => {
  return `/images/tokens/${tokenAddress}.png`
}

export function withdrawStatus(
  t,
  status: 'inprogress' | 'admin_review' | 'success' | 'fail' | 'usercancel' | 'admincancel' | 'timeout',
) {
  if (status === 'admin_review') {
    return t('Admin review')
  }
  if (status === 'success') {
    return t('Success')
  }
  if (status === 'fail') {
    return t('Failed')
  }
  if (status === 'usercancel') {
    return t('User cancel')
  }
  if (status === 'admincancel') {
    return t('Admin cancel')
  }
  if (status === 'timeout') {
    return t('Timeout')
  }
  return t('Inprogress')
}

export function isGamePage(path: string) {
  if (path.startsWith('/game') || path.startsWith('/scenes')) return true
  return false
}

export const shareTwitterUrl = (shareText: string, communities: { name: string }[], shareContent: string) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `@dagenLife ${shareText} #dagen ${communities.map((c) => `#${c.name}`)}`,
  )}&url=${encodeURIComponent(shareContent)}`
}

export const calcLost = (value: number, max: number) => {
  return value > max - 100 ? (value > 100 ? value - 100 : value) : 0
}
