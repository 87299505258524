import { utils } from 'ethers'

export function standardEthAddress(address: string) {
  try {
    return address ? utils.getAddress(address) : ''
  } catch (err) {
    return address
  }
}

export const truncateHash = (address: string, startLength = 4, endLength = 4) => {
  if (!address) return ''

  return `${address.substring(0, startLength)}...${address.substring(address.length - endLength)}`
}

export const getTxCount = (address: string) => {
  return 0
}

export const EMPTY_BYTES = '0x0000000000000000000000000000000000000000000000000000000000000000'
export const ADDRESS_0 = '0x0000000000000000000000000000000000000000'

export const paddingHex = (i: number | string) => {
  return `0000000000000000000000000000000000000000000000000000000000000000${Number(i).toString(16)}`.substring(-64)
}

export const resolveLink = (url: string) => {
  if (!url || !url.includes('ipfs://')) return url
  return url.replace('ipfs://', 'https://gateway.ipfs.io/ipfs/')
}

export const openNewTabByUrl = (url) => {
  const w = window.open('_black')
  w.location.href = url
}

export const getTwoRandom = (seed: number) => {
  const bgRandom1 = Math.floor(Math.random() * seed + 1)
  const bgRandom2 = Math.floor(Math.random() * (seed - bgRandom1) + bgRandom1)
  let bgRandom1Str = String(bgRandom1)
  let bgRandom2Str = String(bgRandom2)
  if (bgRandom1 < 10) bgRandom1Str = `0${bgRandom1Str}`
  if (bgRandom2 < 10) bgRandom2Str = `0${bgRandom2Str}`
  return [bgRandom1Str, bgRandom2Str]
}

export const timeFormat = () => {
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1
  let monthStr = String(month)
  if (month < 10) monthStr = String(0).concat(monthStr)
  const day = new Date().getDate()
  let dayStr = String(day)
  if (day < 10) dayStr = String(0).concat(dayStr)
  return String(year).concat(monthStr).concat(dayStr)
}
