import useAuth from '../useAuth'
import useLogoutManager from '../game/useLogoutManager'

const useEventMsgLogout = () => {
  const { logout } = useAuth()
  const { showLogout } = useLogoutManager()

  return async (event) => {
    showLogout()
    await logout()
  }
}

export default useEventMsgLogout
