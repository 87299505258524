import { Pair, Token } from '@pancakeswap/sdk'
import { useTranslation } from '@library/localization'
import { differenceInDays } from 'date-fns'
import { useDagenApi } from 'hooks/useDagenApi'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KeyedMutator } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { isGamePage } from 'utils'
import { AppDispatch, AppState } from '../../index'
import {
  hideAnnouncementBanner,
  muteAudio,
  unmuteAudio,
  updateDagenToken,
  updateEnergyCollectable,
  updateFusionConfirm,
  updateGasPrice,
  updateGenCollectable,
  updateUserDeadline,
  updateUserSingleHopOnly,
  updateUserSlippageTolerance,
} from '../actions'

export function useAudioModeManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>()
  const audioPlay = useSelector<AppState, AppState['user']['audioPlay']>((state) => state.user.audioPlay)

  const toggleSetAudioMode = useCallback(() => {
    if (audioPlay) {
      dispatch(muteAudio())
    } else {
      dispatch(unmuteAudio())
    }
  }, [audioPlay, dispatch])

  return [audioPlay, toggleSetAudioMode]
}

export function useAnnouncementBannerManager(): [
  boolean,
  () => void,
  { title: string; content: string },
  { mutate: KeyedMutator<any> },
] {
  const { currentLanguage } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  const hideTimestampAnnouncementBanner = useSelector<AppState, AppState['user']['hideTimestampAnnouncementBanner']>(
    (state) => state.user.hideTimestampAnnouncementBanner,
  )
  const dagenApi = useDagenApi()

  const { data: announcement, mutate } = useSWRImmutable('announcement', async (url) => {
    const res = await dagenApi.get(url, { params: { language: currentLanguage.code.split('-')[0] } })
    return res.data
  })

  const now = Date.now()
  const showAnnouncementBanner =
    !isGamePage(router.pathname) &&
    (hideTimestampAnnouncementBanner ? differenceInDays(now, hideTimestampAnnouncementBanner) >= 1 : true) &&
    announcement?.success

  const hideBanner = useCallback(() => {
    dispatch(hideAnnouncementBanner())
  }, [dispatch])

  return [showAnnouncementBanner, hideBanner, announcement?.result, { mutate }]
}

export function useUserSingleHopOnly(): [boolean, (newSingleHopOnly: boolean) => void] {
  const dispatch = useDispatch<AppDispatch>()

  const singleHopOnly = useSelector<AppState, AppState['user']['userSingleHopOnly']>(
    (state) => state.user.userSingleHopOnly,
  )

  const setSingleHopOnly = useCallback(
    (newSingleHopOnly: boolean) => {
      dispatch(updateUserSingleHopOnly({ userSingleHopOnly: newSingleHopOnly }))
    },
    [dispatch],
  )

  return [singleHopOnly, setSingleHopOnly]
}

export function useUserSlippageTolerance(): [number, (slippage: number) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const userSlippageTolerance = useSelector<AppState, AppState['user']['userSlippageTolerance']>((state) => {
    return state.user.userSlippageTolerance
  })

  const setUserSlippageTolerance = useCallback(
    (slippage: number) => {
      dispatch(updateUserSlippageTolerance({ userSlippageTolerance: slippage }))
    },
    [dispatch],
  )

  return [userSlippageTolerance, setUserSlippageTolerance]
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const userDeadline = useSelector<AppState, AppState['user']['userDeadline']>((state) => {
    return state.user.userDeadline
  })

  const setUserDeadline = useCallback(
    (deadline: number) => {
      dispatch(updateUserDeadline({ userDeadline: deadline }))
    },
    [dispatch],
  )

  return [userDeadline, setUserDeadline]
}

export function useDagenToken(): [string, (token: string) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const dagenToken = useSelector<AppState, AppState['user']['dagenToken']>((state) => {
    return state.user.dagenToken
  })

  const setDagenToken = useCallback(
    (token: string) => {
      dispatch(updateDagenToken({ dagenToken: token }))
    },
    [dispatch],
  )

  return [dagenToken, setDagenToken]
}

export function useEnergyCollectable(): [boolean, (collectable: boolean) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const energyCollectable = useSelector<AppState, AppState['user']['energyCollectable']>((state) => {
    return state.user.energyCollectable
  })

  const setEnergyCollectable = useCallback(
    (collectable: boolean) => {
      dispatch(updateEnergyCollectable(collectable))
    },
    [dispatch],
  )

  return [energyCollectable, setEnergyCollectable]
}

export function useGenCollectable(): [boolean, (collectable: boolean) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const genCollectable = useSelector<AppState, AppState['user']['genCollectable']>((state) => {
    return state.user.genCollectable
  })

  const setGenCollectable = useCallback(
    (collectable: boolean) => {
      dispatch(updateGenCollectable(collectable))
    },
    [dispatch],
  )

  return [genCollectable, setGenCollectable]
}

export function useFusionConfirm(): [boolean, (fusionConfirm: boolean) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const fusionConfirm = useSelector<AppState, AppState['user']['fusionConfirm']>((state) => {
    return state.user.fusionConfirm
  })

  const setFusionConfirm = useCallback(
    (fusionConfirm: boolean) => {
      dispatch(updateFusionConfirm(fusionConfirm))
    },
    [dispatch],
  )

  return [fusionConfirm, setFusionConfirm]
}

export function useGasPrice(): string {
  const userGas = useSelector<AppState, AppState['user']['gasPrice']>((state) => state.user.gasPrice)
  return userGas
}

export function useGasPriceManager(): [string, (userGasPrice: string) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const userGasPrice = useGasPrice()

  const setGasPrice = useCallback(
    (gasPrice: string) => {
      dispatch(updateGasPrice({ gasPrice }))
    },
    [dispatch],
  )

  return [userGasPrice, setGasPrice]
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  return new Token(tokenA.chainId, Pair.getAddress(tokenA, tokenB), 18, 'Cake-LP', 'LPs')
}
