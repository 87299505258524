import { NoBscProviderError } from '@binance-chain/bsc-connector'
import { useTranslation } from '@library/localization'
import { Box, connectorLocalStorageKey, ConnectorNames, Text, useModal } from '@library/uikit'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import axios from 'axios'
import { MENU_ITEMS } from 'components/Chains/Chains'
import useToast from 'hooks/useToast'
import { getCsrfToken, getSession, signIn, signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { SiweMessage } from 'siwe'
import { useAppDispatch } from 'state'
import { useMiniPlayer } from 'state/player/hooks'
import { useDagenToken } from 'state/user/hooks'
import { addNetwork, setupNetwork } from 'utils/wallet'
import { connectorsByName, signMessage } from 'utils/web3React'
import EmailLoginModal from 'views/Home/EmailLoginModal'
import { clearUserStates } from '../utils/clearUserStates'

declare module 'next-auth' {
  export interface Session {
    userId: string
    accessToken: string
  }
}

const useAuth = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { chainId, activate, deactivate, setError, account, library, connector } = useWeb3React()
  const { toastError } = useToast()
  const [_, setDagenToken] = useDagenToken()
  const { data: session } = useSession()
  const [miniPlayer, setMiniPlayer] = useMiniPlayer()
  const router = useRouter()

  const [onPresentEmailLoginModal, onDismissEmailLoginModal] = useModal(
    <EmailLoginModal />,
    true,
    true,
    'EmailLoginModal',
  )

  const login = useCallback(
    async (connectorID: ConnectorNames, eager = false): Promise<any> => {
      const connectorOrGetConnector = connectorsByName[connectorID]
      let connectorInner =
        typeof connectorOrGetConnector !== 'function' ? connectorsByName[connectorID] : await connectorOrGetConnector()

      const handleError = async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          setError(error)
          console.error(connectorInner)

          if (connectorInner) {
            try {
              const provider = await connectorInner.getProvider()
              const hasSetup = await setupNetwork(MENU_ITEMS()[0].value, provider)

              if (hasSetup) {
                activate(connectorInner)
              }
            } catch (error) {
              toastError(
                t('Provider Error'),
                <Box>
                  <Text>{t('No provider was found')}</Text>
                </Box>,
              )
            }
          } else {
            toastError(
              t('Provider Error'),
              <Box>
                <Text>{t('No provider was found')}</Text>
              </Box>,
            )
          }
        } else {
          window?.localStorage?.removeItem(connectorLocalStorageKey)
          if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
            toastError(
              t('Provider Error'),
              <Box>
                <Text>{t('No provider was found')}</Text>
              </Box>,
            )
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connectorInner instanceof WalletConnectConnector) {
              const walletConnector = connectorInner as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            toastError(t('Authorization Error'), t('Please authorize to access your account'))
          } else {
            toastError(error.name, error.message)
          }
        }
      }

      if (process.env.NODE_ENV === 'development') {
        await addNetwork(MENU_ITEMS()[0].value)
      }

      if (connectorID === ConnectorNames.Wallet3) {
        if (window.navigator?.userAgent?.indexOf('Wallet3') !== -1) {
          connectorInner = connectorsByName[ConnectorNames.Injected]
          activate(connectorInner, handleError)
        } else {
          window.open(`https://wallet3.io/wc/?uri=wallet3://open?url=https://dagen.life`)
        }
      } else if (connectorID === ConnectorNames.Twitter || connectorID === ConnectorNames.Google) {
        if (!eager) {
          const session = await getSession()
          if (!session) {
            const res = await signIn(connectorID.toLowerCase())
            return res
          }
        }
      } else if (connectorID === ConnectorNames.EMAIL) {
        if (!eager) {
          onPresentEmailLoginModal()
        }
      } else if (typeof connectorInner !== 'function' && connectorInner) {
        activate(connectorInner, handleError)
      } else {
        window?.localStorage?.removeItem(connectorLocalStorageKey)
        toastError(t('Unable to find connector'), t('The connector config is wrong'))
      }

      return ''
    },
    [activate, toastError, setError],
  )

  const sign = useCallback(async () => {
    if (!connector || !library || !account) return {}

    const message = new SiweMessage({
      domain: window.location.host,
      address: account,
      statement: 'Sign in with Ethereum to the app.',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce: await getCsrfToken(),
    })

    const sig = await signMessage(connector, library, account, message.prepareMessage())
    return { sig, message }
  }, [account, connector, library])

  const loginDagen = useCallback(async () => {
    const signRes = await sign()
    if (!signRes.sig) return ''
    const res = await axios.post(`/api/login`, {
      address: account,
      chainId,
      message: signRes.message,
      sig: signRes.sig,
    })
    setDagenToken(res.data.token)
    return res.data.token
  }, [account, chainId, setDagenToken, sign])

  const logout = useCallback(async () => {
    deactivate()
    if (session) await signOut()
    clearUserStates(dispatch, chainId)
    setMiniPlayer({})
  }, [deactivate, session, dispatch, chainId])

  return { login, logout, sign, loginDagen }
}

export default useAuth
