import axios from 'axios'
import clone from 'lodash/clone'
import set from 'lodash/set'
import { signOut, useSession } from 'next-auth/react'
import { useMemo } from 'react'
import { usePreGameConnect } from 'state/player/hooks'
import { useDagenToken } from 'state/user/hooks'
import useAuth from './useAuth'

import aes from '../../common/aes'

declare module 'axios' {
  interface AxiosInstance {
    eventSource: (url) => EventSource
  }
}

export const useDagenApi = () => {
  const { loginDagen } = useAuth()
  const [dagenToken] = useDagenToken()
  const { data: session } = useSession()
  const [preGameConnect, setPreGameConnect] = usePreGameConnect()

  function decryptData(auth: string, encryptedHex: string) {
    const key = aes.generateKey(auth, '')
    const decryptedData = JSON.parse(aes.decrypt(key, encryptedHex))
    return decryptedData
  }

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: '/api',
      headers: {
        Authorization: dagenToken,
      },
    })

    const decrypt = (token, response) => {
      if (response.data.secureData) {
        try {
          const decryptedData = decryptData(token, response.data.secureData)
          set(response.data, 'result', decryptedData)
        } catch (error) {
          console.error(error)
        }
      }
    }

    instance.interceptors.response.use(
      (response) => {
        decrypt(dagenToken, response)

        return response
      },
      async (error) => {
        if (error.config && error.response && error.response.status === 401) {
          if (session) {
            signOut()
          } else {
            const token = await loginDagen()
            const config = clone(error.config)
            config.headers.Authorization = token

            const response = await instance.request(config)
            decrypt(token, response)
            setPreGameConnect(true)
            return response
          }
        }

        return Promise.reject(error)
      },
    )

    set(instance, 'eventSource', (url) => {
      const evtSource = new EventSource(`/api/${url}`)
      return evtSource
    })

    return instance
  }, [dagenToken, loginDagen])

  return api
}
