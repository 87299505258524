import { useSystemInfo } from 'state/player/hooks'
import { useStore } from '../../state'

const useEventMsg1012SyncBarrage = () => {
  const [_, setSystemInfo] = useSystemInfo()
  const { getState } = useStore()

  return (event) => {
    const eventData: { attacker: string; affecter: string } = JSON.parse(event.data)
    setSystemInfo({
      ...getState().player.systemInfo,
      ...{ bullet: eventData },
    })
  }
}

export default useEventMsg1012SyncBarrage
