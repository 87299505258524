import {
  Button,
  CloseIcon,
  ConnectorNames,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  Text,
} from '@library/uikit'
import { useTranslation } from '@library/localization'
import { useDagenApi } from 'hooks/useDagenApi'
import useInterval from 'hooks/useInterval'
import useToast from 'hooks/useToast'
import { signIn } from 'next-auth/react'
import { useState } from 'react'
import styled from 'styled-components'

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`

const EmailLoginModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const dagenApi = useDagenApi()
  const [input, setInput] = useState('')
  const [code, setCode] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [counter, setCounter] = useState(60)
  const [delay, setDelay] = useState(null)
  useInterval(() => {
    setCounter(counter - 1)

    if (counter <= 0) {
      setIsSending(false)
      setCounter(60)
      setDelay(null)
    }
  }, delay)

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Email Login')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="24px" maxWidth="400px" width="100%">
        <Flex mb={30} alignItems="center">
          <Input
            placeholder={t('Email Address')}
            type="email"
            name="email"
            value={input}
            autoFocus
            onChange={(e) => setInput(e.target.value)}
          />
        </Flex>
        <Text>{t('Verification Code')}</Text>
        <Flex mb={30} alignItems="center">
          <Input
            placeholder={t('Verification Code')}
            type="number"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <Button
            isLoading={isSending}
            variant="subtle"
            style={{ whiteSpace: 'nowrap', zoom: 0.9 }}
            ml={20}
            onClick={async () => {
              setIsSending(true)
              setDelay(1000)

              try {
                const res = await signIn(ConnectorNames.EMAIL.toLowerCase(), { redirect: false, email: input.trim() })
                if (res.ok) {
                  toastSuccess(t('Verification code alreay send to your email'))
                } else {
                  toastError(res.error)
                }
              } catch (error: any) {
                toastError(error.message)
              }
            }}
          >
            {!isSending ? t('Get Code') : `${t('Resend')}(${counter})`}
          </Button>
        </Flex>

        <Button
          variant="tertiary"
          onClick={async () => {
            try {
              await dagenApi.get('auth/callback/email', {
                params: { token: code, email: input.trim() },
              })
              window.location.reload()
            } catch (error: any) {
              toastError(t('Please check your verification code'))
            }
          }}
        >
          {t('Submit')}
        </Button>
      </ModalBody>
    </ModalContainer>
  )
}

export default EmailLoginModal
