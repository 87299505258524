import { AnimatePresence, domAnimation, LazyMotion, m, Variants } from "framer-motion";
import React, { createContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Overlay } from "../../components/Overlay";
import { Handler } from "./types";

const animationVariants: Variants = {
  initial: { transform: "translateX(0px)" },
  animate: { transform: "translateX(0px)" },
  exit: { transform: "translateX(0px)" },
};

const animationMap = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
};

interface ModalsContext {
  isOpen: boolean;
  nodeIds: string[];
  modalNodes: { node: React.ReactNode; priority: number }[];
  setModalNodes: React.Dispatch<React.SetStateAction<{ node: React.ReactNode; priority: number }[]>>;
  onPresent: (node: React.ReactNode, newNodeId: string, priority?: number) => void;
  onDismiss: Handler;
  onDismissAll: Handler;
  setCloseOnOverlayClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const appearAnimation = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const disappearAnimation = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const ModalWrapper = styled(m.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.modal};
  will-change: opacity;
  opacity: 0;

  &.appear {
    animation: ${appearAnimation} 0.3s ease-in-out forwards;
  }

  &.disappear {
    animation: ${disappearAnimation} 0.3s ease-in-out forwards;
  }
`;

export const MContext = createContext<ModalsContext>({
  isOpen: false,
  nodeIds: [],
  modalNodes: [],
  setModalNodes: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  onDismissAll: () => null,
  setCloseOnOverlayClick: () => true,
});

const ModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalNodes, setModalNodes] = useState<{ node: React.ReactNode; priority: number }[]>([]);
  const [nodeIds, setNodeIds] = useState<string[]>([]);
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true);

  const handlePresent = (node: React.ReactNode, newNodeId: string, priority = 0) => {
    setNodeIds((pre) => {
      const index = pre.indexOf(newNodeId);
      if (index !== -1) {
        setModalNodes((pre) => {
          pre.splice(index, 1, { node, priority });
          return pre;
        });
        return pre;
      }

      setModalNodes((pre) => [...pre, { node, priority }]);
      return [...pre, newNodeId];
    });
  };

  const handleDismiss = () => {
    setModalNodes((pre) => pre.slice(0, -1));
    setNodeIds((pre) => pre.slice(0, -1));
  };

  const handleDismissAll = () => {
    setModalNodes([]);
    setNodeIds([]);
  };

  useEffect(() => {
    setIsOpen(nodeIds.length > 0);
  }, [nodeIds]);

  const handleOverlayDismiss = () => {
    if (closeOnOverlayClick) {
      handleDismiss();
    }
  };

  return (
    <MContext.Provider
      value={{
        isOpen,
        nodeIds,
        modalNodes,
        setModalNodes,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        onDismissAll: handleDismissAll,
        setCloseOnOverlayClick,
      }}
    >
      <LazyMotion features={domAnimation}>
        <AnimatePresence>
          {modalNodes
            .sort((current, pre) => current.priority - pre.priority)
            .map((mn, index) => (
              <ModalWrapper
                key={nodeIds[index]}
                className="appear"
                {...animationMap}
                variants={animationVariants}
                transition={{ duration: 0.3 }}
              >
                <Overlay onClick={handleOverlayDismiss} />
                {React.isValidElement(mn.node) &&
                  React.cloneElement(mn.node, {
                    // @ts-ignore
                    onDismiss: handleDismiss,
                  })}
              </ModalWrapper>
            ))}
        </AnimatePresence>
      </LazyMotion>
      {children}
    </MContext.Provider>
  );
};

export default ModalProvider;
