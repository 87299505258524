import { useMiniPlayer } from 'state/player/hooks'
import { InteractionManager } from '@common/InteractionManager'
import { useStore } from '../../state'
import useTaskManager from '../useTaskManager'

const useEventMsg1010SyncGuide = () => {
  const [_, setMiniPlayer] = useMiniPlayer()
  const { getState } = useStore()
  const { finishTask } = useTaskManager()

  return async (event) => {
    const eventData: { guideIndex: number } = JSON.parse(event.data)
    console.info(`[服务器同步任务index=${eventData.guideIndex}]任务，完成${eventData.guideIndex - 1}`)
    if (InteractionManager.interactionMap.length < eventData.guideIndex) return
    await finishTask(eventData.guideIndex - 1)
    setMiniPlayer({
      ...getState().player.miniPlayer,
      ...{ profile: { ...getState().player.miniPlayer.profile, ...eventData } },
    })
  }
}

export default useEventMsg1010SyncGuide
