import { EventSourceContext } from 'Providers'
import { useContext } from 'react'
import useSWRImmutable from 'swr/immutable'
import { useDagenApi } from './useDagenApi'
import useWallet from './useWallet'

// combines the block timestamp with the user setting to give the deadline that should be used for any submitted transaction
export default function useUnreadStatus() {
  const { walletAddress } = useWallet()
  const dagenApi = useDagenApi()
  const { isConnect } = useContext(EventSourceContext)

  const { data: hasUnread, mutate } = useSWRImmutable(
    walletAddress && isConnect ? ['checkUnread', walletAddress] : null,
    async (url, walletAddress) => {
      const res = await dagenApi.get(url, { params: { walletAddress } })
      if (res.data.success) return res.data.result
      return false
    },
  )

  return { hasUnread, refresh: mutate }
}
