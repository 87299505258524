import { ResetCSS } from '@library/uikit'
import 'animate.css'
import BigNumber from 'bignumber.js'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import useEventSource from 'hooks/useEventSource'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import 'video-react/dist/video-react.css'
import { Blocklist } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import '../index.css'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  // usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  useSentryUser()
  return null
}

function MyApp(props: AppProps<{ initialReduxState: any; session: any }>) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="description" content="A Living Open World, CREATE, CHALLENGE, EARN" />
        <meta name="twitter:image" content="https://dagen.life/images/teams/profile-banner.png" />
        <meta name="twitter:description" content="A Living Open World, CREATE, CHALLENGE, EARN" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="dagen - A Web3 VITAVERSE GAME" />
        <meta name="theme-color" content="#000" />
        <title>dagen - A Web3 VITAVERSE GAME</title>
      </Head>
      <Providers session={pageProps.session} store={store}>
        <Blocklist>
          <GlobalHooks />
          <ResetCSS />
          <GlobalStyle />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-JTQ0EZ0ZWZ" />
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-JTQ0EZ0ZWZ');
          `,
        }}
      />
      <Script async src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js" />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment

  return (
    <ProductionErrorBoundary>
      <Menu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Menu>
      <ToastListener />
    </ProductionErrorBoundary>
  )
}

export default MyApp
