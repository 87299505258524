import { useTranslation } from '@library/localization'
import { CloseIcon, Flex, IconButton, Text } from '@library/uikit'
import useInterval from 'hooks/useInterval'
import { useEffect, useState } from 'react'
import { useAnnouncementBannerManager } from 'state/user/hooks'
import styled from 'styled-components'

const Container = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
  font-size: 10px;
  gap: 2px;
  zoom: 0.8;
  color: black;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
    gap: 5px;
    padding: 5px;
    zoom: 1;
  }
`

const AnnouncementBanner: React.FC = () => {
  const [, hideBanner, announcement, mutate] = useAnnouncementBannerManager()
  const contents = announcement.content ? announcement.content.split('<br />').filter((a) => !!a) : []
  const [counter, setCounter] = useState(0)
  const [content, setConent] = useState(contents[0])
  const [className, setClassName] = useState('')
  const { t, currentLanguage } = useTranslation()
  useInterval(
    () => {
      setClassName('')
      setTimeout(() => {
        setClassName('animate__animated animate__fadeIn')
        setCounter((counter + 1) % contents.length)
        setConent(contents[counter])
      }, 100)
    },
    5000,
    false,
  )

  useEffect(() => {
    mutate.mutate()
  }, [currentLanguage])

  return (
    <Container>
      <Text
        className={className}
        color="dark"
        pl={10}
        pr={30}
        dangerouslySetInnerHTML={{
          __html: `${content}`,
        }}
      />
      <IconButton style={{ position: 'absolute', right: 0 }} onClick={hideBanner} variant="text">
        <CloseIcon color="primary" />
      </IconButton>
    </Container>
  )
}

export default AnnouncementBanner
