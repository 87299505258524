import { languageList, useTranslation } from '@library/localization'
import { Flex, Menu as UikitMenu, Text, useMatchBreakpoints } from '@library/uikit'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import AnnouncementBanner from 'components/AnnouncementBanner'
import GCentBalance from 'components/GCentBalance'
import { NextLinkFromReactRouter } from 'components/NextLink'
import useTheme from 'hooks/useTheme'
import useWallet from 'hooks/useWallet'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { useAnnouncementBannerManager } from 'state/user/hooks'
import styled from 'styled-components'
import { isGamePage } from 'utils'
import { BOTTOM_NAV_HEIGHT } from 'views/Scenes/constants'
import { footerLinks } from './config/footerConfig'
import GameTitleBar, { ALL_NAVIGATION } from './GameTitleBar'
import UserMenu from './UserMenu'

const vConsoleAddresses = [
  '0x37d8B0123D013ad824B14D8e6a946da425B551CD',
  '0xd614482049F6326dF9aa4B8A8771a6D9aFE4C9f9',
  '0x23C9b93B1b73Ad6002D2e41f73D1d956dECdAD3B',
  '0xFf305dBbF500875f9b981092E8A827835CaCFff1',
  '0x8b1f1047cd4c084e1cc244afa09ab9f60961c685',
  '0xE4Bf471DB408d450228824e183BF05D910ABC786',
]

const BottomNav = styled(Flex)<{ isDesktop: boolean }>`
  z-index: 200;
  padding: 10px;
  flex-direction: row;
  position: fixed;
  justify-content: space-around;
  bottom: 0;
  height: 44px;
  width: 100%;
  height: ${BOTTOM_NAV_HEIGHT};
  background: #252934;
`
let vConsole

const Menu = (props) => {
  const { isDark } = useTheme()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { back, pathname, push } = useRouter()
  const { walletAddress } = useWallet()
  const [showAnnouncementBanner] = useAnnouncementBannerManager()
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints()
  const { nodeIds, onDismiss } = useContext(MContext)

  useEffect(() => {
    if (vConsoleAddresses.includes(walletAddress)) {
      // @ts-ignore
      if (!vConsole && window.VConsole) vConsole = new window.VConsole()
    }
  }, [walletAddress])

  return (
    <UikitMenu
      linkComponent={(linkProps) => {
        return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
      }}
      gameTitleBar={<GameTitleBar userMenu={<UserMenu />} />}
      userMenu={<UserMenu />}
      banner={showAnnouncementBanner && typeof window !== 'undefined' && <AnnouncementBanner />}
      nativeBalance={<GCentBalance />}
      isDark={isDark}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      showFooter={!isGamePage(pathname)}
      footerLinks={footerLinks(t)}
      bottomNav={
        !isDesktop ? (
          <BottomNav isDesktop={isDesktop}>
            {ALL_NAVIGATION.map((navigateItem, index) => {
              return (
                <>
                  {navigateItem.field === 'Back' ? (
                    <ClickFlex
                      key={`navigateItem_${index}`}
                      onContextMenu={(e) => e.preventDefault()}
                      className="navigateItem_container"
                      alignItems="center"
                      flexDirection="row"
                      onClick={() => {
                        if (nodeIds.length > 0) {
                          onDismiss()
                        } else {
                          back()
                        }
                      }}
                    >
                      <NextImage src={navigateItem.image} width={36} height={36} />
                    </ClickFlex>
                  ) : (
                    <ClickFlex
                      key={`navigateItem_${index}`}
                      onContextMenu={(e) => e.preventDefault()}
                      className="navigateItem_container"
                      alignItems="center"
                      flexDirection="row"
                      onClick={() => {
                        push(navigateItem.url)
                      }}
                    >
                      <NextImage src={navigateItem.image} width={24} height={24} />
                      <Text ml={2} textAlign="center" fontSize="12px" fontWeight={400} color={navigateItem.color}>
                        {t(navigateItem.field)}
                      </Text>
                    </ClickFlex>
                  )}
                </>
              )
            })}
          </BottomNav>
        ) : null
      }
      showDrawerButton={!!walletAddress}
      t={t}
      {...props}
    />
  )
}

export default Menu
