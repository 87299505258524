import BSCLogo from '@library/uikit/src/components/Svg/Icons/BSCLogo'
import ETHLogo from '@library/uikit/src/components/Svg/Icons/ETHLogo'
import PolygonLogo from '@library/uikit/src/components/Svg/Icons/PolygonLogo'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect } from 'react'
import { setupNetwork } from 'utils/wallet'
import Select from '../Select/Select'

export const MENU_ITEMS = (props?) => {
  if (process.env.NODE_ENV === 'production') {
    return [
      {
        value: 1,
        label: 'Ethereum',
        icon: <ETHLogo {...props} />,
      },
      {
        value: 56,
        label: 'Binance',
        icon: <BSCLogo {...props} />,
      },
      {
        value: 137,
        label: 'Polygon',
        icon: <PolygonLogo {...props} />,
      },
    ]
  }
  return [
    {
      value: 5,
      label: 'Goerli Testnet',
      icon: <ETHLogo {...props} />,
    },
    {
      value: 80001,
      label: 'Mumbai',
      icon: <PolygonLogo {...props} />,
    },
  ]
}

function Chains() {
  const { chainId } = useActiveWeb3React()
  let setSelected

  useEffect(() => {
    if (!chainId) return
    const newSelected = MENU_ITEMS().findIndex((item) => item.value === chainId)
    if (newSelected !== -1) setSelected(newSelected)
  }, [chainId, setSelected])

  const handleMenuClick = (e) => {
    setupNetwork(e.value)
  }

  const setSelectIndexFunc = (setSelectedOptionIndex) => {
    setSelected = setSelectedOptionIndex
  }

  return <Select setSelectIndexFunc={setSelectIndexFunc} options={MENU_ITEMS()} onOptionChange={handleMenuClick} />
}

export default Chains
