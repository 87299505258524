import {
  GREEN_LIGHT_F_GEN_TOKEN_ID,
  BLUE_LIGHT_F_GEN_TOKEN_ID,
  FIBER_MAKER_F_GEN_TOKEN_ID,
  CHITIN_MAKER_F_GEN_TOKEN_ID,
} from './constans'

export interface Interaction {
  dictId: number
  levelNeed: number
  reward?: {
    exp?: number
    gens?: string[]
    ep?: number
    fiber?: number
    chitin?: number
  }
}

/**
 * 对于字典数据一定要是只读的，多层引用嵌套也要想办法只读
 * 原因：字典数据是全局字典，改动一个数值，会影响所有玩家数据
 * 例如：当新手引导玩家A 修改为发放 gen 2个，后面所有的新玩家的新手奖励都将会获得gen 2个
 */
export class InteractionManager {
  // 交互 （key:交互id value:交互）
  static readonly interactionMap: Interaction[] = [
    // 从1开始 0没用
    {
      dictId: 0,
      levelNeed: 0,
    },
    {
      dictId: 1,
      levelNeed: 0,
    },
    {
      dictId: 2,
      levelNeed: 0,
    },
    {
      dictId: 3,
      levelNeed: 0,
      reward: { exp: 100, ep: 100, gens: [`${GREEN_LIGHT_F_GEN_TOKEN_ID}|1`, `${BLUE_LIGHT_F_GEN_TOKEN_ID}|1`] },
    },
    {
      dictId: 4,
      levelNeed: 0,
      reward: { exp: 100, ep: 100 },
    },
    {
      dictId: 5,
      levelNeed: 0,
      reward: { exp: 100, ep: 100 },
    },
    // 离线也没问题！
    {
      dictId: 6,
      levelNeed: 0,
    },
    // 点击【收取】按钮，收取生命体中的能量
    {
      dictId: 7,
      levelNeed: 0,
      reward: { exp: 100, ep: 100, gens: [`${FIBER_MAKER_F_GEN_TOKEN_ID}|1`, `${CHITIN_MAKER_F_GEN_TOKEN_ID}|1`] },
    },
    {
      dictId: 8,
      levelNeed: 0,
    },
    {
      dictId: 9,
      levelNeed: 0,
    },
    {
      dictId: 10,
      levelNeed: 0,
    },
  ]
}
