import { taskTypeMap } from '@common/constans'
import { ChangeItem } from '@common/types'
import { useTranslation } from '@library/localization'
import { Box, Flex, InjectedModalProps, ModalTaskBody, ModalTaskContainer, Text } from '@library/uikit'
import ClickFlex from '@library/uikit/src/components/Box/ClickFlex'
import noop from 'lodash/noop'
import NextImage from 'next/image'
import React from 'react'
import styled from 'styled-components'

export interface Attacked {
  dictId?: number
  type?: 'welcome' | 'accept' | 'finish' | 'reward' | 'fail'
  title?: string
  itemIcon?: string
  changeItem?: ChangeItem
  buttonDesc?: string
}

export interface AttackedProps extends InjectedModalProps, Attacked {
  callback?: () => void
}

const AttackedTitle = styled(Flex)<{ color: string }>`
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color ?? '#4553EC'};
  border-radius: 12px 12px 0 0;
  height: 52px;
  gap: 20px;
`

const AttackedContentDesc = styled(Flex)`
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  gap: 18px;
  min-height: 250px;
`

const AttackedButton = styled(ClickFlex)<{ color: string }>`
  width: 140px;
  height: 40px;
  margin-bottom: 20px;
  background-color: ${({ color }) => color ?? '#4553EC'};
  border-radius: 26px;
  align-items: center;
  justify-content: center;
`

export const AttackedModal: React.FC<AttackedProps> = ({ type, title, changeItem, buttonDesc, callback = noop }) => {
  const { t } = useTranslation()

  return (
    <ModalTaskContainer color="#B12020">
      <AttackedTitle color="#B12020">
        <NextImage src="/images/attack.svg" width={22} height={22} />

        <Text fontSize="20px" textAlign="center">
          {t(title)}
        </Text>
      </AttackedTitle>
      <ModalTaskBody flexDirection="column">
        <Text m={10}>{t("You've just been attacked by [Paramecia]")}</Text>
        <AttackedContentDesc mt={10}>
          <NextImage src="/images/attack/ParameciaAttack.png" width={560} height={220} />
          {changeItem.lifeForm ? (
            <Box p={10}>
              <Text>{t('The gnawing of [Paramecia] causes %num% energy loss', { num: changeItem.lifeForm.ep })}</Text>
            </Box>
          ) : (
            <Box p={10}>
              <Text>{t('Very lucky, nothing to lose')}</Text>
            </Box>
          )}
        </AttackedContentDesc>
        <AttackedButton
          color={taskTypeMap[type].color}
          onClick={() => {
            callback()
          }}
        >
          <Text fontSize="14px" bold>
            {buttonDesc || t(taskTypeMap[type].action)}
          </Text>
        </AttackedButton>
      </ModalTaskBody>
    </ModalTaskContainer>
  )
}
