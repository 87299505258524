import { useTranslation } from '@library/localization'
import { useRouter } from 'next/router'
import { BattleLogItem } from '../../../server/model'
import {
  AffecterLossedContent,
  AffecterWinedContent,
  AttackerDialogContent,
  UIBattleProps,
} from '../../views/Game/BattleRewardModals'
import useBattleManager from '../game/useBattleManager'
import useWallet from '../useWallet'

const useEventMsg1013SyncBattleFinish = () => {
  const { showBattleReward } = useBattleManager()
  const { walletAddress } = useWallet()
  const { t } = useTranslation()
  const router = useRouter()

  return async (event) => {
    const eventData: BattleLogItem = JSON.parse(event.data)
    const isAttacker = walletAddress.toLowerCase() === eventData.attacker.toLowerCase()
    const uiBattleProps: UIBattleProps = {} as any
    const isWin = eventData.isWin >= 0

    const stealItemList = eventData.steal.map((item) => {
      if (item.item.itemNum > 0 && item.item.itemId !== 'hp') {
        return item.item
      }
      return undefined
    })

    const affecterLossHpItem = eventData.steal.find((item) => item.item.itemId === 'hp')

    const lossItemList = eventData.reward.map((item) => {
      if (item.item.itemNum < 0) {
        return item.item
      }
      return undefined
    })

    const rewardItemList = eventData.reward.map((item) => {
      const deleteItem = eventData.steal.find((stealItem) => stealItem.item.itemId === item.item.itemId)

      if (item.item.itemNum > 0 && !deleteItem) {
        return item.item
      }
      return undefined
    })

    console.info('战斗结算', eventData)

    if (isAttacker) {
      uiBattleProps.type = isWin ? 'victory' : 'loss'
      uiBattleProps.title = isWin
        ? { key: 'You have sucessfully sneak attacked %name%', params: { name: eventData.affecterName } }
        : { key: 'You sneak attacked %name%. Bad luck, you failed', params: { name: eventData.affecterName } }

      uiBattleProps.content = (
        <AttackerDialogContent
          lossItemList={lossItemList.filter((item) => item)}
          rewardItemList={rewardItemList.filter((item) => item)}
          stealItemList={stealItemList.filter((item) => item)}
        />
      )
    } else {
      const stealList = stealItemList.filter((item) => item)
      uiBattleProps.type = !isWin ? 'victoryed' : 'lossed'
      uiBattleProps.title = !isWin
        ? { key: 'you have been attacked %name%', params: { name: eventData.attackerName } }
        : stealList.length > 0
        ? { key: '%name% steal you', params: { name: eventData.attackerName } }
        : { key: 'you have been attacked %name%', params: { name: eventData.attackerName } }

      uiBattleProps.content = !isWin ? (
        <AffecterWinedContent
          desc={`${t('punish %name%', { name: eventData.attackerName })}`}
          callback={() => router.push(`/profile/${eventData.attacker}`)}
        />
      ) : (
        <AffecterLossedContent stealItemList={stealList} hpNum={affecterLossHpItem?.item?.itemNum ?? 0} />
      )
      uiBattleProps.contentBottom = !isWin
    }

    showBattleReward(uiBattleProps.type, uiBattleProps.title, uiBattleProps.content, uiBattleProps.contentBottom)
  }
}

export default useEventMsg1013SyncBattleFinish
