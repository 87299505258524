import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="51 51 410 410" style={{ borderRadius: 8 }} {...props}>
      <rect x="51" y="51" width="410" height="410" fill="#6286ff" />
      <path
        d="M395.42,174.27a13,13,0,0,1-16.71,13.82L301,164.36a13,13,0,0,0-16.44,9.4L265.05,255.6a13,13,0,0,1-18.4,8.62L51,167.47v161L320.11,405a13,13,0,0,0,16.55-12.44l.67-159.34a13,13,0,0,1,17.52-12.12l58.72,21.84a13,13,0,0,0,17.3-14.56L397.72,51H382.06Z"
        fill="#fff"
      />
    </Svg>
  );
};

export default Icon;
