import { EmailBoxIcon, GoogleIcon, TwitterIcon } from "../../components/Svg";
import Metamask from "../../components/Svg/Icons/Metamask";
import Wallet3 from "../../components/Svg/Icons/Wallet3";

import { Config, ConnectorNames } from "./types";

export const wechatConnectors: Config[] = [
  {
    title: "Wallet3",
    icon: Wallet3,
    connectorId: ConnectorNames.Wallet3,
    priority: 1,
  },
  {
    title: "Email",
    icon: EmailBoxIcon,
    connectorId: ConnectorNames.EMAIL,
    priority: 3,
  },
];

export const web2Connectors: Config[] = [
  {
    title: "Email",
    icon: EmailBoxIcon,
    connectorId: ConnectorNames.EMAIL,
    priority: 3,
  },
  {
    title: "Twitter",
    icon: TwitterIcon,
    connectorId: ConnectorNames.Twitter,
    priority: 3,
  },
  {
    title: "Google",
    icon: GoogleIcon,
    connectorId: ConnectorNames.Google,
    priority: 3,
  },
];

const connectors: Config[] = [
  {
    title: "Wallet3",
    icon: Wallet3,
    connectorId: ConnectorNames.Wallet3,
    priority: 1,
  },
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    priority: 2,
  },
  {
    title: "Email",
    icon: EmailBoxIcon,
    connectorId: ConnectorNames.EMAIL,
    priority: 3,
  },
  {
    title: "Twitter",
    icon: TwitterIcon,
    connectorId: ConnectorNames.Twitter,
    priority: 3,
  },
  {
    title: "Google",
    icon: GoogleIcon,
    connectorId: ConnectorNames.Google,
    priority: 3,
  },
];

export default connectors;
export const connectorLocalStorageKey = "connectorIdv2";
export const walletLocalStorageKey = "wallet";
