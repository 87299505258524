import { ContextApi } from '@library/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'dagen',
  description: 'A Living Open World, CREATE, CHALLENGE, EARN',
  image: 'https://dagen.life/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/profile')) {
    basePath = '/profile'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: t('dagen - A Web3 VITAVERSE GAME'),
      }
    case '/igo':
      return {
        title: `${t('Initial Gen Offering')} | ${t('dagen')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('dagen')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('dagen')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('dagen')}`,
      }
    case '/gens':
      return {
        title: `${t('Overview')} | ${t('dagen')}`,
      }
    case '/profile':
      return {
        title: `${t('Profile')} | ${t('dagen')}`,
      }
    default:
      return null
  }
}
