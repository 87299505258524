import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import noop from 'lodash/noop'
import { ReactNode, useContext } from 'react'
import { BATTLE_RESULT_PRIORITY } from '@common/constans'
import { BattleRewardModals } from '../../views/Game/BattleRewardModals'
import { BattleRecordModals } from '../../views/Game/BattleRecordModals'

export default function useBattleManager() {
  const { isOpen, onPresent, onDismiss } = useContext(MContext)
  const showBattleReward = (
    type,
    title: { key: string; params: { name: string } },
    content: ReactNode,
    contentBottom = false,
    callback = noop,
  ) => {
    return onPresent(
      <BattleRewardModals
        contentBottom={contentBottom}
        type={type}
        title={title}
        content={content}
        callback={() => {
          callback()
          onDismiss()
        }}
      />,
      `showBattleReward_${type}`,
      BATTLE_RESULT_PRIORITY,
    )
  }

  const showBattleRecord = (stealNum, reward, loss, recordItems) => {
    return onPresent(
      <BattleRecordModals stealNum={stealNum} reward={reward} loss={loss} recordItems={recordItems} />,
      `showBattleRecord`,
    )
  }

  return { showBattleRecord, showBattleReward, onDismiss, isOpen }
}
