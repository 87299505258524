import throttle from "lodash/throttle";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import "react-modern-drawer/dist/index.css";
import { useTranslation } from "@library/localization";
import styled from "styled-components";
import { Box, Flex } from "../../components/Box";
import Footer from "../../components/Footer";
import { HOME_MENU_HEIGHT, HOME_MENU_HEIGHT_MOBILE, MOBILE_MENU_HEIGHT } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import { useMatchBreakpoints } from "../../contexts";
import ClickFlex from "../../components/Box/ClickFlex";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledNav = styled.nav<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? `${HOME_MENU_HEIGHT_MOBILE}px` : `${HOME_MENU_HEIGHT}px`)};
  padding: 8px;
  position: relative;
`;

const FixedContainer = styled.div<{ drawerOpen: boolean; showMenu: boolean; height: number }>`
  position: fixed;
  height: ${({ height }) => `${height}px`};
  right: 0;
  width: 100%;
  z-index: 20;
`;

const BodyWrapper = styled(Box)<{ isTablet: boolean; isMobile: boolean; drawerOpen: boolean }>`
  position: relative;
  display: flex;
`;

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 100%;
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  userMenu,
  banner,
  gameTitleBar,
  isDark,
  currentLang,
  setLang,
  showFooter,
  footerLinks,
  showDrawerButton,
  bottomNav,
  langs,
  children,
  t,
}) => {
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints();
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);
  const router = useRouter();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { setLanguage, currentLanguage } = useTranslation();
  const { code: languageCode } = currentLanguage;

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const totalTopMenuHeight = banner ? HOME_MENU_HEIGHT : HOME_MENU_HEIGHT;
  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  useEffect(() => {
    document.documentElement.removeAttribute("local-lang");
    document.documentElement.setAttribute("local-lang", languageCode);
  }, [languageCode]);

  return (
    <MenuContext.Provider value={{ linkComponent, drawerOpen }}>
      <Wrapper>
        <FixedContainer drawerOpen={drawerOpen} showMenu={showMenu} height={totalTopMenuHeight}>
          {gameTitleBar}
        </FixedContainer>

        <BodyWrapper isMobile={isMobile} isTablet={isTablet} drawerOpen={drawerOpen}>
          <Inner isPushed={false} showMenu={showMenu}>
            {children}
            {showFooter && (
              <Footer
                items={footerLinks}
                isDark={isDark}
                langs={langs}
                setLang={setLang}
                currentLang={currentLang}
                mb={[`${MOBILE_MENU_HEIGHT}px`, null, "0px"]}
              />
            )}
          </Inner>
        </BodyWrapper>
        {bottomNav}
      </Wrapper>
    </MenuContext.Provider>
  );
};

export default Menu;
