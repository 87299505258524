import { ReactNode } from 'react'
import useToast from '../useToast'

export default function useCustomToastManager() {
  const { toastCustom } = useToast()

  const showToastCustom = (customContent?: ReactNode) => {
    toastCustom('', '', 'center', '', customContent, true)
  }

  return { showToastCustom }
}
