import { IMiniPlayer } from '@common/types'
import { useStore } from 'state'
import { useAbsorbingChangeItems, useMiniPlayer, useOthersChangeItems } from 'state/player/hooks'

const useEventMsg1008SyncPlayer = () => {
  const { getState } = useStore()
  const [_, setAbsorbingChangeItems] = useAbsorbingChangeItems()
  const [___, setOthersChangeItems] = useOthersChangeItems()
  const [_____, setMiniPlayer] = useMiniPlayer()

  return (event) => {
    const player: IMiniPlayer = JSON.parse(event.data)
    const current = getState().player.miniPlayer
    setAbsorbingChangeItems([])
    setOthersChangeItems([])
    setMiniPlayer({ ...current, ...player })
  }
}

export default useEventMsg1008SyncPlayer
