import { LanguageProvider } from '@library/localization'
import { dark, ModalProvider, UIKitProvider } from '@library/uikit'
import { Store } from '@reduxjs/toolkit'
import { Web3ReactProvider } from '@web3-react/core'
import { ToastsProvider } from 'contexts/ToastsContext'
import useEventSource from 'hooks/useEventSource'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { ThemeProvider as NextThemeProvider } from 'next-themes'
import { createContext } from 'react'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { getLibrary } from 'utils/web3React'

const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({ children, ...props }) => {
  return (
    <UIKitProvider theme={dark} {...props}>
      {children}
    </UIKitProvider>
  )
}

export const EventSourceContext = createContext<{ evtSource; isConnect }>(undefined)

export const EventSourceProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { evtSource, isConnect } = useEventSource()

  return <EventSourceContext.Provider value={{ evtSource, isConnect }}>{children}</EventSourceContext.Provider>
}

const Providers: React.FC<React.PropsWithChildren<{ session: Session | null; store: Store }>> = ({
  children,
  session,
  store,
}) => {
  return (
    <SessionProvider session={session}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <ToastsProvider>
            <NextThemeProvider>
              <StyledUIKitProvider>
                <LanguageProvider>
                  <SWRConfig
                    value={{
                      use: [fetchStatusMiddleware],
                    }}
                  >
                    <ModalProvider>
                      <EventSourceProvider>{children}</EventSourceProvider>
                    </ModalProvider>
                  </SWRConfig>
                </LanguageProvider>
              </StyledUIKitProvider>
            </NextThemeProvider>
          </ToastsProvider>
        </Provider>
      </Web3ReactProvider>
    </SessionProvider>
  )
}

export default Providers
