import { IMaterial, TaskTypeMap } from './types'

export const ETH_ADDRESS_CHECK_MESSAGE = 'Must be etheruem address'
export const ORDER_TIMEOUT = 30 * 60 * 1000
export const BLINDBOX_PRICE = '8900'
export const WITHDRAW_PRICE = 200

export const MESSAGE = `Welcome to dagen!

  Click to sign in and accept the dagen Terms of Service: https://dagen.life
  
  This request will not trigger a blockchain transaction or cost any gas fees.`

export const GCENT_MARKET_FEE = 250
export const HUNDRED_PERCENT = 10_000

export const GCENT_MARKET_FEE_PERCENTAGE = GCENT_MARKET_FEE / HUNDRED_PERCENT

export const COLLECT_ENERGY_DELAY = 5000
export const COLLECT_MAX_DAY = 1000
export const ONLINE_ACCELERATE = 30

export const STRENGTH_REFRESH_TIME = 60 * 60 * 1000

export const STRENGTH_ONCE_COUNT = 60
export const MAX_STRENGTH = 1200

export const HP_REFRESH_TIME = 2 * 1000
export const HP_ONCE_COUNT = 1

export const BATTLE_WAIT_TIME = 22 * 1000

export const BATTLE_MAX_ROUND = 3

export const ONE_DAY_TIME = 20 * 60 * 60 * 1000

export const ONLINE = 1
export const OFFLINE = 2
export const FUSION_FEE = 0.025

// =======================================协议开始===================================
export const LOGOUT = '-1'
export const LOGIN = '1000'
export const SCENE_EVENT = '1001'
export const PLAYER_ONLINE = '1002'
export const PLAYER_OFFLINE = '1003'
export const CROSS_DAYS = '1004'
export const TASK = '1005'
// 玩家面板数值

export const CHANGE_ITEM = '1006'

export const WEATHER_CHANGE = '1007'

export const SYNC_PLAYER = '1008'

export const CHANGE_ITEM_PROFILE = '1009'

export const SYNC_GUIDE = '1010'

export const SYNC_TIME = '1011'

export const SYNC_BARRAGE = '1012'

// 战斗结算：攻击者/被攻击
export const BATTLE_FINISH = '1013'

export const CHANGE_FINALLY_ITEM = '1014'

export const PLAYER_LEVEL_UP = '1015'

export const FUSION_ORDER_FINISH = '1016'

export const CHAT_MSG = '1017'

export const APPLY_FRIEND = '1018' // 对方操作申请列表

export const DELETE_FRIEND = '1019' // 对方操作删除自己

export const ADD_FRIEND = '1020' // 通知对方我想添加他为好友

// =======================================协议结束===================================

// =======================================任务事件开始===================================

export const INSTALL_GEN = '1000'
export const COLLECT_ENERGY_SUN = '1001'
export const COLLECT_ENERGY = '1002'
export const REWARD = '1003'
export const LEVEL_UP = '1004'

export const LOG_LOGIN = 'log_login'
export const LOG_LOGOUT = 'log_logout'
export const LOG_LEVE_LUP = 'level_up'
export const LOG_ITEM = 'item'
export const LOG_GET_TASK = 'get_task'
export const LOG_FINISH_TASK = 'finish_task'
export const LOG_COMMUNITY= 'log_community'

// =======================================任务事件结束===================================

export const ALGAE_P_GEN_TOKEN_ID = '11'
export const GREEN_LIGHT_F_GEN_TOKEN_ID = '12'
export const BLUE_LIGHT_F_GEN_TOKEN_ID = '13'
export const FIBER_MAKER_F_GEN_TOKEN_ID = '14'
export const CHITIN_MAKER_F_GEN_TOKEN_ID = '15'

export const BATTLE_PRIORITY = 100
export const BATTLE_RESULT_PRIORITY = 99
export const LEVEL_PRIORITY = -1

interface ICostListItem {
  image: string
  unit: string
}

export const AssetsInfo: { [key: string]: ICostListItem } = {
  ep: { image: '/images/game/icon/energy.svg', unit: 'e' },
  fiber: { image: '/images/034-leaf-6.svg', unit: 'f' },
  chitin: { image: '/images/034-leaf-2.svg', unit: 'c' },
}

// TODO 需要写到i18n
export const genFunList = {
  redLightAbsorption: '红光',
  yellowLightAbsorption: '黄光',
  greenLightAbsorption: '绿光',
  blueLightAbsorption: '蓝光',
  violetLightAbsorption: '紫光',
  thermalEnergyAbsorption: '热能',
  chemicalEnergyAbsorption: '化学能',
  produceFiber: '纤维',
  swim: 'swim',
}

export const DEFAULT_COLOR = '#6F1616'

export const genLightFunBgList = {
  redLightAbsorption: ['#FF2626', '#6F1616'],
  yellowLightAbsorption: ['#FFBD5A', '#6B4409'],
  greenLightAbsorption: ['#89BD00', '#3A5208'],
  blueLightAbsorption: ['#5865F2', '#1D28A5'],
  violetLightAbsorption: ['#7000FF', '#500887'],

  redLight: ['#FF2626', '#6F1616'],
  yellowLight: ['#FFBD5A', '#6B4409'],
  greenLight: ['#89BD00', '#3A5208'],
  blueLight: ['#5865F2', '#1D28A5'],
  violetLight: ['#7000FF', '#500887'],
}

export const genLightFunDesc = {
  redLightAbsorption: '红色光谱',
  yellowLightAbsorption: '黄色光谱',
  greenLightAbsorption: '绿色光谱',
  blueLightAbsorption: '蓝色光谱',
  violetLightAbsorption: '紫色光谱',

  redLight: '红色光谱',
  yellowLight: '黄色光谱',
  greenLight: '绿色光谱',
  blueLight: '蓝色光谱',
  violetLight: '紫色光谱',
}

export const genMaterialBgList = {
  thermalEnergy: ['#FFBD5A', 'rgba(255, 255, 255, 0.5)'],
  chemicalEnergy: ['#FFBD5A', 'rgba(255, 255, 255, 0.5)'],
  fiber: ['#E4BC53', '#58481F'],
  chitin: ['#DA975A', '#634932'],
  swim: ['#FFBD5A', 'rgba(255, 255, 255, 0.5)'],
}

export const customAttributeFun = [
  'redLightAbsorption',
  'yellowLightAbsorption',
  'greenLightAbsorption',
  'blueLightAbsorption',
  'violetLightAbsorption',
]

export const materialList: IMaterial[] = [
  { field: 'fiber', maxFiled: 'maxFiber', name: 'produceFiber' },
  { field: 'chitin', maxFiled: 'maxChitin', name: 'produceChitin' },
]

export const taskTypeMap: { [key: string]: TaskTypeMap } = {
  welcome: { icon: 'welcome.svg', desc: 'Welcome', action: 'Continue', color: '#4553EC' },
  accept: { icon: 'accept.svg', desc: 'Task', action: 'Received', color: '#4553EC' },
  finish: { icon: 'finish.svg', desc: 'Task Finished', action: 'Receive award', color: '#7BAA00' },
  reward: { icon: '', desc: 'Received', action: 'Receive award', color: '#E1A200' },
  fail: { icon: 'fail.svg', desc: '任务失败', action: '确定', color: '#B12020' },
  victory: { icon: 'victory.svg', desc: 'steal victory', action: 'Share', color: '#7BAA00' },
  loss: { icon: 'loss.svg', desc: 'steal failed', action: 'help me', color: '#B12020' },
  victoryed: { icon: 'victory.svg', desc: 'meet steal', action: 'scoff him', color: '#B12020' },
  lossed: { icon: 'loss.svg', desc: 'meet steal', action: 'help me', color: '#B12020' },
}

export const imageList: {
  image: string
  buttonColor: string
  buttonDesc: string
  isFirst?: boolean
  upTitle?: string
  downTitle?: string
}[] = [
  {
    isFirst: true,
    buttonDesc: 'start_new',
    buttonColor: '#4553EC',
    image: '/images/game/home/home_item_bg_1.png',
  },
  {
    buttonDesc: 'start_new',
    buttonColor: '#4FB400',
    upTitle: 'creat lives',
    downTitle: 'grab energy',
    image: '/images/game/home/home_item_bg_2.png',
  },
  {
    buttonDesc: 'start_new',
    buttonColor: '#FF3D00',
    upTitle: 'level up',
    downTitle: 'accumulate assets',
    image: '/images/game/home/home_item_bg_3.png',
  },
  {
    buttonDesc: 'start_new',
    buttonColor: '#6500E4',
    upTitle: 'have fun',
    downTitle: 'win fortune',
    image: '/images/game/home/home_item_bg_4.png',
  },
]

export const homeImgInfoList: { image: string; btnDesc?: string; title?: string; content?: string }[] = [
  {
    image: '/images/game/home/home_item_bg_1.png',
    btnDesc: 'homeCard1',
  },
  {
    image: '/images/game/home/home_item_bg_2.png',
    title: 'homeCard2',
    content: 'homeCard3',
  },
  {
    image: '/images/game/home/home_item_bg_3.png',
    title: 'homeCard4',
    content: 'homeCard5',
  },
  {
    image: '/images/game/home/home_item_bg_4.png',
    title: 'homeCard6',
    content: 'homeCard7',
  },
]

export const formatDagenTime = (time) => {
  let hour = String(Math.floor((Date.now() / 1000 / 60 / 60) % 20))
  let minute = String(new Date(time).getMinutes())

  if (Number(minute) < 10) {
    minute = `0${minute}`
  }
  if (Number(hour) < 10) {
    hour = `0${hour}`
  }
  return `${hour}:${minute}`
}

export const DAGEN_BACKEND = process.env.NEXT_PUBLIC_DAGEN_BACKEND
export const DAGEN_DOMAIN = process.env.NEXT_PUBLIC_DAGEN_DOMAIN
