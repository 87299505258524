import { ChangeItemEvent } from '@common/types'
import useAttackedManager from 'hooks/useAttackedManager'
import { useAbsorbingChangeItems, useEnergySuns, useOthersChangeItems } from 'state/player/hooks'
import { useStore } from '../../state'

const useEventMsg1006ChangeItem = () => {
  const { getState } = useStore()
  const [_, setAbsorbingChangeItems] = useAbsorbingChangeItems()
  const [__, setEnergySunChangeItems] = useEnergySuns()
  const [___, setOthersChangeItems] = useOthersChangeItems()
  const { showAttacked } = useAttackedManager()

  return (event) => {
    const eventData: ChangeItemEvent = JSON.parse(event.data)
    if (eventData.eventName === 'Absorbing') {
      setAbsorbingChangeItems([...getState().player.absorbingChangeItems, eventData])
    } else if (eventData.eventName === 'EnergySun') {
      setEnergySunChangeItems([...getState().player.energySuns, ...eventData.energySuns])
    } else if (eventData.eventName === 'ParameciaAttack') {
      showAttacked('ParameciaAttack', eventData)
      setOthersChangeItems([...getState().player.othersChangeItems, eventData])
    } else {
      setOthersChangeItems([...getState().player.othersChangeItems, eventData])
    }
  }
}

export default useEventMsg1006ChangeItem
