import React from "react";
import useCustomToastManager from "../game/useCustomToastManager";
import FriendAgreeModal from "../../views/Game/FriendAgreeModal";

/**
 * 对方是否通过了好友请求
 */
const useEventMsg1018ApplyFriend = () => {
  const { showToastCustom } = useCustomToastManager()

  return async (event) => {
    const { nickName, agree } = JSON.parse(event.data)
    showToastCustom(<FriendAgreeModal nickName={nickName} agree={agree} />)
  }
}

export default useEventMsg1018ApplyFriend
