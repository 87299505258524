import { InteractionManager } from '@common/InteractionManager'
import { useTranslation } from '@library/localization'
import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import { useContext } from 'react'
import { Task, TaskModal } from 'views/Game/TaskModals'
import { useDagenApi } from './useDagenApi'
import useRewardManager from './useRewardManager'
import useToast from './useToast'

export default function useTaskManager() {
  const { isOpen, onPresent, onDismiss } = useContext(MContext)
  const { toastWarning } = useToast()
  const dagenApi = useDagenApi()
  const { t } = useTranslation()
  const { showGenReward } = useRewardManager()

  // 请求服务器完成指定任务
  const finishGuide = async (guideIndex: number) => {
    const res = await dagenApi.post('interactionProcessor', { interactionId: guideIndex })
    if (!res.data.success) {
      toastWarning(res.data.result)
    }
  }

  // 玩家接受任务，引导任务自动完成
  const showTask = (guideIndex: number, isCheck = false) => {
    if (tasks[guideIndex]) {
      onPresent(
        <TaskModal
          type={tasks[guideIndex].type}
          title={tasks[guideIndex].title}
          content={tasks[guideIndex].content}
          buttonDesc={isCheck ? t('OK') : tasks[guideIndex].buttonDesc}
          callback={async () => {
            onDismiss()

            if (tasks[guideIndex].type === 'welcome') {
              // 引导任务点击之后自动完成
              setTimeout(async () => {
                await finishGuide(guideIndex)
              }, 500)
            }
          }}
        />,
        tasks[guideIndex].title,
      )
    }
  }

  const startNextTask = async (currentGuideIndex) => {
    // TODO 应该查表的下个任务触发id
    const nextIndex = currentGuideIndex + 1
    // 自动挂载下一个任务
    if (InteractionManager.interactionMap.length > nextIndex) {
      showTask(nextIndex)
    }
  }

  // 只发奖励不推进下一个任务，当服务器完成任务校验之后返回，当前任务完成，由协议返回调用。
  // 显示完成的任务，在显示该任务的奖励。其他任务怎么挂上？ 协议成功返回，自动推进任务
  // 没有奖励不需要完成直接下一个任务
  const finishTask = async (guideIndex: number) => {
    const interactionDict = InteractionManager.interactionMap[guideIndex]
    if (!interactionDict.reward) {
      await startNextTask(guideIndex)
      return
    }
    onPresent(
      <TaskModal
        type="finish"
        title={tasks[guideIndex].title}
        content={tasks[guideIndex].finishContent || tasks[guideIndex].content}
        callback={async () => {
          onDismiss()

          await getRewardAndPushNextTask(guideIndex)
        }}
      />,
      `${tasks[guideIndex].title}_finish`,
    )
  }

  // 结算当前任务后开始推进到下个任务
  const getRewardAndPushNextTask = async (guideIndex: number) => {
    const { reward } = InteractionManager.interactionMap[guideIndex]
    if (reward && reward.gens) {
      for (let index = 0; index < reward.gens.length; index++) {
        const [tokenId] = reward.gens[index].split('|')
        const res = await dagenApi.get('genGameAttribute', { params: { nftId: tokenId } })
        const genGameAttribute = res.data.result
        const gen = genGameAttribute.fgen || genGameAttribute.pgen

        showGenReward(gen)
      }
    }

    await startNextTask(guideIndex)
  }

  const tasks: Task[] = [
    {
      dictId: 0,
      type: 'welcome',
      title: 'empty',
      content: {
        image: 'empty',
        descs: ['empty'],
      },
    },
    {
      dictId: 1,
      type: 'welcome',
      title: 'task_title_1',
      content: {
        image: '/images/tasks/logo.png',
        descs: ['task_content_1'],
      },
    },
    {
      dictId: 2,
      type: 'welcome',
      title: 'task_title_2',
      content: {
        image: '/images/tasks/0.png',
        descs: ['task_content_2_1'],
      },
    },
    {
      dictId: 3,
      type: 'accept',
      title: 'task_title_3',
      content: {
        image: '/images/tasks/1.png',
        descs: ['task_content_3_1'],
        reward: 'task_content_3_2',
      },
    },
    {
      dictId: 4,
      type: 'accept',
      title: 'task_title_4',
      content: {
        image: '/images/tasks/0.png',
        descs: ['task_content_4_1'],
        reward: 'task_content_4_2',
      },
      finishContent: {
        image: '/images/tasks/2.png',
        descs: ['task_content_4_1', 'task_finish_4_2'],
        reward: 'task_content_4_2',
      },
    },
    {
      dictId: 5,
      type: 'accept',
      title: 'task_title_5',
      content: {
        image: '/images/tasks/1.png',
        descs: ['task_content_5_1'],
        reward: 'task_content_5_2',
      },
    },
    {
      dictId: 6,
      type: 'welcome',
      title: 'task_title_6',
      content: {
        image: '/images/tasks/0.png',
        descs: ['task_content_6_1'],
        reward: 'task_content_6_2',
      },
    },
    {
      dictId: 7,
      type: 'accept',
      title: 'task_title_7',
      content: {
        image: '/images/tasks/0.png',
        descs: ['task_content_7_1'],
        reward: 'task_content_7_2',
      },
      buttonDesc: 'OK',
    },
    // {
    //   dictId: 8,
    //   type: 'welcome',
    //   title: 'task_title_8',
    //   content: {
    //     image: '/images/tasks/0.png',
    //     descs: ['task_content_8_1', 'task_content_8_2'],
    //   },
    //   buttonDesc: 'OK',
    // },
    // {
    //   dictId: 9,
    //   type: 'welcome',
    //   title: 'task_title_9',
    //   content: {
    //     image: '/images/tasks/0.png',
    //     descs: ['task_content_9_1', 'task_content_9_2'],
    //   },
    //   buttonDesc: 'OK',
    // },
    // {
    //   dictId: 10,
    //   type: 'welcome',
    //   title: 'task_title_10',
    //   content: {
    //     image: '/images/tasks/0.png',
    //     descs: ['task_content_10_1', 'task_content_10_2'],
    //   },
    //   buttonDesc: 'OK',
    // },
  ]

  return { tasks, showTask, finishTask, onDismiss, isOpen }
}
