import React from "react";
import styled from "styled-components";
import { DropdownProps, PositionProps, Position } from "./types";

const getLeft = ({ position }: PositionProps) => {
  if (position === "top-right") {
    return "100%";
  }
  return "50%";
};

const getBottom = ({ position }: PositionProps) => {
  if (position === "top" || position === "top-right") {
    return "100%";
  }
  return "auto";
};

const DropdownContent = styled.div<{ position: Position }>`
  width: max-content;
  display: none;
  flex-direction: column;
  position: absolute;
  transform: translate(-50%, 0);
  left: ${getLeft};
  bottom: ${getBottom};
  background-color: ${({ theme }) => theme.nav.background};
  box-shadow: ${({ theme }) => theme.shadows.level1};
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  border-radius: ${({ theme }) => theme.radii.small};
`;

const Container = styled.div<{ selected: boolean }>`
  position: relative;
  ${DropdownContent} {
    display: ${({ selected }) => (selected ? "flex" : "none")};
  }
`;

const Dropdown: React.FC<React.PropsWithChildren<DropdownProps>> = ({
  target,
  selected,
  position = "bottom",
  children,
}) => {
  return (
    <Container selected={selected}>
      {target}
      <DropdownContent position={position}>{children}</DropdownContent>
    </Container>
  );
};

export default Dropdown;
