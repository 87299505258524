export default {
  chainIds: [1, 5],
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  market: {
    1: '0x0660Bc062B72996f70EaCC52B7C1Cd89718fc81D',
    5: '0xBD13FF1eC8Da99a1A969bD10127ce8eeB20403Ea',
  },
  items: {
    1: '0x27f06e76D24D08fb50114ea18955a0146E7E3A7D',
    5: '0x6F833a665C0e070Bc21Aae68B1003A595FcBd0d6',
  },
  dagenAvatar: {
    1: '0x846e53b6f008a3abfdb06302c9c862f32499758f',
    5: '0x4240292282ee8f6dF71f1de54edCf4A2099cC258',
  },
  blindBox: {
    1: '0xD901BAC478017fdEAbbd9f14CB6bc6295242EC26',
    5: '0x9cc5fF7ACDd3814134eF7b36D9AC9EE20ec45259',
  },
  badge: {
    1: '0x635a6eD40126743bC27efDF6a5ce3ccEA4F6dCA6',
    5: '0x9B27710361d4a5a10ca956044D032a224fCC861a',
  },
  badgeBlindBox: {
    1: '0xcfB002931482EdC9B6AbdCe42ac311DB88B8B6Dd',
    5: '0xC458Ad62dCe3E5041A5eE71E87198E555F2978Ce',
  },
  lifeDAO: {
    1: '0x181D4D65045d371Cd84aF8C2BBf3Affd8dA56477',
    5: '0x920814f7F56A25c7e912bA4748FcfF176fe6446D',
  },
}
