// eslint-disable-next-line lodash/import-scope
import cloneDeep from 'lodash/cloneDeep'
import { useRouter } from 'next/router'
import { useStore } from 'state'
import { useMiniPlayer, useSystemInfo } from 'state/player/hooks'
import { BattleOfflineRecord, IMiniPlayer, MaterialFull } from '@common/types'
import { SceneEvent } from '../../../server/model'
import useRewardManager from '../useRewardManager'
import { useDagenApi } from '../useDagenApi'
import useBattleManager from '../game/useBattleManager'

const useEventMsg1000Login = () => {
  const [_, setMiniPlayer] = useMiniPlayer()
  const [__, setSystemInfo] = useSystemInfo()
  const { getState } = useStore()
  const { showReward } = useRewardManager()
  const { showBattleRecord } = useBattleManager()
  const router = useRouter()
  const dagenApi = useDagenApi()
  let loopNum = 10
  let timer
  return (event) => {
    const player: IMiniPlayer & {
      reward: any
      events: SceneEvent[]
      battleRecord: BattleOfflineRecord
      materialFull: MaterialFull
    } = JSON.parse(event.data)
    const materialFull = cloneDeep(player.materialFull)
    const reward = cloneDeep(player.reward)
    const events = cloneDeep(player.events)
    const battleRecord = cloneDeep(player.battleRecord)
    delete player.reward
    delete player.events
    delete player.battleRecord
    setMiniPlayer(player)
    setSystemInfo({ onlineCount: 1, sceneEvents: [] })
    console.info('显示战斗日志', battleRecord)
    timer = setInterval(async () => {
      const res = await dagenApi.post('heart', { address: player.profile.walletAddress })
      if (!res.data.success) {
        loopNum--
        if (!loopNum) clearInterval(timer)
      }
    }, 5000)

    if (battleRecord) {
      setSystemInfo({
        ...getState().player.systemInfo,
        battleRecord: { ...battleRecord },
      })
    }

    // 去选角色 已经通过服务器同步过了数据，已经登陆
    if (router.pathname === '/' || router.pathname === '/scenes' || router.pathname === '/scenes') {
      if (player.profile.guideIndex < 1) {
        router.replace('/game')
        return
      }

      // 去场景
      if (player.profile.guideIndex >= 1) {
        router.replace('/scenes')
      }
    }

    if (player?.profile?.guideIndex >= 2 && (materialFull || reward)) {
      showReward(reward, player.profile.offlineTime, materialFull)
    }

    if (battleRecord.stealNum > 0) {
      showBattleRecord(battleRecord.stealNum, battleRecord.reward, battleRecord.loss, battleRecord.recordItems)
    }

    if (events) {
      setSystemInfo({
        ...getState().player.systemInfo,
        sceneEvents: [...events, ...getState().player.systemInfo.sceneEvents],
      })
    }
  }
}

export default useEventMsg1000Login
