import { ILevelUpParams } from '@common/types'
import useLevelUpManager from '../game/useLevelUpManager'

const useEVentMsg1015PlayerLevelUp = () => {
  const { showLevelUp } = useLevelUpManager()

  return async (event) => {
    const levelUpParams: { level: string; preAndCurrentItems: ILevelUpParams[] } = JSON.parse(event.data)
    showLevelUp(levelUpParams.level, levelUpParams.preAndCurrentItems)
  }
}

export default useEVentMsg1015PlayerLevelUp
