import {
  Button,
  CloseIcon,
  Grid,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
} from '@library/uikit'
import { useTranslation } from '@library/localization'
import useToast from 'hooks/useToast'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
} from 'react-share'
import styled from 'styled-components'
import { copyText } from 'utils/copyText'

interface ReferProps extends InjectedModalProps {
  title?: string
  shareContent: string
}

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`

const ReferModal: React.FC<ReferProps> = ({ title = '', shareContent, onDismiss }) => {
  const { t } = useTranslation()
  const { toastInfo } = useToast()

  const refer = () => {
    copyText(shareContent)
    toastInfo(t('Refer link copied, send to your friends'))
  }

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Refer')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="24px" maxWidth="400px" width="100%">
        <Grid gridGap="16px" gridTemplateColumns={['repeat(4, 1fr)', null, 'repeat(5, 1fr)']} alignItems="start">
          <FacebookShareButton url={shareContent} quote={title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={shareContent} title={title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <TelegramShareButton url={shareContent} title={title}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <WhatsappShareButton url={shareContent} title={title} separator=":: ">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LinkedinShareButton url={shareContent}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={String(window.location)} media="/logo.png">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <VKShareButton url={shareContent} image="/logo.png">
            <VKIcon size={32} round />
          </VKShareButton>

          <OKShareButton url={shareContent} image="/logo.png">
            <OKIcon size={32} round />
          </OKShareButton>

          <RedditShareButton url={shareContent} title={title} windowWidth={660} windowHeight={460}>
            <RedditIcon size={32} round />
          </RedditShareButton>

          <LivejournalShareButton url={shareContent} title={title} description={shareContent}>
            <LivejournalIcon size={32} round />
          </LivejournalShareButton>

          <MailruShareButton url={shareContent} title={title}>
            <MailruIcon size={32} round />
          </MailruShareButton>

          <EmailShareButton url={shareContent} subject={title} body="body">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <TumblrShareButton url={shareContent} title={title}>
            <TumblrIcon size={32} round />
          </TumblrShareButton>

          <ViberShareButton url={shareContent} title={title}>
            <ViberIcon size={32} round />
          </ViberShareButton>

          <WorkplaceShareButton url={shareContent} quote={title}>
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <LineShareButton url={shareContent} title={title}>
            <LineIcon size={32} round />
          </LineShareButton>

          <WeiboShareButton url={shareContent} title={title} image="/logo.png">
            <WeiboIcon size={32} round />
          </WeiboShareButton>

          <PocketShareButton url={shareContent} title={title}>
            <PocketIcon size={32} round />
          </PocketShareButton>

          <InstapaperShareButton url={shareContent} title={title}>
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>

          <HatenaShareButton url={shareContent} title={title} windowWidth={660} windowHeight={460}>
            <HatenaIcon size={32} round />
          </HatenaShareButton>
        </Grid>

        <Button m={20} variant="round" onClick={refer}>
          {t('Copy link')}
        </Button>
      </ModalBody>
    </ModalContainer>
  )
}

export default ReferModal
