import { useStore } from 'state'
import { useMiniPlayer } from 'state/player/hooks'

const useEventMsg1007WeatherChange = () => {
  const [_, setMiniPlayer] = useMiniPlayer()
  const { getState } = useStore()

  return (event) => {
    const eventData: { weatherId: number } = JSON.parse(event.data)
    setMiniPlayer({ ...getState().player.miniPlayer, ...eventData })
  }
}

export default useEventMsg1007WeatherChange
