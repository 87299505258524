import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FetchStatus } from 'config/constants/types'
import { NftActivityFilter, NftAttribute, NftFilter, State } from './types'

const initialNftFilterState: NftFilter = {
  loadingState: FetchStatus.Idle,
  activeFilters: {},
  showOnlyOnSale: false,
  ordering: {
    field: 'currentAskPrice',
    direction: 'asc',
  },
}

const initialNftActivityFilterState: NftActivityFilter = {
  collectionFilters: [],
}

const initialState: State = {
  data: {
    nfts: {},
    filters: {},
    activityFilters: {},
    resultSize: 0,
    tryVideoNftMedia: true,
    loadingState: {
      isUpdatingPancakeBunnies: false,
      latestPancakeBunniesUpdateAt: 0,
    },
  },
}

export const NftMarket = createSlice({
  name: 'NftMarket',
  initialState,
  reducers: {
    removeAllItemFilters: (state, action: PayloadAction<string>) => {
      if (state.data.filters[action.payload]) {
        const { ordering, showOnlyOnSale } = state.data.filters[action.payload]
        state.data.filters[action.payload] = {
          ...initialNftFilterState,
          ordering,
          showOnlyOnSale,
        }
      } else {
        state.data.filters[action.payload] = { ...initialNftFilterState }
      }

      state.data.nfts[action.payload] = []
    },
    addActivityCollectionFilters: (state, action: PayloadAction<{ collection: string }>) => {
      if (state.data.activityFilters['']) {
        state.data.activityFilters[''].collectionFilters.push(action.payload.collection)
      } else {
        state.data.activityFilters[''] = {
          ...initialNftActivityFilterState,
          collectionFilters: [action.payload.collection],
        }
      }
    },
    removeActivityCollectionFilters: (state, action: PayloadAction<{ collection: string }>) => {
      if (state.data.activityFilters['']) {
        state.data.activityFilters[''].collectionFilters = state.data.activityFilters[''].collectionFilters.filter(
          (activeFilter) => activeFilter !== action.payload.collection,
        )
      }
    },
    removeAllActivityCollectionFilters: (state) => {
      if (state.data.activityFilters['']) {
        state.data.activityFilters[''].collectionFilters = []
      }
    },
    removeAllActivityFilters: (state, action: PayloadAction<string>) => {
      state.data.activityFilters[action.payload] = { ...initialNftActivityFilterState }
    },
    setOrdering: (state, action: PayloadAction<{ collection: string; field: string; direction: 'asc' | 'desc' }>) => {
      if (state.data.filters[action.payload.collection]) {
        state.data.filters[action.payload.collection].ordering = {
          field: action.payload.field,
          direction: action.payload.direction,
        }
      } else {
        state.data.filters[action.payload.collection] = {
          ...initialNftFilterState,
          ordering: {
            field: action.payload.field,
            direction: action.payload.direction,
          },
        }
      }
    },
    setShowOnlyOnSale: (state, action: PayloadAction<{ collection: string; showOnlyOnSale: boolean }>) => {
      if (state.data.filters[action.payload.collection]) {
        state.data.filters[action.payload.collection].showOnlyOnSale = action.payload.showOnlyOnSale
      } else {
        state.data.filters[action.payload.collection] = {
          ...initialNftFilterState,
          showOnlyOnSale: action.payload.showOnlyOnSale,
        }
      }
    },
    filterNftsFromCollection: (
      state,
      action: PayloadAction<{ collection: string; nftFilters: Record<string, NftAttribute> }>,
    ) => {
      if (state.data.filters[action.payload.collection]) {
        state.data.filters[action.payload.collection].activeFilters = action.payload.nftFilters
      } else {
        state.data.filters[action.payload.collection] = {
          ...initialNftFilterState,
          activeFilters: action.payload.nftFilters,
        }
      }
    },
    setTryVideoNftMedia: (state, action: PayloadAction<boolean>) => {
      state.data.tryVideoNftMedia = action.payload
    },
    setResultSize: (state, action: PayloadAction<number>) => {
      state.data.resultSize = action.payload
    },
  },
})

// Actions
export const {
  removeAllItemFilters,
  removeAllActivityFilters,
  removeActivityCollectionFilters,
  removeAllActivityCollectionFilters,
  addActivityCollectionFilters,
  setOrdering,
  setShowOnlyOnSale,
  filterNftsFromCollection,
  setTryVideoNftMedia,
  setResultSize,
} = NftMarket.actions

export default NftMarket.reducer
