import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import { noop } from 'lodash'
import { useContext } from 'react'
import { Preview } from 'views/Gens/Market/Fusion/hooks/useFusion'
import { FusionContent, TaskModal } from '../../views/Game/TaskModals'

export default function useFusionManager() {
  const { isOpen, onPresent, onDismiss } = useContext(MContext)
  const showFusionFinish = async (preview: Preview, closeCallback = noop) => {
    return onPresent(
      <TaskModal
        type="reward"
        title=""
        itemIcon="gens"
        showButton={false}
        content={<FusionContent preview={preview} onShareCallback={closeCallback} />}
        callback={() => onDismiss()}
        closeCallback={() => {
          onDismiss()
          closeCallback()
        }}
      />,
      `showFusionFinish`,
    )
  }

  return { showFusionFinish, onDismiss, isOpen }
}
