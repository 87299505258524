import { MContext } from '@library/uikit/src/widgets/Modal/ModalContext'
import { useContext } from 'react'
import { GenRewardModal } from 'views/Game/GenRewardModal'
import RewardModal from '../views/Game/RewardModal'

export default function useRewardManager() {
  const { onPresent, setCloseOnOverlayClick } = useContext(MContext)

  const showReward = (reward, offlineTime, materialFull) => {
    setCloseOnOverlayClick(false)
    onPresent(<RewardModal reward={reward} offlineTime={offlineTime} materialFull={materialFull} />, 'RewardModal')
  }

  const showGenReward = (gen) => {
    setCloseOnOverlayClick(false)
    onPresent(<GenRewardModal gen={gen} />, `GenRewardModal_${gen.name}`, 1)
  }

  return { showReward, showGenReward }
}
