import { ThemeProvider, DefaultTheme } from "styled-components";
import { MatchBreakpointsProvider } from "./contexts";

export const UIKitProvider: React.FC<React.PropsWithChildren<{ theme: DefaultTheme; children: React.ReactNode }>> = ({
  theme,
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <MatchBreakpointsProvider>{children}</MatchBreakpointsProvider>
    </ThemeProvider>
  );
};
