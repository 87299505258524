import { Box, BoxProps } from '@library/uikit'
import { PropsWithRef } from 'react'

const Container: React.FC<PropsWithRef<BoxProps>> = ({ children, ...props }) => (
  <Box px={['16px', '24px']} mx="auto" maxWidth="1200px" {...props}>
    {children}
  </Box>
)

export default Container
