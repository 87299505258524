import { DAGEN_DOMAIN } from '@common/constans'
import { allAsset, IPreAndCurrent } from '@common/types'
import { openNewTabByUrl } from '@common/utils'
import { languageList, useTranslation } from '@library/localization'
import { Flex, Grid, LangSelector, Text, useMatchBreakpoints, useModal } from '@library/uikit'
import useCharaterManager from 'hooks/useCharaterManager'
import { useDagenApi } from 'hooks/useDagenApi'
import useRewardManager from 'hooks/useRewardManager'
import useUnreadStatus from 'hooks/useUnreadStatus'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import React, { ReactElement } from 'react'
import CountUp from 'react-countup'
import { useMiniPlayer } from 'state/player/hooks'
import { useProfile } from 'state/profile/hooks'
import styled from 'styled-components'
import useSWR from 'swr'
import ClickFlex from '../../../packages/uikit/src/components/Box/ClickFlex'
import useAssertPreAndCurrent from '../../hooks/preAndCurrent/useAssertPreAndCurrent'
import useWallet from '../../hooks/useWallet'
import GoldChargeModal from '../../views/Gens/Market/Profile/components/GoldChargeModal'
import { FlexGap } from '../Layout/Flex'
import ReferModal from '../ReferModal'

const GameTitleBarContainer = styled(Grid)<{ isMobile: boolean }>`
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 8px;
  position: relative;
  background: #252934;
  border-bottom: 1px solid #89bd00;

  justify-content: flex-start;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'GameIconInfoContainer GameIconInfoContainer '
    'GameAssetContainer GameAssetContainer ';

  ${({ theme }) => theme.mediaQueries.lg} {
    height: 88px;
    grid-template-columns: auto 1.8fr auto 1.8fr auto;
    grid-template-areas: 'GameIconInfoContainer GameAssetContainer  GameLogo GameNavigationContainer GameMultifunctionContainer';
  }
`

const GameIconInfoContainer = styled(Flex)`
  grid-area: GameIconInfoContainer;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  .lang {
    display: none;
  }

  @media (max-width: 420px) {
    .lang {
      right: 10px;
      position: absolute;
      display: flex;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    justify-content: center;
    align-items: center;
  }
`

const IconImage = styled(Flex)<{ image: string }>`
  cursor: pointer;
  position: absolute;
  width: 112px;
  height: 112px;
  left: 12px;
  top: 16px;
  border: 2px solid #dbea2d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120px;
  background-image: ${({ image }) => `url(${image})`};
  background-color: #141e29;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  img {
    -webkit-user-drag: none;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    background-size: 150px;
    width: 128px;
    height: 128px;
    left: 16px;
    top: 24px;
  }
`

const GameAssetContainer = styled(Flex)`
  grid-area: GameAssetContainer;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 420px) {
    .asset_lang {
      display: none;
    }
  }

  .asset_lang {
    padding-top: 5px;
    padding-right: 15px;
    justify-content: end;
    flex: 1;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-right: unset;
    justify-content: center;

    .asset_container {
      gap: 30px;
    }

    .asset_lang {
      display: none;
    }
  }

  .assert_content {
    gap: 5px;
  }

  .assert_num {
    font-size: 14px;
  }

  .assert_num_change {
    font-size: 28px;
    text-shadow: 0 0 4px #ffee00;
    font-weight: 400;
  }
`

const GameLogo = styled(ClickFlex)`
  grid-area: GameLogo;
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
  }
`

const NoLoginGameLogo = styled(ClickFlex)`
  position: absolute;
  left: calc(50% - 64px);
`

const GameNavigationContainer = styled(Flex)`
  grid-area: GameNavigationContainer;
  align-items: center;
  justify-content: center;
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;

    .navigateItem_container {
      width: 70px;
      gap: 5px;
    }
  }

  @media (max-width: 1200px) {
    .navigateItem_title {
      font-size: 12px;
      max-width: 50px;
    }

    .navigateItem_container {
      width: 55px;
      gap: 5px;
    }
  }
`

const GameMultifunctionContainer = styled(Flex)<{ isLogin: boolean }>`
  grid-area: GameMultifunctionContainer;
  align-items: center;
  justify-content: end;
  margin-bottom: ${({ isLogin }) => (isLogin ? 'unset' : '40px')};
  display: ${({ isLogin }) => (isLogin ? 'none' : 'flex')};

  ${({ theme }) => theme.mediaQueries.sm} {
    display: ${({ isLogin }) => (isLogin ? 'none' : 'flex')};
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: unset;
    gap: 10px;
    display: flex;
  }
`

interface GameTitleBarProps {
  userMenu?: ReactElement
}

const GameTitleBar: React.FC<GameTitleBarProps> = ({ userMenu }) => {
  const { t, setLanguage, currentLanguage } = useTranslation()
  const { walletAddress } = useWallet()
  const { playerExps } = useCharaterManager()
  const [miniPlayer] = useMiniPlayer()
  const dagenApi = useDagenApi()
  const { code: languageCode } = currentLanguage
  const { isDesktop, isMobile } = useMatchBreakpoints()
  const router = useRouter()
  const assertPreAndCurrent = useAssertPreAndCurrent()
  const [onPresentGoldChargeModal] = useModal(<GoldChargeModal />, true, true, 'GoldChargeModal')
  const ASSERT_ID_PREFIX = 'assert_id_prefix_'
  const { profile } = useProfile()
  const avatarImage = profile?.preview || '/images/tokens/DAGEN.png'
  const accountEllipsis = walletAddress
    ? `${walletAddress.substring(0, 2)}...${walletAddress.substring(walletAddress.length - 4)}`
    : null
  const nickNameEllipsis = profile?.nickName
    ? profile?.nickName.length > 12
      ? `${profile?.nickName?.substring(0, 12)}...`
      : profile?.nickName
    : null

  const onAssertClassStart = (assetKey) => {
    const assertElement = document.getElementById(`${ASSERT_ID_PREFIX}${assetKey}`)
    if (!assertElement) return
    assertElement.classList.remove('assert_num_change')
    assertElement.classList.add('assert_num')
  }
  const onAssertClassEnd = (assetKey) => {
    const assertElement = document.getElementById(`${ASSERT_ID_PREFIX}${assetKey}`)
    if (!assertElement) return
    assertElement.classList.remove('assert_num_change')
    assertElement.classList.add('assert_num')
  }

  const onAssertClassPauseReset = (assetKey) => {
    const assertElement = document.getElementById(`${ASSERT_ID_PREFIX}${assetKey}`)
    if (!assertElement) return
    assertElement.classList.remove('assert_num')
    assertElement.classList.add('assert_num_change')
  }

  const onOpenAssertClick = async (assertKey) => {
    // eslint-disable-next-line default-case
    switch (assertKey) {
      case 'friends':
        router.push('/friends')
        break
      case 'energy':
        break
      case 'gCent':
        onPresentGoldChargeModal()
        break
      case 'gensCount':
        await router.push('/gens/yours')
        break
      case 'epRank':
        await router.push('/rank?collectionAddress=WORLD')
        break
    }
  }

  const getAssertPreValue = (preAndCurrent: IPreAndCurrent) => {
    return preAndCurrent.preValue > 0 ? preAndCurrent.preValue : preAndCurrent.currentValue
  }

  const [onPresentReferModal] = useModal(
    <ReferModal shareContent={`${DAGEN_DOMAIN}/profile/${walletAddress}`} />,
    true,
    true,
    'ReferModal',
  )

  const onIconClick = async () => {
    if (!walletAddress) return
    await router.push(`/scenes`)
  }

  const { hasUnread } = useUnreadStatus()

  return (
    <GameTitleBarContainer
      isMobile={false}
      flexDirection={['column', 'column', 'column', 'row']}
      onContextMenu={(e) => e.preventDefault()}
    >
      <IconImage image={avatarImage} onClick={() => onIconClick()} />

      {!walletAddress && (
        <NoLoginGameLogo>
          <NextImage src="/images/game/dagen-logo-titlebar.png" width={128} height={64} />
        </NoLoginGameLogo>
      )}

      {walletAddress && (
        <>
          <GameIconInfoContainer ml={[112 + 15, 112 + 15, 128 + 16, 128 + 16]}>
            <Flex flexDirection="column">
              <FlexGap gap="14px">
                <Text bold fontSize="16px" minWidth="20px">
                  {nickNameEllipsis ?? accountEllipsis}
                </Text>
                <NextImage
                  onClick={() => onPresentReferModal()}
                  className="clickable"
                  src="/images/game/share-icon.svg"
                  width={16}
                  height={16}
                />
              </FlexGap>

              <Flex justifyContent="start" alignItems="center">
                <Text pt={2} bold color="#EBFF00" fontSize="20px">
                  Lv.{miniPlayer?.profile?.level ?? 1}
                </Text>

                <Text pt={12} ml={2} color="grey">
                  {miniPlayer?.profile?.exp} / {playerExps.get(miniPlayer?.profile?.level)?.lvlExp}
                </Text>

                <Flex className="lang">
                  <LangSelector
                    currentLang={languageCode}
                    langs={languageList}
                    setLang={setLanguage}
                    buttonScale="xs"
                    color="textSubtle"
                    hideLanguage
                  />
                </Flex>
              </Flex>
            </Flex>

            {!isDesktop && userMenu}
          </GameIconInfoContainer>
          <GameAssetContainer ml={[112 + 15, 112 + 15, 128 + 16, 128 + 16, 0]}>
            <FlexGap flexWrap={isMobile ? 'wrap' : 'nowrap'} gap={isMobile ? '8px' : '24px'}>
              {Object.keys(assertPreAndCurrent).map((assetKey, index) => {
                return (
                  <ClickFlex
                    onClick={() => onOpenAssertClick(assetKey)}
                    className="assert_content"
                    alignItems="center"
                    flexDirection={['row', 'row', 'row', 'row', 'column']}
                    key={`assetItem_${index}`}
                  >
                    <Flex width={16} height={16}>
                      <NextImage
                        className={
                          assetKey === 'friends' && hasUnread
                            ? 'animate__animated animate__flash animate__infinite'
                            : ''
                        }
                        src={allAsset[assetKey]}
                        width={16}
                        height={16}
                      />
                    </Flex>
                    <Text id={`${ASSERT_ID_PREFIX}${assetKey}`} className="assert_num">
                      <CountUp
                        start={getAssertPreValue(assertPreAndCurrent[assetKey])}
                        end={assertPreAndCurrent[assetKey].currentValue}
                        duration={2}
                        separator=","
                        onStart={() => onAssertClassStart(assetKey)}
                        onEnd={() => onAssertClassEnd(assetKey)}
                        onReset={() => onAssertClassPauseReset(assetKey)}
                      />
                    </Text>
                  </ClickFlex>
                )
              })}
            </FlexGap>
            <Flex className="asset_lang">
              <LangSelector
                currentLang={languageCode}
                langs={languageList}
                setLang={setLanguage}
                buttonScale="xs"
                color="textSubtle"
                hideLanguage
              />
            </Flex>
          </GameAssetContainer>
          <GameLogo>
            <NextImage src="/images/game/dagen-logo-titlebar.png" width={128} height={64} />
          </GameLogo>
          <GameNavigationContainer>
            {ALL_NAVIGATION.filter((nav) => nav.field !== 'Back').map((navigateItem, index) => {
              return (
                <ClickFlex
                  onClick={() =>
                    navigateItem.isNewTab ? openNewTabByUrl(navigateItem.url) : router.push(navigateItem.url)
                  }
                  className="navigateItem_container"
                  alignItems="center"
                  flexDirection="column"
                  key={`navigateItem_${index}`}
                >
                  <NextImage src={navigateItem.image} width={16} height={16} />
                  <Text
                    className="navigateItem_title"
                    textAlign="center"
                    fontSize="14px"
                    fontWeight={400}
                    color={navigateItem.color}
                  >
                    {t(navigateItem.field)}
                  </Text>
                </ClickFlex>
              )
            })}
          </GameNavigationContainer>
        </>
      )}

      <GameMultifunctionContainer isLogin={!!walletAddress}>
        <LangSelector
          currentLang={languageCode}
          langs={languageList}
          setLang={setLanguage}
          buttonScale="xs"
          color="textSubtle"
          hideLanguage
        />
        {userMenu}
      </GameMultifunctionContainer>
    </GameTitleBarContainer>
  )
}

export const ALL_NAVIGATION = [
  {
    image: '/images/game/market.svg',
    field: 'Market',
    url: '/gens',
    color: '#FFCC00',
    isNewTab: false,
  },
  {
    image: '/images/game/fusion.svg',
    field: 'Fusion',
    url: '/gens/fusion',
    color: '#B6FF1B',
    isNewTab: false,
  },
  {
    image: '/images/game/blindbox.svg',
    field: 'Blind Box',
    url: '/igo',
    color: '#B6FF1B',
    isNewTab: false,
  },
  {
    image: '/images/game/wiki.svg',
    field: 'Docs',
    url: 'https://docs.dagen.life',
    color: '#B5D2FF',
    isNewTab: true,
  },
  // {
  //   field: 'Back',
  //   image: '/images/back.svg',
  //   isNewTab: true,
  // },
]

export default GameTitleBar
