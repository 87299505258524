import { Flex, Text } from '@library/uikit'
import React from 'react'
import styled from 'styled-components'
import NextImage from 'next/image'
import { useTranslation } from '@library/localization'
import { truncateHash } from '@common/utils'

const Container = styled(Flex)`
  flex-direction: column;
  background: #272727;
  border: 1px solid #89bd00;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.75);
  border-radius: 18px;
  align-items: center;
  width: 260px;
  height: 100px;
  overflow: hidden;
  top: 0;
  right: 0;
`

const RemoveOrInstallContainer = styled(Flex)<{ agree?: boolean }>`
  gap: 10px;
  width: 100%;
  padding: 5px;
  background: ${({ agree }) => (agree ? '#B12020' : '#89bd00')};
  align-items: center;
  justify-content: center;
`

interface FriendOperateProps {
  nickName: string
  agree: boolean
  isShowPercentage?: boolean
}

const FriendAgreeModal: React.FC<FriendOperateProps> = ({ nickName, agree, isShowPercentage = true }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <RemoveOrInstallContainer>
        <NextImage src={agree ? '/images/game/yes.svg' : '/images/game/error.svg'} width={15} height={15} />
        <Text bold> {t(agree ? 'agree friend' : 'reject friend')}</Text>
      </RemoveOrInstallContainer>

      <Text color="white" mt="4px" textAlign="center">
        {agree
          ? t('%s% agree friend', { s: nickName.length > 10 ? truncateHash(nickName) : nickName })
          : t('%s% reject friend', { s: nickName.length > 10 ? truncateHash(nickName) : nickName })}
      </Text>
    </Container>
  )
}

export default FriendAgreeModal
